import axios from "axios";
import React from "react";
import { BASEURL } from "../../constant/constant";
import { ValidateName } from "../../helpers/validators";
import { alertService, AlertType } from "../../utils/alert.service";
import AcInputText from "../AcInputText/AcInputText";
import ButtonTop from "../AcInputText/ButtonTop";
import SearchBar from "../AcInputText/SearchBar";
import { AddCategory } from "../Popup/Popup";

const CatContentTop = ({
  setSize,
  toggleAddCatPopup,
  isOpen,
  catName,
  setCatName,
  catList,
  setErrors,
  errors,
  catData,
  handleChangeSearch,
  searchInput,
}) => {
  const addCategory = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const item = {
      name: catName,
    };
    try {
      const response = await axios({
        url: BASEURL + "product/create",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleAddCatPopup();
        setCatName("");
        catList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const validator = () => {
    let newErrors = {};
    let isValid = true;
    const statusName = ValidateName(catName);

    if (statusName) {
      newErrors.catName = statusName === 1 ? "Name is Required" : "";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const submitCategoryHandler = (e) => {
    e.preventDefault();
    if (validator()) {
      addCategory();
    }
  };

  return (
    <div className="cat-content-top">
      <div className="cat-content-top-left">
        <span className="cat-content-top-left-title">PRODUCT LIST</span>
        <span className="cat-content-top-left-desc">
          All Product Details Listed Here Also You Can Update or Delete
        </span>
        {catData?.list?.length > 0 ? (
          <span className="cat-content-top-left-dropdown">
            Show&nbsp;
            <select
              id="number-dd"
              name="number"
              onChange={(e) => setSize(e.target.value)}
            >
              {[10, 25, 50, 75, 100, 125, 150, 175, 200].map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            &nbsp; Entries
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="content-top-right">
        <ButtonTop onClick={toggleAddCatPopup} name={"Add Product"} />
        <SearchBar onChange={handleChangeSearch} value={searchInput} />
      </div>
      {isOpen && (
        <AddCategory
          content={
            <form>
              <div className="cat-first">
                <span className="cat-title">PRODUCT DETAILS</span>
                <span className="cat-desc">All field are required</span>
              </div>
              <div className="cat-second">
                <span className="cat-second-title">
                  Personal Details
                  <div className="cat-second-hr">
                    <hr />
                  </div>
                </span>
                <div className="cat-second-firstRow">
                  <AcInputText
                    label="Name"
                    type="text"
                    placeholder="Name"
                    value={catName}
                    onChange={(e) => setCatName(e.target.value)}
                    error={errors?.catName}
                  />
                </div>
              </div>
              <div className="cat-second-bottam-btn">
                <button
                  onClick={submitCategoryHandler}
                  className="cat-second-bottam-btn-sub"
                >
                  Submit
                </button>
                <button
                  className="cat-second-bottam-btn-can"
                  onClick={toggleAddCatPopup}
                >
                  Cancel
                </button>
              </div>
            </form>
          }
        />
      )}
    </div>
  );
};

export default CatContentTop;
