import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-toastify/dist/ReactToastify.css";
import { BASEURL } from "../../constant/constant";
import { alertService, AlertType } from "../../utils/alert.service";
import { AddSitePop, DeletePopup, OrderSummery } from "../Popup/Popup";
import ClContentMiddle from "./CLContentMiddle";
import ClContentTop from "./ClContentTop";
import AcInputText from "../AcInputText/AcInputText";
import AddressInputText from "../AcInputText/AddressInputText";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import LotiFiles from "../Lotifiles/LotiFiles";
import CLDetailsCard from "./CLDetailsCard";
import RIAccordion from "./RIAccordion";
import PlaceOrderInvoice from "../PlaceOrderInvoice";
import ReactToPrint from "react-to-print";
import DatePicker from "react-multi-date-picker";
import RoInputText from "../AcInputText/RoInputText";
import ButtonTop from "../AcInputText/ButtonTop";
import { AppColors } from "../colors";
import { DeleteIcon, RedCrossIcon } from "../../assets";

const ClContent = () => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [size, setSize] = useState(10);
  const [customerSelectedPage, setCustomerSelectedPage] = useState(1);
  const [customerSize, setCustomerSize] = useState(10);
  const [siteSelectedPage, setSiteSelectedPage] = useState(1);
  const [siteSize, setSiteSize] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const initialValues = {
    siteName: "",
    siteAddress: "",
  };
  const [siteData, setSiteData] = useState(initialValues);
  const [errors, setErrors] = useState(initialValues);
  const [addSite, setAddSite] = useState([]);
  const [customerName, setCustomerName] = useState([]);
  const [summeryOpen, setSummeryOpen] = useState(false);
  const [placeOrder, setPlaceOrder] = useState("");
  const [p_s_id, setP_s_id] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAccordionLoading, setIsAccordionLoading] = useState(null);
  const [customerList, setCustomerList] = useState("");
  const [productList, setProductList] = useState("");
  const [siteOpen, setSiteOpen] = useState(false);
  const [siteInput, setSiteInput] = useState(initialValues);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [delItem, setDelItem] = useState("");
  const [r_o_Customer, setR_o_Customer] = useState("");
  const [r_o_Site, setR_o_Site] = useState("");
  const [p_I_Customer, setP_I_Customer] = useState("");
  const [r_SiteSelectedPage, setr_SiteSelectedPage] = useState(1);
  const [r_Sitesize, setr_SiteSize] = useState(10);
  const [r_CustomerSelectedPage, setr_CustomerSelectedPage] = useState(1);
  const [r_CustomerSize, setr_CustomerSize] = useState(10);
  const [o_C_sortData, setO_C_SortData] = useState("");
  const [o_C_orderBy, setO_C_OrderBy] = useState("");
  const [r_C_sortData, setR_C_SortData] = useState("");
  const [r_C_orderBy, setR_C_OrderBy] = useState("");
  const [o_S_sortData, setO_S_SortData] = useState("");
  const [o_S_orderBy, setO_S_OrderBy] = useState("");
  const [r_S_sortData, setR_S_SortData] = useState("");
  const [r_S_orderBy, setR_S_OrderBy] = useState("");
  const { state } = useLocation();
  const [openInvoice, setOpenInvoice] = useState(false);
  const [placeArr, setPlaceArr] = useState([]);
  const [delPlaceOrder, setDelPlaceOrder] = useState("");
  const [delReturnOrder, setDelReturnOrder] = useState("");
  const [isPlaceDeleteOpen, setIsPlaceDeleteOpen] = useState(false);
  const [isReturnDeleteOpen, setIsReturnDeleteOpen] = useState(false);
  const [discountDate, setDiscountDate] = useState([]);
  const [show_customer_payment, setShow_customer_payment] = useState("");
  const [show_site_payment, setShow_site_payment] = useState("");
  const [listHolidays, setListHolidays] = useState("");
  const [delHoliday, setDelHoliday] = useState("");
  const [isHolidayDeleteOpen, setIsHolidayDeleteOpen] = useState("");
  const [isChecked, setIsChecked] = useState("");
  const datePickerDRef = useRef();

  const AddSite = async () => {
    setIsLoading(true);
    const auth_token = sessionStorage.getItem("auth_token");
    const item = {
      customer_id: state.customer_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "customer/details",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      setCustomerName(response.data.details);
      setAddSite(response.data.details.sites);
      setIsLoading(false);
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  useEffect(() => {
    AddSite();
  }, []);

  const customerSiteList = async () => {
    setIsLoading(true);
    const auth_token = sessionStorage.getItem("auth_token");
    const item = {
      customer_id: state.customer_id,
      size: customerSize,
      page: customerSelectedPage?.selected ?? 1,
      sort_by: o_C_sortData,
      order_by: o_C_orderBy,
    };

    const response = await axios({
      url: BASEURL + "order/list_by_customer",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setCustomerList(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    customerSiteList();
  }, [customerSize, customerSelectedPage, o_C_sortData, o_C_orderBy]);

  const placeOrderList = async () => {
    setIsAccordionLoading(p_s_id.site_id);
    const auth_token = sessionStorage.getItem("auth_token");
    const item = {
      site_id: p_s_id.site_id,
      size: size,
      page: selectedPage?.selected ?? 1,
      sort_by: o_S_sortData,
      order_by: o_S_orderBy,
    };

    const response = await axios({
      url: BASEURL + "order/list_by_site",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setPlaceOrder(response.data);
    setIsAccordionLoading(null);
  };

  useEffect(() => {
    customerSiteList();
  }, []);

  useEffect(() => {
    p_s_id?.site_id && placeOrderList();
  }, [p_s_id, selectedPage, size, o_S_sortData, o_S_orderBy]);

  const toggleAddSitePopup = () => {
    setIsOpen(!isOpen);
    setSiteData(initialValues);
  };

  const toggleSummeryPopup = () => {
    setSummeryOpen(!summeryOpen);
    setOpenInvoice(!openInvoice);
  };

  const onSummery = (item) => {
    setProductList(item);
    toggleSummeryPopup();
  };

  const addSiteDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const siteItem = {
      site_name: siteData.siteName,
      site_address: siteData.siteAddress,
      customer_id: state.customer_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "customer/site/create",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: siteItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleAddSitePopup();
        await AddSite();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      }
    }
  };

  const sumOfCharges = productList?.vehicle_charge + productList?.labour_charge;

  const returnOrderSiteList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const item = {
      site_id: p_s_id?.site_id,
      size: r_Sitesize,
      page: r_SiteSelectedPage?.selected ?? 1,
      sort_by: r_S_sortData,
      order_by: r_S_orderBy,
    };

    const response = await axios({
      url: BASEURL + "order/return/list_by_site",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setR_o_Site(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    returnOrderSiteList(true);
  }, [
    p_s_id.site_id,
    r_Sitesize,
    r_SiteSelectedPage,
    r_S_sortData,
    r_S_orderBy,
  ]);

  const returnOrderCustomerList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const item = {
      customer_id: state.customer_id,
      size: r_CustomerSize,
      page: r_CustomerSelectedPage?.selected ?? 1,
      sort_by: r_C_sortData,
      order_by: r_C_orderBy,
    };

    const response = await axios({
      url: BASEURL + "order/return/list_by_customer",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setR_o_Customer(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    returnOrderCustomerList(true);
  }, [r_CustomerSelectedPage, r_CustomerSize, r_C_sortData, r_C_orderBy]);

  const pendingItemCustomerList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const item = {
      customer_id: state.customer_id,
    };

    const response = await axios({
      url: BASEURL + "order/pending/variant/master_by_customer",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setP_I_Customer(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    pendingItemCustomerList(true);
  }, []);

  const showCustomerPayment = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const item = {
      customer_id: state.customer_id,
    };

    const response = await axios({
      url: BASEURL + "payment/pending_by_customer",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setShow_customer_payment(response.data);
  };

  useEffect(() => {
    showCustomerPayment();
  }, []);

  const showSitePayment = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const item = {
      site_id: p_s_id?.site_id,
    };

    const response = await axios({
      url: BASEURL + "payment/pending_by_site",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setShow_site_payment(response.data);
  };

  useEffect(() => {
    if (p_s_id?.site_id) {
      showSitePayment();
    }
  }, [p_s_id?.site_id]);

  const updateSiteDetail = async () => {
    setToggleLoading(true);
    const auth_token = sessionStorage.getItem("auth_token");
    const updateVarient = {
      site_id: siteInput.site_id,
      site_name: siteInput.site_name,
      site_address: siteInput.site_address,
    };
    try {
      const response = await axios({
        url: BASEURL + "customer/site/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: updateVarient,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleUpdateSitePopup();
        setToggleLoading(false);
        await AddSite();
        await customerSiteList();
        await placeOrderList();
        await returnOrderCustomerList();
        await returnOrderSiteList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
      setToggleLoading(false);
    }
  };

  const deleteCustomerDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const deleteItem = {
      site_id: delItem?.site_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "customer/site/delete",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: deleteItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleDeletePopup();
        await AddSite();
        await customerSiteList();
        await returnOrderCustomerList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      }
    }
  };

  const deletePlaceOrderDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const deleteItem = {
      order_id: delPlaceOrder.order_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "order/delete",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: deleteItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleDeletePlacePopup();
        await AddSite();
        await customerSiteList();
        await returnOrderCustomerList();
        await placeOrderList();
        await returnOrderSiteList();
        await pendingItemCustomerList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      }
    }
  };

  const deleteReturnOrderDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const deleteItem = {
      return_order_id: delReturnOrder.return_order_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "order/return/delete",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: deleteItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleDeleteReturnPopup();
        await AddSite();
        await customerSiteList();
        await returnOrderCustomerList();
        await placeOrderList();
        await returnOrderSiteList();
        await pendingItemCustomerList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      }
    }
  };

  const ListHolidays = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const item = {
      customer_id: state.customer_id,
    };

    const response = await axios({
      url: BASEURL + "customer/holidays/list",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setListHolidays(response.data);
  };

  useEffect(() => {
    ListHolidays();
  }, []);

  const AddHolidays = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const item = {
      customer_id: state.customer_id,
      holidays: discountDate.map(
        (item) => item.day + "-" + item.month.number + "-" + item.year
      ),
    };
    try {
      const response = await axios({
        url: BASEURL + "customer/holidays/add",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        await ListHolidays();
        await customerSiteList();
        await returnOrderSiteList();
        await returnOrderCustomerList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      }
    }
  };

  const DeleteHolidays = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const deleteItem = {
      holidays_id: delHoliday?.holidays_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "customer/holidays/delete",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: deleteItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        await ListHolidays();
        toggleDeleteHolidayPopup();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item?.message).join("\n"),
        });
      }
    }
  };

  const toggleUpdateSitePopup = () => {
    setSiteOpen(!siteOpen);
  };

  const onEdit = (item) => {
    setSiteInput(item);
    toggleUpdateSitePopup();
  };

  const submitSiteHandler = () => {
    updateSiteDetail();
  };

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const onDelete = (delValue) => {
    setDelItem(delValue);
    toggleDeletePopup();
  };

  const deleteSubmitHandler = () => {
    deleteCustomerDetail();
    toggleDeletePopup();
  };

  const toggleDeletePlacePopup = () => {
    setIsPlaceDeleteOpen(!isPlaceDeleteOpen);
  };

  const toggleDeleteReturnPopup = () => {
    setIsReturnDeleteOpen(!isReturnDeleteOpen);
  };

  const onDeletePlaceOrder = (item) => {
    setDelPlaceOrder(item);
    toggleDeletePlacePopup();
  };

  const deletePlaceSubmitHandler = () => {
    deletePlaceOrderDetail();
    toggleDeletePlacePopup();
  };

  const deletePlaceCancelHandler = () => {
    toggleDeletePlacePopup();
  };

  const onDeleteReturnOrder = (item) => {
    setDelReturnOrder(item);
    toggleDeleteReturnPopup();
  };

  const deleteReturnSubmitHandler = () => {
    deleteReturnOrderDetail();
    toggleDeleteReturnPopup();
  };

  const deleteReturnCancelHandler = () => {
    toggleDeleteReturnPopup();
  };

  const onShortOrderCustomerData = (sortItem) => {
    if (sortItem !== o_C_sortData) {
      setO_C_SortData(sortItem);
      setO_C_OrderBy("ASC");
    } else {
      setO_C_OrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  const onShortOrderSiteData = (sortItem) => {
    if (sortItem !== o_S_sortData) {
      setO_S_SortData(sortItem);
      setO_S_OrderBy("ASC");
    } else {
      setO_S_OrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  const onShortReturnCustomerData = (sortItem) => {
    if (sortItem !== r_C_sortData) {
      setR_C_SortData(sortItem);
      setR_C_OrderBy("ASC");
    } else {
      setR_C_OrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  const onShortreturnSiteData = (sortItem) => {
    if (sortItem !== r_S_sortData) {
      setR_S_SortData(sortItem);
      setR_S_OrderBy("ASC");
    } else {
      setR_S_OrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  let total_rent = 0;

  for (let i = 0; i < productList?.order_details?.length; i++) {
    total_rent += productList?.order_details[i]?.rent_fifteen_days;
  }

  const componentRef = useRef();

  const printDocument = () => {
    window.print();
  };

  const handlePlaceCheckbox = (e, mapItem) => {
    const { value, checked } = e.target;
    if (checked == true) {
      setPlaceArr((prevValue) => [...prevValue, mapItem]);
    } else {
      setPlaceArr(placeArr.filter((item) => item !== mapItem));
    }
    setIsChecked(checked);
  };

  const AddHolidaysSubmitHandler = () => {
    AddHolidays();
  };

  const toggleDeleteHolidayPopup = () => {
    setIsHolidayDeleteOpen(!isHolidayDeleteOpen);
  };

  const onHolidaysDelete = (item) => {
    setDelHoliday(item);
    toggleDeleteHolidayPopup();
  };

  const deleteHolidaySubmitHandler = () => {
    DeleteHolidays();
    toggleDeleteHolidayPopup();
  };

  const deleteHolidayCancelHandler = () => {
    toggleDeleteHolidayPopup();
  };

  return (
    <div className="cl-content-scroll">
      <CLDetailsCard
        customer_name={customerName.customer_full_name}
        customer_phone={customerName.customer_phone}
        customer_city={customerName.customer_city}
        customer_whatsapp={customerName.customer_whatsapp}
        customer_email={customerName.customer_email}
        customer_address={customerName.customer_address}
        customer_id={customerName.customer_id}
        toggleAddSitePopup={toggleAddSitePopup}
        addSite={addSite}
        show_customer_payment={show_customer_payment}
      />
      {/* Holidays */}
      <div className="cl-content-card card" style={{ marginBottom: "1rem" }}>
        <RIAccordion
          product_name={"Holidays"}
          content={
            <div>
              <div style={{ display: "flex" }}>
                <div className="po-accordion-input">
                  <div className="po-accordion-date">
                    <label
                      className="ac-inputText-label"
                      style={{ marginTop: "2px" }}
                      htmlFor={"Discounted Date"}
                    >
                      Discounted Days
                    </label>
                    <div onClick={() => datePickerDRef.current.openCalendar()}>
                      <DatePicker
                        onChange={(value) => {
                          setDiscountDate(value);
                        }}
                        name="startDate"
                        format="DD-MM-YYYY"
                        multiple={true}
                        ref={datePickerDRef}
                        placeholder="Discount Days"
                      />
                    </div>
                  </div>
                </div>
                <div className="po-accordion-input">
                  <RoInputText
                    label={"Discounted Total Days"}
                    placeholder="Discounted Total Days"
                    value={discountDate?.length}
                    disabled
                    style={{ fontSize: "14px" }}
                  />
                </div>
                <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                  <ButtonTop
                    onClick={AddHolidaysSubmitHandler}
                    name={"Add Holidays"}
                  />
                </div>
              </div>
              {listHolidays?.holidays?.length > 0 ? (
                <div
                  className="cat-content-middle"
                  style={{ marginBottom: "20px" }}
                >
                  <Table className="cat-content-middle-main">
                    <Thead>
                      <Tr>
                        <Th className="content-middle-title-tr">Date</Th>
                        <Th className="content-middle-title-tr">Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {listHolidays?.holidays &&
                      listHolidays?.holidays?.length > 0
                        ? listHolidays?.holidays?.map((item, index) => {
                            return (
                              <Tr>
                                <Td className="cat-content-middle-name-data">
                                  {item?.date?.trim() !== ""
                                    ? moment(item.date).format("DD-MM-YYYY")
                                    : "-"}
                                </Td>
                                <Td className="content-middle-action-data">
                                  <div className="content-middle-action-data-div">
                                    <img
                                      className="content-middle-action-data-div-delete"
                                      src={DeleteIcon}
                                      onClick={() => onHolidaysDelete(item)}
                                    />
                                  </div>
                                </Td>
                              </Tr>
                            );
                          })
                        : null}
                    </Tbody>
                  </Table>
                </div>
              ) : (
                <LotiFiles message={"No Pending Items!"} />
              )}
            </div>
          }
        />
      </div>
      {/* Remain Item */}
      <div className="cl-content-card card" style={{ marginBottom: "1rem" }}>
        <RIAccordion
          product_name={"Remain Item"}
          content={
            <div>
              {p_I_Customer?.list?.length > 0 ? (
                <div
                  className="cat-content-middle"
                  style={{ marginBottom: "20px" }}
                >
                  <Table className="cat-content-middle-main">
                    <Thead>
                      <Tr>
                        <Th className="content-middle-title-tr">Item Name</Th>
                        <Th className="content-middle-title-tr">
                          Order Quantity
                        </Th>
                        <Th className="content-middle-title-tr">
                          Pending Quantity
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {p_I_Customer?.list && p_I_Customer?.list?.length > 0
                        ? p_I_Customer?.list?.map((item, index) => {
                            return (
                              <Tr>
                                <Td className="cat-content-middle-name-data">
                                  {item.item_name}
                                </Td>
                                <Td className="cat-content-middle-active-data">
                                  {item.ordered_quantity}
                                </Td>
                                <Td className="cat-content-middle-action-data">
                                  {item.pending_quantity}
                                </Td>
                              </Tr>
                            );
                          })
                        : null}
                    </Tbody>
                  </Table>
                </div>
              ) : (
                <LotiFiles message={"No Pending Items!"} />
              )}
            </div>
          }
        />
      </div>
      <div>
        <div class="cl-content-card card">
          <ClContentTop
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            siteData={siteData}
            setSiteData={setSiteData}
            initialValues={initialValues}
            errors={errors}
            setErrors={setErrors}
            addSiteDetail={addSiteDetail}
            toggleAddSitePopup={toggleAddSitePopup}
            addSite={addSite}
            customerList={customerList}
          />
          <div>
            <ClContentMiddle
              setCustomerSize={(value) => {
                setCustomerSize(value);
                setCustomerSelectedPage(1);
              }}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              customerSelectedPage={customerSelectedPage}
              setCustomerSelectedPage={setCustomerSelectedPage}
              siteSelectedPage={siteSelectedPage}
              setSiteSelectedPage={setSiteSelectedPage}
              initialValues={initialValues}
              errors={errors}
              setErrors={setErrors}
              siteData={siteData}
              setSiteData={setSiteData}
              state={state}
              addSite={addSite}
              setAddSite={setAddSite}
              onSummery={onSummery}
              placeOrder={placeOrder}
              setP_s_id={setP_s_id}
              customerList={customerList}
              isLoading={isLoading}
              p_s_id={p_s_id}
              customer_name={customerName.customer_full_name}
              customer_phone={customerName.customer_phone}
              onDelete={onDelete}
              toggleLoading={toggleLoading}
              setToggleLoading={setToggleLoading}
              onEdit={onEdit}
              isAccordionLoading={isAccordionLoading}
              r_o_Customer={r_o_Customer}
              r_o_Site={r_o_Site}
              r_SiteSelectedPage={r_SiteSelectedPage}
              setr_SiteSelectedPage={setr_SiteSelectedPage}
              siteSize={siteSize}
              setSiteSize={setSiteSize}
              r_CustomerSelectedPage={r_CustomerSelectedPage}
              setr_CustomerSelectedPage={setr_CustomerSelectedPage}
              r_CustomerSize={r_CustomerSize}
              setr_CustomerSize={setr_CustomerSize}
              r_Sitesize={r_Sitesize}
              setr_SiteSize={setr_SiteSize}
              onShortOrderCustomerData={onShortOrderCustomerData}
              onShortOrderSiteData={onShortOrderSiteData}
              onShortReturnCustomerData={onShortReturnCustomerData}
              onShortReturnSiteData={onShortreturnSiteData}
              handlePlaceCheckbox={handlePlaceCheckbox}
              setPlaceArr={setPlaceArr}
              onDeletePlaceOrder={onDeletePlaceOrder}
              onDeleteReturnOrder={onDeleteReturnOrder}
              show_site_payment={show_site_payment}
            />
          </div>
        </div>
        {summeryOpen && (
          <OrderSummery
            summeryContent={
              <div>
                <div>
                  <span className="cl-summary-title">Order Summary</span>
                  <div className="cl-summary-hr" />
                  {isLoading ? (
                    <TableLoader />
                  ) : (
                    <div>
                      {productList ? (
                        <div
                          className="content-middle"
                          style={{ marginRight: "25px" }}
                        >
                          <Table className="content-middle-main">
                            <Thead>
                              <Tr>
                                <Th
                                  className="cl-content-middle-title-tr"
                                  style={{ width: "150px" }}
                                >
                                  Chalan No.
                                </Th>
                                <Th className="cl-content-middle-title-tr">
                                  Site Name
                                </Th>
                                <Th className="cl-content-middle-title-tr">
                                  Order Date
                                </Th>
                                <Th className="cl-content-middle-title-tr">
                                  Location
                                </Th>
                                <Th className="cl-content-middle-title-tr">
                                  For Days
                                </Th>
                                <Th className="cl-content-middle-title-tr">
                                  Vehicle charge
                                </Th>
                                <Th className="cl-content-middle-title-tr">
                                  Labour charge
                                </Th>
                                <Th className="cl-content-middle-title-tr">
                                  Total charges
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                <Td className="cl-content-middle-name-data">
                                  {productList?.chalan_no !== null
                                    ? productList.chalan_no
                                    : "-"}
                                </Td>
                                <Td className="cl-content-middle-email-data">
                                  {productList.site_name}
                                </Td>
                                <Td className="cl-content-middle-mobile-data">
                                  {productList?.order_date?.trim() !== ""
                                    ? moment(productList.order_date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "-"}
                                </Td>
                                <Td className="cl-content-middle-active-data">
                                  {productList?.order_location?.trim() !== ""
                                    ? productList.order_location
                                    : "-"}
                                </Td>
                                <Td className="cl-content-middle-date-data">
                                  15 DAYS
                                </Td>
                                <Td className="cl-content-middle-date-data">
                                  {productList?.vehicle_charge}
                                </Td>
                                <Td className="cl-content-middle-date-data">
                                  {productList?.labour_charge}
                                </Td>
                                <Td className="cl-content-middle-date-data">
                                  {sumOfCharges}
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </div>
                      ) : (
                        <LotiFiles message={"No Data Found For Order!"} />
                      )}
                    </div>
                  )}
                </div>
                <div>
                  <span
                    className="cl-summary-title"
                    style={{ marginTop: "20px" }}
                  >
                    Order Details
                  </span>
                  <div className="cl-summary-hr" />
                  {isLoading ? (
                    <TableLoader />
                  ) : (
                    <div>
                      {productList?.order_details.length > 0 ? (
                        <div
                          className="cat-content-middle"
                          style={{
                            marginRight: "25px",
                          }}
                        >
                          <Table className="content-middle-main">
                            <Thead>
                              <Tr>
                                <Th className="cl-content-middle-title-tr cl-summary-middle-no">
                                  No
                                </Th>
                                <Th className="cl-content-middle-title-tr">
                                  Item Name
                                </Th>
                                <Th className="cl-content-middle-title-tr">
                                  Order Date
                                </Th>
                                <Th className="cl-content-middle-title-tr">
                                  Order Qty
                                </Th>
                                <Th className="cl-content-middle-title-tr">
                                  Unit Rent
                                </Th>
                                <Th className="cl-content-middle-title-tr">
                                  Rent (Per Day Rs.)
                                </Th>
                                <Th className="cl-content-middle-title-tr">
                                  Rent (Per 15 Days)
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {productList?.order_details &&
                              productList.order_details.length > 0
                                ? productList.order_details.map(
                                    (item, index) => {
                                      return (
                                        <Tr>
                                          <Td className="cl-summary-middle-no-data">
                                            {index + 1}
                                          </Td>
                                          <Td className="cl-summary-middle-quantity-data">
                                            {item?.item_name}
                                          </Td>
                                          <Td className="cl-content-middle-mobile-data">
                                            {productList?.order_date?.trim() !==
                                            ""
                                              ? moment(
                                                  productList.order_date
                                                ).format("DD-MM-YYYY")
                                              : "-"}
                                          </Td>
                                          <Td className="cl-summary-middle-quantity-data">
                                            {item?.quantity}
                                          </Td>
                                          <Td className="cl-summary-middle-quantity-data">
                                            {item?.rent}
                                          </Td>
                                          <Td className="cl-summary-middle-rent2-data">
                                            {item?.rent_per_day}
                                          </Td>
                                          <Td className="cl-summary-middle-rent1-data">
                                            {item?.rent_fifteen_days}
                                          </Td>
                                        </Tr>
                                      );
                                    }
                                  )
                                : null}
                            </Tbody>
                            <Tbody>
                              <Tr style={{ background: AppColors.lightGreen }}>
                                <Td
                                  style={{
                                    textAlign: "right",
                                  }}
                                  className="cl-summary-middle-rent1-data"
                                  colspan="6"
                                >
                                  Total Rent
                                </Td>
                                <Td className="cl-summary-middle-rent1-data">
                                  ₹ {total_rent}
                                </Td>
                              </Tr>
                              <Tr>
                                <Td
                                  className="ro-content-middle-v-1"
                                  colspan="6"
                                >
                                  Vehicle Rent
                                </Td>
                                <Td className="ro-content-middle-v-2">
                                  ₹ {productList?.vehicle_charge}
                                </Td>
                              </Tr>
                              <Tr>
                                <Td
                                  className="ro-content-middle-l-1"
                                  colspan="6"
                                >
                                  Labour Rent
                                </Td>
                                <Td className="ro-content-middle-l-2">
                                  ₹ {productList?.labour_charge}
                                </Td>
                              </Tr>
                              <Tr style={{ fontWeight: "bold" }}>
                                <Td
                                  className="ro-content-middle-t-1"
                                  colspan="6"
                                >
                                  Total Payable Amount
                                </Td>
                                <Td className="ro-content-middle-t-2">
                                  ₹ {total_rent + sumOfCharges}
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </div>
                      ) : (
                        <LotiFiles message={"No Data Found For Orders!"} />
                      )}
                    </div>
                  )}
                  <div className="cl-summary-btn-div">
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className="cl-summary-btn"
                          style={{ backgroundColor: AppColors.red }}
                          onClick={printDocument}
                        >
                          Print
                        </button>
                      )}
                      content={() => componentRef.current}
                    />
                    <button
                      style={{ marginLeft: "10px" }}
                      className="cl-summary-btn"
                      onClick={toggleSummeryPopup}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            }
          />
        )}
        {siteOpen && (
          <AddSitePop
            content={
              <div>
                <span className="addCustomer-second-title">
                  Update Site
                  <div className="addCustomer-second-hr">
                    <hr />
                  </div>
                </span>
                <div className="addCustomer-second-firstRow">
                  <AcInputText
                    label="Site Name"
                    type="text"
                    name="siteName"
                    placeholder="Name"
                    value={siteInput.site_name}
                    onChange={(e) =>
                      setSiteInput((prevValue) => ({
                        ...prevValue,
                        site_name: e.target.value,
                      }))
                    }
                  />
                  <AddressInputText
                    label="Address"
                    type="text"
                    name="siteAddress"
                    placeholder="Address"
                    value={siteInput.site_address}
                    onChange={(e) =>
                      setSiteInput((prevValue) => ({
                        ...prevValue,
                        site_address: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="addCustomer-second-bottam-btn">
                  <button
                    onClick={submitSiteHandler}
                    className="addCustomer-second-bottam-btn-sub"
                  >
                    Submit
                  </button>
                  <button
                    className="addCustomer-second-bottam-btn-can"
                    onClick={toggleUpdateSitePopup}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            }
          />
        )}
        {isDeleteOpen && (
          <DeletePopup
            deleteContent={
              <div className="del-pop">
                <div className="del-pop-main">
                  <img className="del-pop-icon" src={RedCrossIcon} />
                </div>
                <div className="del-pop-text-div">
                  <span className="del-pop-text">
                    Are you sure want to delete site : <br />
                    {delItem?.site_name}?
                  </span>
                </div>
                <div className="del-pop-btn">
                  <button
                    className="del-pop-btn-yes"
                    onClick={deleteSubmitHandler}
                  >
                    Yes
                  </button>
                  <button
                    className="del-pop-btn-no"
                    onClick={toggleDeletePopup}
                  >
                    No
                  </button>
                </div>
              </div>
            }
          />
        )}
        {openInvoice ? (
          <div ref={componentRef}>
            {placeArr == "" ? (
              <PlaceOrderInvoice
                customer_phone={state.customer_phone}
                customer_name={state.customer_full_name}
                summeryItem={productList}
                isChecked={isChecked}
              />
            ) : (
              <div>
                {placeArr.map((summeryItem) => (
                  <PlaceOrderInvoice
                    customer_phone={state.customer_phone}
                    customer_name={state.customer_full_name}
                    summeryItem={summeryItem}
                    isChecked={isChecked}
                  />
                ))}
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        {isPlaceDeleteOpen && (
          <DeletePopup
            deleteContent={
              <div className="del-pop">
                <div className="del-pop-main">
                  <img className="del-pop-icon" src={RedCrossIcon} />
                </div>
                <div className="del-pop-text-div">
                  <span className="del-pop-text">
                    Are you sure want to delete site : <br />
                    {delPlaceOrder?.site_name}?
                  </span>
                </div>
                <div className="del-pop-btn">
                  <button
                    className="del-pop-btn-yes"
                    onClick={deletePlaceSubmitHandler}
                  >
                    Yes
                  </button>
                  <button
                    className="del-pop-btn-no"
                    onClick={deletePlaceCancelHandler}
                  >
                    No
                  </button>
                </div>
              </div>
            }
          />
        )}
        {isReturnDeleteOpen && (
          <DeletePopup
            deleteContent={
              <div className="del-pop">
                <div className="del-pop-main">
                  <img className="del-pop-icon" src={RedCrossIcon} />
                </div>
                <div className="del-pop-text-div">
                  <span className="del-pop-text">
                    Are you sure want to delete site : <br />
                    {delReturnOrder?.site_name}?
                  </span>
                </div>
                <div className="del-pop-btn">
                  <button
                    className="del-pop-btn-yes"
                    onClick={deleteReturnSubmitHandler}
                  >
                    Yes
                  </button>
                  <button
                    className="del-pop-btn-no"
                    onClick={deleteReturnCancelHandler}
                  >
                    No
                  </button>
                </div>
              </div>
            }
          />
        )}
        {isHolidayDeleteOpen && (
          <DeletePopup
            deleteContent={
              <div className="del-pop">
                <div className="del-pop-main">
                  <img className="del-pop-icon" src={RedCrossIcon} />
                </div>
                <div className="del-pop-text-div">
                  <span className="del-pop-text">
                    Are you sure want to delete site : <br />
                    {moment(delHoliday?.date).format("DD-MM-YYYY")}?
                  </span>
                </div>
                <div className="del-pop-btn">
                  <button
                    className="del-pop-btn-yes"
                    onClick={deleteHolidaySubmitHandler}
                  >
                    Yes
                  </button>
                  <button
                    className="del-pop-btn-no"
                    onClick={deleteHolidayCancelHandler}
                  >
                    No
                  </button>
                </div>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export default ClContent;
