import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASEURL } from "../../constant/constant";
import InvoiceContentTop from "./InvoiceContentTop";
import InvoiceMiddle from "./InvoiceMiddle";
import { AlertType, alertService } from "../../utils/alert.service";

const InvoiceContent = () => {
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [customer, setCustomer] = useState("");
  const [c_id, setC_id] = useState("");
  const [customerInDetails, setCustomerInDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState("");

  const CustomerList = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    try {
      const response = await axios({
        url: BASEURL + "customer/drop_down",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
      });
      setCustomer(response.data);
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  useEffect(() => {
    CustomerList();
  }, []);

  const CustomerInvoiceDetails = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    try {
      const response = await axios({
        url: BASEURL + "invoice",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: {
          customer_id: c_id,
          start_date: selectedFromDate,
          end_date: selectedToDate,
        },
      });
      setCustomerInDetails(response.data);
      setIsLoading(false);
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  useEffect(() => {
    CustomerInvoiceDetails();
  }, [c_id, selectedFromDate, selectedToDate]);

  const CustomerDetails = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const response = await axios({
      url: BASEURL + "customer/details",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        customer_id: c_id,
      },
    });
    setCustomerDetails(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (c_id) {
      CustomerDetails();
    }
  }, [c_id]);

  return (
    <div className="content-scroll">
      <div class="content-card card">
        <InvoiceContentTop
          selectedFromDate={selectedFromDate}
          selectedToDate={selectedToDate}
          setSelectedFromDate={setSelectedFromDate}
          setSelectedToDate={setSelectedToDate}
          customer={customer}
          c_id={c_id}
          setC_id={setC_id}
        />
      </div>
      <div class="content-card card">
        <InvoiceMiddle
          customerInDetails={customerInDetails}
          isLoading={isLoading}
          customerDetails={customerDetails}
        />
      </div>
    </div>
  );
};

export default InvoiceContent;
