import React from "react";
import { HomeIcon } from "../../assets";
const ChalanNavigation = () => {
  return (
    <div className="content-nav">
      <img src={HomeIcon} />
      <span className="content-nav-home">Home</span>
      <i class="bx bx-chevron-right"></i>
      <span className="content-nav-home">Chalan-Book</span>
    </div>
  );
};

export default ChalanNavigation;
