export const AppColors = {
  black: "#000000",
  white: "#ffffff",
  primary: "#03A9F4",
  secondary: "#FFC107",
  darkRed: "#881010",
  red: "#FF0000",
  lightRed: "#F7A4A4",
  darkCyan: "#167a93",
  lightGreen: "#BEF0CB",
  green: "#C7E9B0",
};
