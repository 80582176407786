import ContentHeader from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import "./../Expenses/Expenses.css";
import TransactionNavigation from "../../components/Transaction-comp/Navigation/TransactionYearNavigation";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASEURL } from "../../constant/constant";
import TransactionYear from "../../components/Transaction-comp/TransactionYear";
import TransactionMonth from "../../components/Transaction-comp/TransactionMonth";
import TransactionDay from "../../components/Transaction-comp/TransactionDay";
import TransactionDayNavigation from "../../components/Transaction-comp/Navigation/TransactionDayNavigation";
import { useLocation } from "react-router-dom";

const TransactionDayMain = () => {
  const { state } = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [day_payment_list, setDay_payment_list] = useState("");

  const DayPaymentList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const response = await axios({
      url: BASEURL + "transaction/daily",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        month: state.month,
        year: state.year,
      },
    });
    setDay_payment_list(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    DayPaymentList(true);
  }, []);

  return (
    <div className="col-md-12 content">
      <Sidebar
        className="sidebar-main"
        isActive={isActive}
        setIsActive={setIsActive}
        classNameTwo="open-sidebar"
      />
      <div className="col-12 sb-content">
        <div className="sb-content-header">
          <ContentHeader setIsActive={setIsActive} />
        </div>
        <div className="sb-nav">
          <TransactionDayNavigation />
        </div>
        <div className="content-index">
          <div className="content-scroll">
            <div class="content-card card">
              <TransactionDay
                day_payment_list={day_payment_list}
                isLoading={isLoading}
                state={state}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDayMain;
