import React, { useState } from "react";
import ContentHeader from "../../components/Header";
import PaymentContent from "../../components/PaymentComp/PaymentContent";
import Sidebar from "../../components/Sidebar";
import PaymentNavigation from "./../../components/PaymentComp/PaymentNavigation";
import "./Payment.css";
import { useLocation } from "react-router-dom";

const Product = () => {
  const [isActive, setIsActive] = useState(false);
  const { state } = useLocation();

  return (
    <div className="col-md-12 content">
      <Sidebar
        className="sidebar-main"
        isActive={isActive}
        setIsActive={setIsActive}
        classNameTwo="open-sidebar"
      />
      <div className="col-12 sb-content">
        <div className="sb-content-header">
          <ContentHeader setIsActive={setIsActive} />
        </div>
        <div className="sb-nav">
          <PaymentNavigation state={state} />
        </div>
        <div className="content-index">
          <PaymentContent state={state} />
        </div>
      </div>
    </div>
  );
};

export default Product;
