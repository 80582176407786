import React from "react";
import { RemovePng } from "../../assets";

const SelectInputText = ({
  label,
  onChange,
  data,
  onClick,
  disabled,
  style,
  error,
}) => {
  return (
    <div className="ac-inputText-input-wrapper">
      <label className="ac-inputText-label" htmlFor={label}>
        {label}
      </label>
      <select
        className="ac-inputText-input-container"
        style={style}
        onChange={onChange}
        onClick={onClick}
        disabled={disabled}
      >
        <option>---select---</option>
        {data &&
          data?.map((item, index) => {
            return (
              <option
                key={index}
                className="ac-inputText-input"
                value={item.value}
              >
                {item.label}
              </option>
            );
          })}
      </select>
      {error ? (
        <div className="inputText-error-container">
          <img
            className="inputText-img"
            style={{ verticalAlign: "middle" }}
            src={RemovePng}
            alt=""
          />
          <span>{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default SelectInputText;
