import React, { useState } from "react";
import Content from "../../components/AddCustomer-comp/Content";
import ContentHeader from "../../components/Header";
import ContentNavigation from "../../components/AddCustomer-comp/ContentNavigation";
import Sidebar from "../../components/Sidebar";
import "./AddCustomer.css";

const AddCustomer = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="col-md-12 content">
      <Sidebar
        className="sidebar-main"
        isActive={isActive}
        setIsActive={setIsActive}
        classNameTwo="open-sidebar"
      />
      <div className="col-12 sb-content">
        <div className="sb-content-header">
          <ContentHeader setIsActive={setIsActive} />
        </div>
        <div className="sb-nav">
          <ContentNavigation />
        </div>
        <div className="content-index">
          <Content />
        </div>
      </div>
    </div>
  );
};

export default AddCustomer;
