import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import "./Lotifiles.css";
import { EmptyTablejson } from "../../assets";

const LotiFiles = ({ message }) => {
  return (
    <div className="container" style={{ marginBottom: "80px" }}>
      <Player src={EmptyTablejson} className="empty-table" loop autoplay />
      <span className="empty-table-title">{message}</span>
    </div>
  );
};

export default LotiFiles;
