import React from "react";
import BarChart from "../ChartsComp/BarChart";
import DonutChartComp from "../ChartsComp/DonutChart";
import LineCharts from "../ChartsComp/LineCharts";

const DashContentMiddle = ({
  setExpenseType,
  setStaticType,
  productData,
  expenseData,
  staticData,
}) => {
  return (
    <div className="dash-middle">
      <div className=" dash-middle-first">
        <div className=" dash-middle-first-left-chart">
          <div className="donut-product-details-div">
            <span className="donut-product-details">Product Details</span>
          </div>
          <DonutChartComp productData={productData} />
        </div>
        <div className="dash-middle-first-right-chart">
          <div>
            <div className="donut-product-details-div">
              <span className="donut-product-details">Expenses</span>
            </div>
            <div className="dash-top-sec-part-first-row-dropdown">
              <select
                className="chart-dropdown-select"
                onChange={(e) => setExpenseType(e.target.value)}
              >
                {["Monthly", "Yearly"].map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          </div>
          <BarChart expenseData={expenseData} />
        </div>
      </div>
      <div className="dash-middle-second">
        <div className="donut-product-details-div">
          <div>
            <span className="donut-product-details">Order statistics</span>
            <div className="dash-top-sec-part-first-row-dropdown">
              <div className="line-div">
                <div
                  className="donut-list-color-dot"
                  style={{
                    backgroundColor: "#16a34a",
                    marginTop: "15px",
                  }}
                />
                <span
                  style={{
                    paddingLeft: "10px",
                    color: "#16a34a",
                    marginTop: "8px",
                  }}
                >
                  Placed Orders
                </span>
              </div>
              <div className="line-div" style={{ marginLeft: "15px" }}>
                <div
                  className="donut-list-color-dot"
                  style={{
                    backgroundColor: "#f6c77d",
                    marginTop: "15px",
                  }}
                />
                <span
                  style={{
                    paddingLeft: "10px",
                    color: "#f6c77d",

                    marginTop: "8px",
                  }}
                >
                  Return Orders
                </span>
              </div>
              <select
                style={{ marginLeft: "15px" }}
                className="chart-dropdown-select"
                onChange={(e) => setStaticType(e.target.value)}
              >
                {["Monthly", "Yearly"].map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <LineCharts staticData={staticData} />
      </div>
    </div>
  );
};

export default DashContentMiddle;
