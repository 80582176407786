export const ValidateId = (id) => {
  if (id?.trim() === "") {
    return 1;
  }
  return false;
};

export const ValidatePassword = (password) => {
  if (password?.trim() === "") {
    return 1;
  }
  return false;
};

export const ValidateName = (name) => {
  if (name?.trim() === "") {
    return 1;
  }
  return false;
};

export const ValidateMobile = (mobile) => {
  if (mobile?.trim() === "") {
    return 1;
  }
  if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(mobile)) {
    return 2;
  }
  return false;
};

export const ValidateEmail = (email) => {
  if (email?.trim() === "") {
    return 1;
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    return 2;
  }
  return false;
};

export const ValidatePrice = (price) => {
  if (price?.trim() === "") {
    return 1;
  }
  return false;
};
