import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Timer = () => {
  const [inactiveTime, setInactiveTime] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;

    const resetTimer = () => {
      setInactiveTime(0);
    };

    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keydown", resetTimer);
    document.addEventListener("mousedown", resetTimer);
    document.addEventListener("touchstart", resetTimer);

    timer = setInterval(() => {
      setInactiveTime((prevInactiveTime) => prevInactiveTime + 1);
    }, 1000);

    return () => {
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keydown", resetTimer);
      document.removeEventListener("mousedown", resetTimer);
      document.removeEventListener("touchstart", resetTimer);
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (inactiveTime > 60 * 5) {
      sessionStorage.clear();
      navigate("/");
    }
  }, [inactiveTime]);

  return <div></div>;
};

export default Timer;
