import React, { useState } from "react";
import { DownArrowIcon, RightArrowIcon } from "../../assets";

const SAccordion = ({ content, product_name, vOpen, setVopen, isLoading }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleSizePopup = () => {
    setVopen(!vOpen);
  };

  return (
    <div className="cl-accordion-item column">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        {isActive ? (
          <img className="cl-down-arrow" src={DownArrowIcon} />
        ) : (
          <img className="cl-right-arrow" src={RightArrowIcon} />
        )}
        <div className="accordion-title-txt">{product_name}</div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default SAccordion;
