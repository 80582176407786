import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASEURL } from "../../constant/constant";
import { alertService, AlertType } from "../../utils/alert.service";
import StockContentTop from "./StockContentTop";
import StockContentMiddle from "./StockContentMiddle";
import { AddProduct, DeletePopup } from "../Popup/Popup";
import AcInputText from "../AcInputText/AcInputText";
import moment from "moment";
import { RedCrossIcon } from "../../assets";

const StockContent = () => {
  const [data, setData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [size, setSize] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  const [addStock, setAddStock] = useState({
    stockQty: "",
    stockPrice: "",
    stockTotal: "",
    stockDate: defaultValue,
  });
  const [varData, setVarData] = useState();
  const [productDropDown, setProductDropDownData] = useState("");
  const [varientDropDown, setVarientDropDown] = useState("");
  const [ispd_Id, setpd_IsId] = useState("");
  const [isv_Id, setv_IsId] = useState("");
  const [stockData, setStockData] = useState();
  const [stockItem, setStockItem] = useState({
    total_quantity: "",
    price_per_item: "",
    total_amount: "",
    purchase_date: "",
  });
  const [stockUpdatePopup, setStockUpdatePopup] = useState(false);
  const [summaryOpen, setSummaryOpen] = useState(false);
  const [delItem, setDelItem] = useState("");
  const [stockDeletePopup, setStockDeletePopup] = useState(false);
  const [errors, setErrors] = useState({
    stockQty: "",
    stockPrice: "",
    stockTotal: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const showSkeleton = false;
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const StockList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);

    const response = await axios({
      url: BASEURL + "product/stock/list",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        size: size,
        page: selectedPage?.selected ?? 1,
        sort_by: sortData,
        order_by: orderBy,
      },
    });
    setData(response.data);
    showSkeleton && setIsLoading(false);
  };

  const varList = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);

    const response = await axios({
      url: BASEURL + "product/variant/List",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        size: size,
        page: selectedPage,
      },
    });
    setVarData(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    varList();
  }, []);

  useEffect(() => {
    StockList(true);
  }, [selectedPage, size, orderBy, sortData]);

  const ProductDropDown = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);

    const response = await axios({
      url: BASEURL + "product/drop_down",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        size: size,
        page: selectedPage,
      },
    });
    setProductDropDownData(response.data);
    showSkeleton && setIsLoading(false);
  };

  const VarientDropDown = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const vDropDown = {
      product_id: ispd_Id,
    };

    const response = await axios({
      url: BASEURL + "product/variant/dropdown_by_productid",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: vDropDown,
    });
    setVarientDropDown(response.data);
    showSkeleton && setIsLoading(false);
  };

  const stockList = async (item, showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const response = await axios({
      url: BASEURL + "product/stock/list_by_variant",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        size: size,
        page: selectedPage?.selected ?? 1,
        product_variants_id: item.product_variants_id,
      },
    });
    setStockData(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    stockList();
  }, [selectedPage, size]);

  const updateStockDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const updateStock = {
      variant_transaction_id: stockItem.variant_transaction_id,
      total_quantity: parseInt(stockItem.total_quantity),
      price_per_item: parseFloat(stockItem.price_per_item),
      total_amount: stockItem.total_amount,
      purchase_date: stockItem.purchase_date,
    };
    try {
      const response = await axios({
        url: BASEURL + "product/stock/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: updateStock,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleStockUpdatePopup();
        await StockList();
        await varList();
        showSkeleton && setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const deleteStockDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const deleteItem = {
      variant_transaction_id: delItem.variant_transaction_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "product/stock/delete_entry",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: deleteItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleStockDeletePopup();
        await StockList();
        await varList();
        showSkeleton && setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const toggleStockUpdatePopup = () => {
    setStockUpdatePopup(!stockUpdatePopup);
  };

  const togglePopupSummary = (item) => {
    stockList(item);
    setSummaryOpen(!summaryOpen);
  };

  const onEdit = (value) => {
    setStockItem(value);
    toggleStockUpdatePopup();
  };

  const submitStockHandler = (e) => {
    updateStockDetail();
    setStockUpdatePopup(!stockUpdatePopup);
  };

  const toggleStockDeletePopup = () => {
    setStockDeletePopup(!stockDeletePopup);
  };

  const onDelete = (delValue) => {
    setDelItem(delValue);
    toggleStockDeletePopup();
  };

  const stockDeleteHandler = () => {
    deleteStockDetail();
    toggleStockDeletePopup();
  };

  const onShortData = (sortItem) => {
    if (sortItem !== sortData) {
      setSortData(sortItem);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  return (
    <div className="content-scroll">
      <div class="content-card card">
        <StockContentTop
          setSize={(value) => {
            setSize(value);
            setSelectedPage(1);
          }}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          addStock={addStock}
          setAddStock={setAddStock}
          varData={varData}
          productDropDown={productDropDown}
          ispd_Id={ispd_Id}
          setpd_IsId={setpd_IsId}
          isv_Id={isv_Id}
          setv_IsId={setv_IsId}
          varientDropDown={varientDropDown}
          ProductDropDown={ProductDropDown}
          VarientDropDown={VarientDropDown}
          StockList={StockList}
          isLoading={isLoading}
          errors={errors}
          setErrors={setErrors}
          data={data}
          defaultValue={defaultValue}
          varList={varList}
          handleChangeSearch={handleChangeSearch}
          searchInput={searchInput}
        />
        <div>
          <StockContentMiddle
            data={data}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            varData={varData}
            stockData={stockData}
            onEdit={onEdit}
            onDelete={onDelete}
            togglePopupSummary={togglePopupSummary}
            setStockUpdatePopup={setStockUpdatePopup}
            stockUpdatePopup={stockUpdatePopup}
            summaryOpen={summaryOpen}
            isLoading={isLoading}
            onShortData={onShortData}
          />
        </div>
      </div>
      {stockUpdatePopup && (
        <AddProduct
          content={
            <form>
              <div className="addCustomer-first">
                <span className="addCustomer-title">UPDATE STOCK DETAILS</span>
                <span className="addCustomer-desc">All field are required</span>
              </div>
              <div className="addProduct-second">
                <span className="addCustomer-second-title">
                  Personal Details
                  <div className="addCustomer-second-hr">
                    <hr />
                  </div>
                </span>
                <div className="addCustomer-second-firstRow">
                  <AcInputText
                    label="Product Name"
                    type="text"
                    disabled
                    value={stockItem.product_name}
                  />
                  <AcInputText
                    label="Measurement"
                    disabled
                    value={stockItem.measurement_name}
                  />

                  <AcInputText
                    label="Size"
                    disabled
                    value={stockItem.product_variant_size}
                  />
                </div>
                <div className="addCustomer-second-firstRow">
                  <AcInputText
                    label="Total Quantity"
                    type="text"
                    placeholder="Total Quantity"
                    value={stockItem.total_quantity}
                    onChange={(e) =>
                      setStockItem((prevValue) => ({
                        ...prevValue,
                        total_quantity: e.target.value,
                        total_amount: prevValue.total_quantity * e.target.value,
                        price_per_item:
                          e.target.value / prevValue.total_quantity,
                      }))
                    }
                  />
                  <AcInputText
                    label="Price (per item)"
                    placeholder="Price"
                    value={stockItem.price_per_item}
                    onChange={(e) =>
                      e.target.value.length <= 10 &&
                      setStockItem((prevValue) => ({
                        ...prevValue,
                        price_per_item: e.target.value.replace(/\D/g, ""),
                        total_amount: prevValue.total_quantity * e.target.value,
                      }))
                    }
                  />
                  <AcInputText
                    label="Total Amount"
                    placeholder="Total Amount"
                    value={stockItem.total_amount}
                    onChange={(e) =>
                      setStockItem((prevValue) => ({
                        ...prevValue,
                        total_amount: e.target.value.replace(/\D/g, ""),
                        price_per_item:
                          e.target.value / prevValue.total_quantity,
                      }))
                    }
                  />
                </div>
                <div className="addCustomer-second-firstRow">
                  <AcInputText
                    label="Created Date"
                    value={moment(stockItem.createdAt).format("DD-MM-YYYY")}
                    disabled
                  />
                </div>
              </div>
              <div className="addProduct-second-bottam-btn">
                <button
                  onClick={submitStockHandler}
                  className="addProduct-second-bottam-btn-sub"
                >
                  Submit
                </button>
                <button
                  className="addProduct-second-bottam-btn-can"
                  onClick={toggleStockUpdatePopup}
                >
                  Cancel
                </button>
              </div>
            </form>
          }
        />
      )}
      {stockDeletePopup && (
        <DeletePopup
          deleteContent={
            <div className="del-pop">
              <div className="del-pop-main">
                <img className="del-pop-icon" src={RedCrossIcon} />
              </div>
              <div className="del-pop-text-div">
                <span className="del-pop-text">
                  Are you sure want to delete stock : <br />
                  {delItem.product_name}?
                </span>
              </div>
              <div className="del-pop-btn">
                <button
                  className="del-pop-btn-yes"
                  onClick={stockDeleteHandler}
                >
                  Yes
                </button>
                <button
                  className="del-pop-btn-no"
                  onClick={toggleStockDeletePopup}
                >
                  No
                </button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default StockContent;
