import React from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const LineCharts = ({ staticData }) => {
  const data = staticData?.result;

  return (
    <div className="line-chart-main">
      <ResponsiveContainer width={"99%"} height={300}>
        <ComposedChart data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0.1%" stopColor="#16A34A" stopOpacity={0.3} />
              <stop offset="99.57%" stopColor="#FFFFFF" stopOpacity={0.0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="Placed" stroke="#16A34A" />
          <Area
            type="monotone"
            dataKey="Placed"
            stroke={false}
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#colorUv)"
            tooltipType="none"
          />
          <Line type="monotone" dataKey="Returned" stroke="#F6C77D" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineCharts;
