import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ReactSwitch from "react-switch";
import { AlertType, alertService } from "../../utils/alert.service";
import SortIcon from "../AcInputText/SortIcon";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import LotiFiles from "../Lotifiles/LotiFiles";
import Pagination from "../Pagination/Pagination";
import { DeleteIcon, EditIcon } from "../../assets";

const ContentMiddle = ({
  selectedPage,
  setSelectedPage,
  data,
  onChangeToggle,
  onEdit,
  onDelete,
  isLoading,
  toggleLoading,
  onShortData,
}) => {
  const navigate = useNavigate();
  const handleChange = (val) => {
    onChangeToggle(val);
  };

  const letsToast = () => {
    alertService.alert({
      type: AlertType.Error,
      message: "Customer is Inactive!",
    });
  };

  return (
    <div>
      {isLoading ? (
        <TableLoader />
      ) : (
        <div>
          {data?.list?.length > 0 ? (
            <div className="content-middle">
              <Table className="content-middle-main">
                <Thead>
                  <Tr>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Name
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("customer_full_name")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Mobile Number
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("customer_phone")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          WhatsApp Number
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("customer_whatsapp")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Is Active
                        </div>
                        <div>
                          <SortIcon onClick={() => onShortData("is_active")} />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Date
                        </div>
                        <div>
                          <SortIcon onClick={() => onShortData("created_at")} />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Ref. Name
                        </div>
                        <div>
                          <SortIcon onClick={() => onShortData("ref_name")} />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Ref. Number
                        </div>
                        <div>
                          <SortIcon onClick={() => onShortData("ref_phone")} />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.list && data?.list?.length > 0
                    ? data?.list?.map((item, index) => {
                        return (
                          <Tr key={index}>
                            <Td
                              className="content-middle-name-data customer-name"
                              onClick={() => {
                                item.is_active
                                  ? navigate("/customer/details", {
                                      state: {
                                        customer_id: item.customer_id,
                                        customer_full_name:
                                          item.customer_full_name,
                                        customer_phone: item.customer_phone,
                                      },
                                    })
                                  : letsToast();
                              }}
                            >
                              {item.customer_full_name}
                            </Td>
                            <Td className="content-middle-mobile-data">
                              {item.customer_phone}
                            </Td>
                            <Td className="content-middle-whats-data">
                              {item?.customer_whatsapp?.trim() !== ""
                                ? item.customer_whatsapp
                                : "-"}
                            </Td>
                            <Td className="content-middle-active-data">
                              <ReactSwitch
                                height={25}
                                width={53}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                checked={item.is_active}
                                onChange={(val) =>
                                  handleChange({
                                    customer_id: item.customer_id,
                                    status: val,
                                  })
                                }
                              />
                            </Td>
                            <Td className="content-middle-date-data">
                              {item?.created_at?.trim() !== ""
                                ? moment(item.created_at).format("DD-MM-YYYY")
                                : "-"}
                            </Td>
                            <Td className="content-middle-refName-data">
                              {item?.ref_name?.trim() !== ""
                                ? item.ref_name
                                : "-"}
                            </Td>
                            <Td className="content-middle-refAddress-data">
                              {item?.ref_phone?.trim() !== ""
                                ? item.ref_phone
                                : "-"}
                            </Td>
                            <Td className="content-middle-action-data">
                              <div className="content-middle-action-data-div">
                                <img
                                  src={EditIcon}
                                  onClick={() => onEdit(item)}
                                />
                                <img
                                  className="content-middle-action-data-div-delete"
                                  src={DeleteIcon}
                                  onClick={() => onDelete(item)}
                                />
                              </div>
                            </Td>
                          </Tr>
                        );
                      })
                    : null}
                </Tbody>
              </Table>
            </div>
          ) : (
            <LotiFiles message={"No Data Found For Customer!"} />
          )}
        </div>
      )}
      {data?.list?.length > 0 ? (
        <div className="content-bottam">
          <Pagination
            selectedPage={selectedPage}
            totalCount={data?.total_pages ?? 1}
            onPageChange={(page) => setSelectedPage(page)}
            itemsPerPage={4}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ContentMiddle;
