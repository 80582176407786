import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASEURL } from "../../constant/constant";
import SizeContentTop from "./VariantContentTop";
import SizeContentMiddle from "./VariantContentMiddle";
import AcInputText from "../AcInputText/AcInputText";
import { AddSize, DeletePopup } from "../Popup/Popup";
import { alertService, AlertType } from "../../utils/alert.service";
import ToggleLoading from "../LoadingSpinner/ToggleLoading";
import { RedCrossIcon } from "../../assets";

const SizeContent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [vOpen, setVopen] = useState(false);
  const [vName, setVname] = useState("");
  const [vFixprice, setVfixPrice] = useState("");
  const [varData, setVarData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [catData, setCatData] = useState([]);
  const [mesData, setMesData] = useState([]);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isp_Id, setp_IsId] = useState();
  const [ism_Id, setm_IsId] = useState();
  const [varItem, setVarItem] = useState();
  const [delItem, setDelItem] = useState();
  const [toggleLoading, setToggleLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const showSkeleton = false;

  const catList = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const response = await axios({
      url: BASEURL + "product/drop_down",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
    });
    setCatData(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    catList();
  }, []);

  const mesList = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const response = await axios({
      url: BASEURL + "product/measurement/drop_down",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
    });
    setMesData(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    mesList();
  }, []);

  const varList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const response = await axios({
      url: BASEURL + "product/variant/List",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
    });
    setVarData(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    varList(true);
  }, []);

  const updateStatus = async (value) => {
    setToggleLoading(true);
    axios({
      method: "post",
      maxBodyLength: Infinity,
      url: BASEURL + "product/variant/update_status",
      headers: {
        Authorization: sessionStorage.getItem("auth_token"),
        "Content-Type": "application/json",
      },
      data: value,
    })
      .then(async function (response) {
        await varList();
        setToggleLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        } else if (error.response.status === 409) {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        } else {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        }
      });
  };

  const updateVarientDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const updateVarient = {
      name: varItem.product_variant_size,
      fix_rent: varItem.fix_rent,
      product_variants_id: varItem.product_variants_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "product/variant/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: updateVarient,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleUpdateVarientPopup();
        await varList();
        showSkeleton && setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const deleteVarientDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const deleteItem = {
      product_variants_id: delItem.product_variants_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "product/variant/delete",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: deleteItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleDeletePopup();
        await varList();
        showSkeleton && setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const onChangeToggle = (val) => {
    updateStatus(val);
  };

  const toggleUpdateVarientPopup = () => {
    setUpdateOpen(!updateOpen);
  };

  const toggleDeletePopup = () => {
    setDeleteOpen(!deleteOpen);
  };

  const onEdit = (value) => {
    setVarItem(value);
    toggleUpdateVarientPopup();
  };

  const onDelete = (delValue) => {
    setDelItem(delValue);
    toggleDeletePopup();
  };

  const submitVarientHandler = (e) => {
    e.preventDefault();
    updateVarientDetail();
  };

  const deleteSubmitHandler = () => {
    deleteVarientDetail();
  };

  return (
    <div className="content-scroll">
      {toggleLoading ? <ToggleLoading /> : null}
      <div class="content-card card">
        <SizeContentTop
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          vName={vName}
          setVname={setVname}
          vFixprice={vFixprice}
          setVfixPrice={setVfixPrice}
          catData={catData}
          varList={varList}
          mesData={mesData}
          vOpen={vOpen}
          errors={errors}
          setErrors={setErrors}
        />
        <div>
          <SizeContentMiddle
            varData={varData}
            vOpen={vOpen}
            setVopen={setVopen}
            catData={catData}
            mesData={mesData}
            varList={varList}
            isp_Id={isp_Id}
            ism_Id={ism_Id}
            setm_IsId={setm_IsId}
            setp_IsId={setp_IsId}
            onEdit={onEdit}
            onDelete={onDelete}
            onChangeToggle={onChangeToggle}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            showSkeleton={showSkeleton}
            toggleLoading={toggleLoading}
          />
        </div>
      </div>
      {updateOpen && (
        <AddSize
          content={
            <form>
              <div className="addSize-first">
                <span className="addSize-title">UPDATE VARIENT DETAILS</span>
                <span className="addSize-desc">All field are required</span>
              </div>
              <div className="addSize-second">
                <span className="addSize-second-title">
                  Personal Details
                  <div className="addSize-second-hr">
                    <hr />
                  </div>
                </span>
                <div className="addSize-second-firstRow">
                  <AcInputText
                    label="Product Name"
                    value={varItem.product_name}
                    disabled
                  />
                  <AcInputText
                    label="Item size"
                    type="text"
                    placeholder="Item size"
                    value={varItem.product_variant_size}
                    onChange={(e) =>
                      setVarItem((prevValue) => ({
                        ...prevValue,
                        product_variant_size: e.target.value,
                      }))
                    }
                  />
                  <AcInputText
                    label="Measurement"
                    value={varItem.measurement_name}
                    disabled
                  />
                  <AcInputText
                    label="Fix Price (15 Days)"
                    placeholder="Fix Price"
                    value={varItem.fix_rent}
                    onChange={(e) =>
                      setVarItem((prevValue) => ({
                        ...prevValue,
                        fix_rent: e.target.value.replace(/\D/g, ""),
                      }))
                    }
                  />
                </div>
              </div>
              <div className="addSize-second-bottam-btn">
                <button
                  onClick={submitVarientHandler}
                  className="addSize-second-bottam-btn-sub"
                >
                  Submit
                </button>
                <button
                  className="addSize-second-bottam-btn-can"
                  onClick={toggleUpdateVarientPopup}
                >
                  Cancel
                </button>
              </div>
            </form>
          }
        />
      )}
      {deleteOpen && (
        <DeletePopup
          deleteContent={
            <div className="del-pop">
              <div className="del-pop-main">
                <img className="del-pop-icon" src={RedCrossIcon} />
              </div>
              <div className="del-pop-text-div">
                <span className="del-pop-text">
                  Are you sure want to delete variant : <br />
                  {delItem.product_variant_size}?
                </span>
              </div>
              <div className="del-pop-btn">
                <button
                  className="del-pop-btn-yes"
                  onClick={deleteSubmitHandler}
                >
                  Yes
                </button>
                <button className="del-pop-btn-no" onClick={toggleDeletePopup}>
                  No
                </button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default SizeContent;
