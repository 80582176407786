import React, { useState } from "react";
import { PassEyeCloseIcon, PassEyeOpenIcon, RemovePng } from "../../assets";
import "./InputText.css";

export default function InputText({
  type,
  placeholder,
  value,
  onChange,
  className,
  label,
  disabled,
  error,
  src,
  style,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      style={style}
      className={`inputText-input-wrapper ${className ? className : ""}`}
    >
      <label className="inputText-label" htmlFor={label}>
        {label}
      </label>
      <div className="inputText-input-container">
        <img src={src} />
        <input
          className="inputText-input"
          type={type === "password" ? (showPassword ? "text" : type) : type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        {type === "password" && (
          <div onClick={togglePassword}>
            {showPassword ? (
              <img src={PassEyeOpenIcon} />
            ) : (
              <img src={PassEyeCloseIcon} />
            )}
          </div>
        )}
      </div>
      {error ? (
        <div className="inputText-error-container">
          <img
            className="inputText-img"
            style={{ verticalAlign: "middle" }}
            src={RemovePng}
            alt=""
          />
          <span>{error}</span>
        </div>
      ) : null}
    </div>
  );
}
