import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import "./PlaceOrder.css";
import ClContentNavigation from "../../components/CustomerList-comp/CLContentNavigation";
import POContent from "./../../components/PlaceOrderComp/POContent";
import PONavigation from "../../components/PlaceOrderComp/PONavigate";

const PlaceOrder = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="col-md-12 content">
      <Sidebar
        className="sidebar-main"
        isActive={isActive}
        setIsActive={setIsActive}
        classNameTwo="open-sidebar"
      />
      <div className="col-12 sb-content">
        <div className="sb-content-header">
          <Header setIsActive={setIsActive} />
        </div>
        <div className="sb-nav">
          <PONavigation />
        </div>
        <div className="content-index">
          <POContent />
        </div>
      </div>
    </div>
  );
};

export default PlaceOrder;
