import React, { useState } from "react";
import ContentHeader from "../../components/Header";
import SizeContentNavigation from "../../components/Variant-comp/VariantContentNavigation";
import Sidebar from "../../components/Sidebar";
import "./Variant.css";
import SizeContent from "../../components/Variant-comp/VariantContent";

const Size = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="col-md-12 content">
      <Sidebar
        className="sidebar-main"
        isActive={isActive}
        setIsActive={setIsActive}
        classNameTwo="open-sidebar"
      />
      <div className="col-12 sb-content">
        <div className="sb-content-header">
          <ContentHeader setIsActive={setIsActive} />
        </div>
        <div className="sb-nav">
          <SizeContentNavigation />
        </div>
        <div className="content-index">
          <SizeContent />
        </div>
      </div>
    </div>
  );
};

export default Size;
