import React, { useEffect, useState } from "react";
import AcInputText from "../AcInputText/AcInputText";
import axios from "axios";
import { AddSize } from "../Popup/Popup";
import { alertService, AlertType } from "../../utils/alert.service";
import "react-toastify/dist/ReactToastify.css";
import { ValidateName, ValidatePrice } from "../../helpers/validators";
import { BASEURL } from "../../constant/constant";
import SelectInputText from "../InputText/SelectInputText";
import SearchBar from "../AcInputText/SearchBar";
import ButtonTop from "../AcInputText/ButtonTop";

const SizeContentTop = ({
  isOpen,
  setIsOpen,
  varList,
  catData,
  mesData,
  errors,
  setErrors,
  vName,
  setVname,
  vFixprice,
  setVfixPrice,
}) => {
  const [isp_Id, setp_IsId] = useState("");
  const [ism_Id, setm_IsId] = useState("");
  const addVarientDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const item = {
      size: vName,
      product_id: isp_Id,
      measurement_id: ism_Id,
      fix_rent: vFixprice,
    };
    try {
      const response = await axios({
        url: BASEURL + "product/variant/create",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleSizePopup();
        setVname("");
        setVfixPrice("");
        varList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const toggleSizePopup = () => {
    setIsOpen(!isOpen);
    setVname("");
    setVfixPrice("");
    setErrors("");
  };

  const validator = () => {
    let newErrors = {};
    let isValid = true;

    const statusName = ValidateName(vName);
    const statusMobile = ValidateName(vFixprice);
    const statusProductName = ValidateName(isp_Id);
    const statusMeasurement = ValidateName(ism_Id);

    if (statusName) {
      newErrors.vName = statusName === 1 ? "Size is Required" : "";
      isValid = false;
    }
    if (statusMobile) {
      newErrors.vFixprice = statusMobile === 1 ? "fix price is Required" : "";
      isValid = false;
    }
    if (statusProductName) {
      newErrors.isp_Id = statusProductName === 1 ? "Select Product Name" : "";
      isValid = false;
    }
    if (statusMeasurement) {
      newErrors.ism_Id = statusMeasurement === 1 ? "Select Measurement" : "";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const submitVarientHandler = (e) => {
    e.preventDefault();
    if (validator()) {
      addVarientDetail();
    }
  };

  return (
    <div className="content-top">
      <div className="content-top-left">
        <span className="content-top-left-title">VARIANT DETAILS</span>
        <span className="content-top-left-desc">
          All Variants Details Listed Here Also You Can Update or Delete
        </span>
      </div>
      <div className="content-top-right">
        <ButtonTop onClick={toggleSizePopup} name={"Add Variant"} />
      </div>
      {isOpen && (
        <AddSize
          content={
            <form>
              <div className="addSize-first">
                <span className="addSize-title">ADD VARIENT DETAILS</span>
                <span className="addSize-desc">All field are required</span>
              </div>
              <div className="addSize-second">
                <span className="addSize-second-title">
                  Personal Details
                  <div className="addSize-second-hr">
                    <hr />
                  </div>
                </span>
                <div className="addSize-second-firstRow">
                  <SelectInputText
                    setp_IsId={setp_IsId}
                    onChange={(e) => {
                      setp_IsId(e.target.value);
                    }}
                    data={catData?.list}
                    value={isp_Id}
                    label="Product Name"
                    error={errors.isp_Id}
                  />
                  <AcInputText
                    label="Item size"
                    type="text"
                    placeholder="Item size"
                    value={vName}
                    onChange={(e) => setVname(e.target.value)}
                    error={errors?.vName}
                  />
                  <SelectInputText
                    onChange={(e) => {
                      setm_IsId(e.target.value);
                    }}
                    data={mesData?.list}
                    label="Measurement"
                    value={ism_Id}
                    error={errors.ism_Id}
                  />
                  <AcInputText
                    label="Fix Price (15 Days)"
                    placeholder="Fix Price"
                    value={vFixprice}
                    onChange={(e) =>
                      setVfixPrice(e.target.value.replace(/\D/g, ""))
                    }
                    error={errors?.vFixprice}
                  />
                </div>
              </div>
              <div className="addSize-second-bottam-btn">
                <button
                  onClick={submitVarientHandler}
                  className="addSize-second-bottam-btn-sub"
                >
                  Submit
                </button>
                <button
                  className="addSize-second-bottam-btn-can"
                  onClick={toggleSizePopup}
                >
                  Cancel
                </button>
              </div>
            </form>
          }
        />
      )}
    </div>
  );
};

export default SizeContentTop;
