import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import LotiFiles from "../Lotifiles/LotiFiles";
import moment from "moment";
import SearchBar from "../AcInputText/SearchBar";

const ChalanMiddle = ({
  isLoading,
  onSummery,
  handleSearchChange,
  searchQuery,
  filteredData,
}) => {
  return (
    <div>
      <div>
        <div style={{ marginLeft: "20px" }}>
          <span className="payment-top-left-title">CHALAN SUMMERY</span>
          <div style={{ float: "right", marginTop: "-32px" }}>
            <SearchBar value={searchQuery} onChange={handleSearchChange} />
          </div>
        </div>
      </div>
      <div>
        <div className="cat-content-middle">
          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="content-middle-main">
              {filteredData?.length > 0 ? (
                <Table>
                  <Thead>
                    <Tr>
                      <Th className="content-middle-title-tr">
                        <div className="sort-main">
                          <div className="content-middle-title-tr-sort-name">
                            Chalan No.
                          </div>
                        </div>
                      </Th>
                      <Th className="content-middle-title-tr">
                        <div className="sort-main">
                          <div className="content-middle-title-tr-sort-name">
                            Customer Name
                          </div>
                        </div>
                      </Th>
                      <Th className="content-middle-title-tr">
                        <div className="sort-main">
                          <div className="content-middle-title-tr-sort-name">
                            Customer Number
                          </div>
                        </div>
                      </Th>
                      <Th className="content-middle-title-tr">
                        <div className="sort-main">
                          <div className="content-middle-title-tr-sort-name">
                            Date
                          </div>
                        </div>
                      </Th>
                      <Th className="content-middle-title-tr">
                        <div className="sort-main">
                          <div className="content-middle-title-tr-sort-name">
                            Order Type
                          </div>
                        </div>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredData && filteredData?.length > 0
                      ? filteredData?.map((item, index) => {
                          return (
                            <Tr key={index}>
                              <div className="cl-content-chalan-box">
                                <Td
                                  className="cat-content-middle-name-data customer-name"
                                  onClick={() => onSummery(item)}
                                >
                                  {item?.chalan_no !== null
                                    ? item.chalan_no
                                    : "-"}
                                </Td>
                              </div>
                              <Td className="cat-content-middle-active-data">
                                {item?.customer_full_name?.trim() !== ""
                                  ? item.customer_full_name
                                  : "-"}
                              </Td>
                              <Td className="cat-content-middle-action-data">
                                {item?.customer_phone !== ""
                                  ? item.customer_phone
                                  : "-"}
                              </Td>
                              <Td className="cat-content-middle-active-data">
                                {item?.order_date ??
                                item.order_return_date?.trim() !== ""
                                  ? moment(
                                      item?.order_date ??
                                        item?.order_return_date
                                    ).format("DD-MM-YYYY")
                                  : item.order_return_date}
                              </Td>
                              <Td className="cat-content-middle-active-data">
                                {item?.return_order_id
                                  ? "Return Order"
                                  : "Place Order"}
                              </Td>
                            </Tr>
                          );
                        })
                      : ""}
                  </Tbody>
                </Table>
              ) : (
                <LotiFiles message={"No Data Found For Chalan-Book!"} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChalanMiddle;
