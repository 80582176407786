import React from "react";
import AcInputText from "../AcInputText/AcInputText";
import AddressInputText from "../AcInputText/AddressInputText";
import { AddSitePop } from "../Popup/Popup";

const ClContentTop = ({
  isOpen,
  siteData,
  setSiteData,
  errors,
  addSiteDetail,
  toggleAddSitePopup,
  initialValues,
}) => {
  const submitSiteHandler = () => {
    addSiteDetail();
    setSiteData(initialValues);
  };

  return (
    <div>
      <div className="cl-content-top">
        {isOpen && (
          <AddSitePop
            content={
              <div>
                <div className="addCustomer-first">
                  <span className="addCustomer-title">ADD SITE DETAILS</span>
                  <span className="addCustomer-desc">
                    All field are required
                  </span>
                </div>
                <span className="addCustomer-second-title">
                  Site Details
                  <div className="addCustomer-second-hr">
                    <hr />
                  </div>
                </span>
                <div className="addCustomer-second-firstRow">
                  <AcInputText
                    label="Site Name"
                    type="text"
                    name="siteName"
                    placeholder="Name"
                    value={siteData.siteName}
                    onChange={(e) =>
                      setSiteData((prevValue) => ({
                        ...prevValue,
                        siteName: e.target.value,
                      }))
                    }
                    error={errors?.siteName}
                  />
                  <AddressInputText
                    label="Address"
                    type="text"
                    name="siteAddress"
                    placeholder="Address"
                    value={siteData.siteAddress}
                    onChange={(e) =>
                      setSiteData((prevValue) => ({
                        ...prevValue,
                        siteAddress: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="addCustomer-second-bottam-btn">
                  <button
                    onClick={submitSiteHandler}
                    className="addCustomer-second-bottam-btn-sub"
                  >
                    Submit
                  </button>
                  <button
                    className="addCustomer-second-bottam-btn-can"
                    onClick={toggleAddSitePopup}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export default ClContentTop;
