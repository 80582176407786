import React from "react";
import { useNavigate } from "react-router-dom";
import { HomeIcon } from "../../assets";
const ClContentNavigation = () => {
  const navigate = useNavigate();

  return (
    <div className="content-nav">
      <img src={HomeIcon} />
      <span className="content-nav-home">Home</span>
      <i class="bx bx-chevron-right"></i>
      <span className="content-nav-home" onClick={() => navigate("/customer")}>
        Customer
      </span>
      <i class="bx bx-chevron-right"></i>
      <span className="content-nav-home">Customer-Details</span>
    </div>
  );
};

export default ClContentNavigation;
