import axios from "axios";
import { useState } from "react";
import LoadingSpin from "react-loading-spin";
import { useNavigate } from "react-router-dom";
import {
  ColoredPassIcon,
  ColoredUserIcon,
  SahjanandLogoIcon,
} from "../../assets";
import InputText from "../../components/InputText/InputText";
import { BASEURL } from "../../constant/constant";
import { ValidateId, ValidatePassword } from "../../helpers/validators";
import { AlertType, alertService } from "../../utils/alert.service";
import "./AdminLogin.css";

function AdminLogin() {
  const initialValues = { id: "", password: "" };
  const [data, setData] = useState(initialValues);
  const [errors, setErrors] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const getData = async () => {
    const item = { user_name: data.id, password: data.password };
    try {
      setIsLoading(true);
      const response = await axios({
        url: BASEURL + "auth/login",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: item,
      });
      if (response.status === 200) {
        setIsLoading(false);
        sessionStorage.setItem("auth_token", response.data.auth_token);
        navigate("/customer");
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const oldUserData = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const response = await axios({
      url: BASEURL + "user/details",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
    });
    sessionStorage.setItem("user_name", response.data.result.user_name);
    sessionStorage.setItem(
      "user_full_name",
      response.data.result.user_full_name
    );
    sessionStorage.setItem("user_email", response.data.result.user_email);
  };

  const validator = (formData = data) => {
    let newErrors = {};
    let isValid = true;
    const statusId = ValidateId(formData.id);
    const statusPassword = ValidatePassword(formData.password);
    if (statusId) {
      newErrors.id = statusId === 1 ? "User is Required" : "";
      isValid = false;
    }
    if (statusPassword) {
      newErrors.password = statusPassword === 1 ? "Password is Required" : "";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validator()) {
      getData();
    }
  };

  return (
    <section className="login-main-container">
      <div className="card">
        <img className="al-logo" src={SahjanandLogoIcon} />
        <h2 className="al-title">Log In</h2>
        <form onSubmit={handleSubmit}>
          <InputText
            label="User"
            type="text"
            placeholder="User"
            src={ColoredUserIcon}
            value={data.id}
            onChange={(e) =>
              setData((prevValue) => ({
                ...prevValue,
                id: e.target.value,
              }))
            }
            error={errors?.id}
          />
          <InputText
            label="Password"
            type="password"
            placeholder="Password"
            src={ColoredPassIcon}
            value={data.password}
            onChange={(e) =>
              setData((prevValue) => ({
                ...prevValue,
                password: e.target.value,
              }))
            }
            error={errors?.password}
          />
          <span
            className="customer-name"
            style={{ display: "flex", justifyContent: "center" }}
            onClick={() => navigate("/forgot-password")}
          >
            forgot password?
          </span>
          <div className="button-container">
            <button
              className="button"
              disabled={isLoading}
              onClick={oldUserData}
            >
              {isLoading ? (
                <LoadingSpin
                  primaryColor="white"
                  size="30px"
                  animationDuration="1s"
                />
              ) : (
                "LOG IN"
              )}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default AdminLogin;
