import React from "react";
import { Table } from "react-super-responsive-table";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import LotiFiles from "../Lotifiles/LotiFiles";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AppColors } from "../colors";

const TransactionOfDay = ({
  ofday_payment_list,
  isLoading,
  selectedPage,
  setSelectedPage,
  setSize,
  onShortData,
}) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="payment-middle">
        <span className="payment-top-left-title">TRANSACTION</span>
      </div>
      <div className="cat-content-middle">
        {isLoading ? (
          <TableLoader />
        ) : (
          <div className="content-middle-main">
            {ofday_payment_list?.list?.length > 0 ? (
              <Table style={{ borderRadius: "10px" }}>
                {ofday_payment_list && ofday_payment_list?.list?.length > 0
                  ? ofday_payment_list?.list?.map((item, index) => {
                      return (
                        <tbody>
                          {item?.type == "income" ? (
                            <tr className="payment-tr">
                              <td
                                style={{
                                  background: AppColors.green,
                                  color: AppColors.black,
                                  paddingLeft: "10px",
                                  width: "500px",
                                }}
                              >
                                {item?.date !== ""
                                  ? moment(item?.date).format("DD-MM-YYYY")
                                  : "-"}
                              </td>
                              <td
                                style={{
                                  background: AppColors.green,
                                  color: AppColors.black,
                                  width: "500px",
                                }}
                              >
                                {item?.description?.trim() !== ""
                                  ? item.description
                                  : "-"}
                              </td>
                              <td
                                style={{
                                  background: AppColors.green,
                                  color: AppColors.black,
                                  width: "500px",
                                }}
                              >
                                +{" "}
                                {item?.amount !== ""
                                  ? item.amount?.toFixed(2)
                                  : "-"}
                              </td>
                            </tr>
                          ) : (
                            <tr className="payment-tr">
                              <td
                                style={{
                                  background: AppColors.lightRed,
                                  color: AppColors.black,
                                  paddingLeft: "10px",
                                  width: "500px",
                                }}
                              >
                                {item?.date !== ""
                                  ? moment(item?.date).format("DD-MM-YYYY")
                                  : "-"}
                              </td>
                              <td
                                style={{
                                  background: AppColors.lightRed,
                                  color: AppColors.black,
                                  width: "500px",
                                }}
                              >
                                {item?.description !== ""
                                  ? item.description
                                  : "-"}
                              </td>
                              <td
                                style={{
                                  background: AppColors.lightRed,
                                  color: AppColors.black,
                                  width: "500px",
                                }}
                              >
                                -{" "}
                                {item?.amount !== ""
                                  ? item.amount?.toFixed(2)
                                  : "-"}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      );
                    })
                  : ""}
              </Table>
            ) : (
              <LotiFiles message={"No Data Found For Payment!"} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionOfDay;
