import React from "react";
import { useNavigate } from "react-router";
import { AlertType, alertService } from "../../utils/alert.service";
import { PdfIconPng } from "../../assets";

const CLDetailsCard = ({
  customer_name,
  customer_phone,
  customer_email,
  customer_whatsapp,
  customer_city,
  customer_address,
  toggleAddSitePopup,
  customer_id,
  addSite,
  show_customer_payment,
}) => {
  const navigate = useNavigate();

  const paymentHandler = () => {
    navigate("/customer/details/payment", {
      state: {
        customer_id: customer_id,
        customer_name: customer_name,
      },
    });
  };

  const returnOrderHandler = () => {
    addSite?.length > 0
      ? navigate("/customer/details/return-order", {
          state: {
            customer_id: customer_id,
            customer_name: customer_name,
            customer_phone: customer_phone,
          },
        })
      : alertService.alert({
          type: AlertType.Error,
          message: "Please add Site first!",
        });
  };

  return (
    <div className="cl-content-card card cl-content-details">
      <div className="col-md-4 cl-content-details-left">
        <div className="cl-content-details-left-side">
          <div className="cl-content-details-left-name-logo">
            <span className="cl-content-details-left-name-logo-text">
              {customer_name?.charAt(0)}
            </span>
          </div>
          <span className="cl-content-details-left-name">
            {customer_name}{" "}
            <span style={{ color: "#04AF70" }}>
              [{show_customer_payment?.pending_amount}]
            </span>
          </span>
        </div>
        <div
          className="cl-content-details-left-side"
          style={{ marginTop: "20px" }}
        >
          <span className="cl-content-details-left-side-title">Address</span>
          <span className="cl-content-details-left-side-dot">:</span>
          <span className="cl-content-details-left-side-desc">
            {customer_address}
          </span>
        </div>
        <div className="cl-content-details-left-side">
          <span className="cl-content-details-left-side-title">City</span>
          <span className="cl-content-details-left-side-dot">:</span>
          <span className="cl-content-details-left-side-desc">
            {customer_city}
          </span>
        </div>
        <div className="cl-content-details-left-side">
          <span className="cl-content-details-left-side-title">
            Mobile Number
          </span>
          <span className="cl-content-details-left-side-dot">:</span>
          <span className="cl-content-details-left-side-desc">
            {customer_phone}
          </span>
        </div>
        <div className="cl-content-details-left-side">
          <span className="cl-content-details-left-side-title">
            WhatsApp Number
          </span>
          <span className="cl-content-details-left-side-dot">:</span>
          <span className="cl-content-details-left-side-desc">
            {customer_whatsapp}
          </span>
        </div>
        <div className="cl-content-details-left-side">
          <span className="cl-content-details-left-side-title">Email</span>
          <span className="cl-content-details-left-side-dot">:</span>
          <span
            className="cl-content-details-left-side-desc"
            style={{ textTransform: "lowercase" }}
          >
            {customer_email}
          </span>
        </div>
      </div>
      <div className="cl-content-details-hr" />
      <div className="col-md-8 cl-content-details-right">
        <div className="cl-content-details-right-doc">
          <div className="cl-content-details-right-docWname">
            <div className="cl-content-details-right-doc-card">
              <img
                className="cl-content-details-right-doc-card-img"
                src={PdfIconPng}
              />
            </div>
            <span>(document name)</span>
          </div>
          <div className="cl-content-details-right-docWname">
            <div className="cl-content-details-right-doc-card">
              <img
                className="cl-content-details-right-doc-card-img"
                src={PdfIconPng}
              />
            </div>
            <span>(document name)</span>
          </div>
          <div className="cl-content-details-right-docWname">
            <div className="cl-content-details-right-doc-card">
              <img
                className="cl-content-details-right-doc-card-img"
                src={PdfIconPng}
              />
            </div>
            <span>(document name)</span>
          </div>
        </div>
        <div className="cl-card-button-main">
          <div className="cl-content-details-right-btn">
            <button
              className="cl-content-accordion-right-btn cl-accordion-btn-two"
              onClick={toggleAddSitePopup}
            >
              + Add Site
            </button>
            <button
              className="cl-content-accordion-right-btn cl-accordion-btn-two"
              onClick={paymentHandler}
            >
              + Payment
            </button>
            <button
              className="cl-content-accordion-right-btn cl-accordion-btn-two"
              onClick={returnOrderHandler}
            >
              + Return Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CLDetailsCard;
