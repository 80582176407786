import React from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import LotiFiles from "../Lotifiles/LotiFiles";

const SearchMiddle = ({
  isLoading,
  searchList,
  selectedPage,
  setSelectedPage,
  setSize,
  onShortData,
  onDelete,
}) => {
  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}
      >
        <span className="payment-top-left-title">SEARCH LIST</span>
      </div>
      <div>
        <div className="ex-content-middle">
          {isLoading ? (
            <TableLoader />
          ) : searchList?.list?.length > 0 ? (
            <div className="cat-content-middle">
              <Table className="content-middle-main">
                <Thead>
                  <Tr>
                    <Th className="content-middle-title-tr">Customer Name</Th>
                    <Th className="content-middle-title-tr">Mobile Number</Th>
                    <Th className="content-middle-title-tr">Location</Th>
                    <Th className="content-middle-title-tr">Remain Qty</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {searchList && searchList?.list?.length > 0
                    ? searchList?.list?.map((item, index) => {
                        return (
                          <Tr key={index}>
                            <Td className="content-middle-mobile-data">
                              {item?.customer_name?.trim() !== ""
                                ? item.customer_name
                                : "-"}
                            </Td>
                            <Td className="content-middle-mobile-data">
                              {item?.customer_phone?.trim() !== ""
                                ? item?.customer_phone
                                : "-"}
                            </Td>
                            <Td className="content-middle-mobile-data">
                              {item?.customer_city !== "" ? item.city : "-"}
                            </Td>
                            <Td className="content-middle-mobile-data">
                              {item?.pending_quantity?.trim() !== ""
                                ? item.pending_quantity
                                : "-"}
                            </Td>
                          </Tr>
                        );
                      })
                    : null}
                </Tbody>
              </Table>
            </div>
          ) : (
            <LotiFiles message={"No Search List!!!"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchMiddle;
