import React from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import LotiFiles from "../Lotifiles/LotiFiles";
import SortIcon from "../AcInputText/SortIcon";
import Checkbox from "../AcInputText/Checkbox";
import { EyeIcon } from "../../assets";

const POContentMiddle = ({
  setSize,
  placeOrder,
  isLoading,
  selectedPage,
  setSelectedPage,
  onSummery,
  onShortData,
  handlePlaceCheckbox,
}) => {
  return (
    <div>
      <div className="content-top-left">
        <span className="content-top-left-title">ORDER HISTORY</span>
        <span style={{ marginTop: "10px" }} className="content-top-left-desc">
          All Place Order Details Listed Here Also You Can Update or Delete
        </span>
        {placeOrder?.list?.length > 0 ? (
          <span className="cat-content-top-left-dropdown">
            Show&nbsp;
            <select
              id="number-dd"
              name="number"
              onChange={(e) => setSize(e.target.value)}
            >
              {[10, 25, 50, 75, 100, 125, 150, 175, 200].map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            &nbsp; Entries
          </span>
        ) : (
          ""
        )}
      </div>
      {isLoading ? (
        <TableLoader />
      ) : (
        <div>
          {placeOrder?.list?.length > 0 ? (
            <div className="content-middle">
              <Table className="content-middle-main">
                <Thead>
                  <Tr>
                    <Th className="cl-content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Chalan No.
                        </div>
                        <div>
                          <SortIcon onClick={() => onShortData("chalan_no")} />
                        </div>
                      </div>
                    </Th>
                    <Th className="cl-content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Order Date
                        </div>
                        <div>
                          <SortIcon onClick={() => onShortData("order_date")} />
                        </div>
                      </div>
                    </Th>
                    <Th className="cl-content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Return Date
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("expected_return_date")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="cl-content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Location
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("order_location")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="cl-content-middle-title-tr">For Days</Th>
                    <Th className="cl-content-middle-title-tr">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {placeOrder.list && placeOrder.list.length > 0
                    ? placeOrder.list.map((item, index) => {
                        return (
                          <Tr key={index}>
                            <div className="div-square-check">
                              <Checkbox
                                value={item.order_id}
                                onChange={(e) => handlePlaceCheckbox(e, item)}
                              />
                              <div className="cl-content-chalan-box">
                                <Td
                                  className="cat-content-middle-name-data customer-name"
                                  onClick={() => onSummery(item)}
                                >
                                  {item?.chalan_no !== null
                                    ? item.chalan_no
                                    : "-"}
                                </Td>
                              </div>
                            </div>

                            <Td className="content-middle-email-data">
                              {item?.order_date?.trim() !== ""
                                ? moment(item.order_date).format("DD-MM-YYYY")
                                : "-"}
                            </Td>
                            <Td className="content-middle-name-data">
                              {item?.expected_return_date?.trim() !== ""
                                ? moment(item.expected_return_date).format(
                                    "DD-MM-YYYY"
                                  )
                                : "-"}
                            </Td>
                            <Td className="content-middle-email-data">
                              {item?.order_location?.trim() !== ""
                                ? item.order_location
                                : "-"}
                            </Td>
                            <Td className="content-middle-name-data">
                              15 DAYS
                            </Td>
                            <Td className="content-middle-name-data">
                              <div className="cl-content-middle-action-data-div">
                                <img
                                  className="cl-content-middle-action-data-div-delete"
                                  src={EyeIcon}
                                  onClick={() => onSummery(item)}
                                />
                              </div>
                            </Td>
                          </Tr>
                        );
                      })
                    : null}
                </Tbody>
              </Table>
            </div>
          ) : (
            <LotiFiles message={"No Data Found For Order History!"} />
          )}
        </div>
      )}
      {placeOrder?.list?.length > 0 ? (
        <div className="content-bottam">
          <Pagination
            selectedPage={selectedPage}
            totalCount={placeOrder?.total_pages ?? 1}
            onPageChange={(page) => setSelectedPage(page)}
            itemsPerPage={4}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default POContentMiddle;
