import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InputText from "../../components/InputText/InputText";
import { ValidatePassword } from "../../helpers/validators";
import axios from "axios";
import "./AdminLogin.css";
import LoadingSpin from "react-loading-spin";
import { BASEURL } from "../../constant/constant";
import { alertService, AlertType } from "../../utils/alert.service";
import { ColoredPassIcon, SahjanandLogoIcon } from "../../assets";

function NewPassword() {
  const initialValues = { new_pass: "", con_new_pass: "" };
  const [data, setData] = useState(initialValues);
  const [errors, setErrors] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();

  const navigate = useNavigate();

  const newPasswordApi = async () => {
    const item = {
      email: state.user_email,
      new_password: data.con_new_pass,
      otp: state.otp,
    };
    try {
      setIsLoading(true);
      const response = await axios({
        url: BASEURL + "user/reset_password",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: item,
      });
      if (response.status === 200) {
        setIsLoading(false);
        navigate("/");
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const letsToast = () => {
    alertService.alert({
      type: AlertType.Error,
      message: "Confirm Password is Not Same",
    });
  };

  const validator = (formData = data) => {
    let newErrors = {};
    let isValid = true;

    const statusPassword = ValidatePassword(formData.new_pass);
    const statusConPassword = ValidatePassword(formData.con_new_pass);
    if (statusPassword) {
      newErrors.new_pass = statusPassword === 1 ? "Password is Required" : "";
      isValid = false;
    }
    if (statusConPassword) {
      newErrors.con_new_pass =
        statusPassword === 1 ? "Password is Required" : "";
      isValid = false;
    }
    if (formData.new_pass !== formData.con_new_pass) {
      letsToast();
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator()) {
      newPasswordApi();
    }
  };

  return (
    <section className="login-main-container">
      <div className="card">
        <img className="al-logo" src={SahjanandLogoIcon} />
        <h2 className="al-title">Forgot Password</h2>
        <form onSubmit={handleSubmit}>
          <InputText
            label="New Password"
            type="password"
            placeholder="New Password"
            src={ColoredPassIcon}
            value={data.new_pass}
            onChange={(e) =>
              setData((prevValue) => ({
                ...prevValue,
                new_pass: e.target.value,
              }))
            }
            error={errors?.new_pass}
          />
          <InputText
            label="Confirm New Password"
            type="password"
            placeholder="Confirm New Password"
            src={ColoredPassIcon}
            value={data.con_new_pass}
            onChange={(e) =>
              setData((prevValue) => ({
                ...prevValue,
                con_new_pass: e.target.value,
              }))
            }
            error={errors?.con_new_pass}
          />
          <div className="button-container">
            <button className="button" disabled={isLoading}>
              {isLoading ? (
                <LoadingSpin
                  primaryColor="white"
                  size="30px"
                  animationDuration="1s"
                />
              ) : (
                "SUBMIT"
              )}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default NewPassword;
