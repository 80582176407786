import React from "react";

const Checkbox = ({ onChange, value }) => {
  return (
    <div className="checkbox-comp">
      <input type="checkbox" onChange={onChange} value={value} />
    </div>
  );
};

export default Checkbox;
