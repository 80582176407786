import React from "react";
import SplineChart from "../ChartsComp/Charts.js";
import { useNavigate } from "react-router-dom";
import { OrderIcon, RupeeIcon } from "../../assets/index.js";

const DashContentTop = ({ setRevenueType, cardData, revenueData }) => {
  const navigate = useNavigate();
  const dash_data = [
    {
      id: 1,
      title: "Total Profit",
      price: cardData.total_profit,
      desc: "View all Transaction",
      btn_icon: RupeeIcon,
      className: "dash-top-card",
      path: "/transaction-year",
    },
    {
      id: 2,
      title: "Total Expenses",
      price: cardData.total_expense,
      desc: "View all Expenses",
      btn_icon: RupeeIcon,
      className: "dash-top-card margin-left",
      path: "/expenses",
    },
    {
      id: 3,
      title: "Total Order",
      price: cardData.total_orders,
      desc: "View all Orders",
      btn_icon: OrderIcon,
      className: "dash-top-card margin-left",
      path: "/customer",
    },
    {
      id: 4,
      title: "Total Stock",
      price: cardData.total_stock,
      desc: "View all Stocks",
      btn_icon: RupeeIcon,
      className: "dash-top-card margin-left",
      path: "/stock",
    },
  ];

  const total_revenue =
    revenueData?.result?.reduce((a, b) => a + b?.Revenue ?? 0, 0) ?? 0;

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  return (
    <div>
      <div className="dash-top">
        {dash_data.map((item) => (
          <div>
            <div className={item.className}>
              <div className="col-md-7 dash-top-card-details-left">
                <span className="dash-top-card-details-left-title">
                  {item.title}
                </span>
                <span className="dash-top-card-details-left-price">
                  {kFormatter(item?.price)?.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    useGrouping: true,
                  })}
                </span>
                <span
                  onClick={() => navigate(item.path)}
                  className="dash-top-card-details-left-desc"
                >
                  {item.desc}
                </span>
              </div>
              <div className="col-md-5 dash-top-card-details-right">
                <span className="dash-top-card-details-right-desc">
                  <img src={item.growIcon} /> &nbsp; {item.percentage}
                </span>
                <div className="dash-top-card-details-right-btn-div">
                  <button
                    disabled={true}
                    className="dash-top-card-details-right-btn"
                  >
                    <img src={item.btn_icon} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="dash-top-sec-part">
        <div className="dash-top-sec-part-first">
          <div className="dash-top-sec-part-first-row">
            <span className="dash-top-sec-part-first-row-revenue">Revenue</span>
          </div>
          <div className="dash-top-sec-part-first-row-dropdown">
            <select
              className="chart-dropdown-select"
              onChange={(e) => setRevenueType(e.target.value)}
            >
              {["Monthly", "Yearly"].map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="dash-top-sec-part-second-row">
          <SplineChart revenueData={revenueData} />
        </div>
        <div className="dash-top-sec-part-third-row">
          <span className="dash-top-sec-part-third-row-revenue-title">
            Total Revenue
          </span>
          <span className="dash-top-sec-part-third-row-revenue">
            ₹{" "}
            {parseFloat(total_revenue ?? 0)
              .toFixed(2)
              ?.toLocaleString("en-US", {
                minimumFractionDigits: 0,
                useGrouping: true,
              })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DashContentTop;
