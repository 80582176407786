import React, { useRef, useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { ReturnSummery } from "../Popup/Popup";
import moment from "moment";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import LotiFiles from "../Lotifiles/LotiFiles";
import Pagination from "../Pagination/Pagination";
import ReactToPrint from "react-to-print";
import SortIcon from "../AcInputText/SortIcon";
import Checkbox from "../AcInputText/Checkbox";
import ReturnOrderInvoice from "../ReturnOrderInvoice";
import { AppColors } from "../colors";
import { EyeIcon } from "../../assets";

const ROContentBottam = ({
  placeOrder,
  s_id,
  returnOrder,
  isLoading,
  setSiteSize,
  setSiteSelectedPage,
  siteSelectedPage,
  setCustomerSize,
  setCustomerSelectedPage,
  customerSelectedPage,
  customer_name,
  customer_phone,
  onShortData,
}) => {
  const [summeryOpen, setSummeryOpen] = useState(false);
  const [summeryItem, setSummeryItem] = useState("");
  const [openInvoice, setOpenInvoice] = useState(false);
  const [arr, setArr] = useState([]);
  const [isChecked, setIsChecked] = useState("");

  const toggleSummeryPopup = (mapItem) => {
    setSummeryItem(mapItem);
    setSummeryOpen(!summeryOpen);
    setOpenInvoice(!openInvoice);
  };

  const componentRef = useRef();

  const printDocument = () => {
    window.print();
  };

  let total_damage_qty = 0;
  let total_ext_days = 0;
  let total_ext_days_rent = 0;
  let total_payable_payment = 0;
  let total_remain_payment = 0;
  let total_charges = summeryItem.labour_charge + summeryItem.vehicle_charge;

  for (let i = 0; i < summeryItem?.return_order_details?.length; i++) {
    total_ext_days += summeryItem?.return_order_details[i]?.total_rent;
    total_damage_qty +=
      summeryItem?.return_order_details[i]?.total_damaged_cost;
    total_ext_days_rent +=
      summeryItem?.return_order_details[i]?.total_extra_days_rent;
    total_payable_payment +=
      summeryItem?.return_order_details[i]?.payable_amount;
    total_remain_payment +=
      summeryItem?.return_order_details[i]?.remaining_payment;
  }

  const handleCheckbox = (e, mapItem) => {
    const { value, checked } = e.target;
    if (checked == true) {
      setArr((prevValue) => [...prevValue, mapItem]);
    } else {
      setArr(arr.filter((item) => item !== mapItem));
    }
    setIsChecked(checked);
  };

  return (
    <div>
      <span
        className="content-top-left-title"
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        RETURN ORDER SUMMARY
      </span>
      {s_id ? (
        <div>
          <span className="cat-content-top-left-dropdown">
            Show&nbsp;
            <select
              id="number-dd"
              name="number"
              onChange={(e) => setSiteSize(e.target.value)}
            >
              {[10, 25, 50, 75, 100, 125, 150, 175, 200].map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            &nbsp; Entries
          </span>
          {isLoading ? (
            <TableLoader />
          ) : (
            <div>
              {placeOrder?.list?.length > 0 ? (
                <div className="content-middle">
                  <Table className="cat-content-middle-main">
                    <Thead>
                      <Tr>
                        <Th className="content-middle-title-tr">
                          <div className="sort-main">
                            <div className="content-middle-title-tr-sort-name">
                              Chalan No.
                            </div>
                            <div>
                              <SortIcon
                                onClick={() => onShortData("chalan_no")}
                              />
                            </div>
                          </div>
                        </Th>
                        <Th className="content-middle-title-tr">Site Name</Th>
                        <Th className="content-middle-title-tr">
                          <div className="sort-main">
                            <div className="content-middle-title-tr-sort-name">
                              Return Order Date
                            </div>
                            <div>
                              <SortIcon
                                onClick={() => onShortData("order_return_date")}
                              />
                            </div>
                          </div>
                        </Th>
                        <Th className="content-middle-title-tr">
                          <div className="sort-main">
                            <div className="content-middle-title-tr-sort-name">
                              Labour Charge
                            </div>
                            <div>
                              <SortIcon
                                onClick={() => onShortData("labour_charge")}
                              />
                            </div>
                          </div>
                        </Th>
                        <Th className="content-middle-title-tr">
                          <div className="sort-main">
                            <div className="content-middle-title-tr-sort-name">
                              Vehicle Charge
                            </div>
                            <div>
                              <SortIcon
                                onClick={() => onShortData("vehicle_charge")}
                              />
                            </div>
                          </div>
                        </Th>
                        <Th className="content-middle-title-tr">
                          Total Charge
                        </Th>
                        <Th className="content-middle-title-tr">Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {placeOrder?.list?.length > 0
                        ? placeOrder?.list?.map((mapItem) => {
                            return (
                              <Tr>
                                <div className="div-square-check">
                                  <Checkbox
                                    value={mapItem.return_order_id}
                                    onChange={(e) => handleCheckbox(e, mapItem)}
                                  />
                                  <div className="cl-content-chalan-box">
                                    <Td
                                      className="cat-content-middle-name-data customer-name"
                                      onClick={() =>
                                        toggleSummeryPopup(mapItem)
                                      }
                                    >
                                      {mapItem?.chalan_no !== null
                                        ? mapItem.chalan_no
                                        : "-"}
                                    </Td>
                                  </div>
                                </div>
                                <Td className="cat-content-middle-name-data">
                                  {mapItem.site_name}
                                </Td>
                                <Td className="content-middle-email-data">
                                  {mapItem?.order_return_date?.trim() !== ""
                                    ? moment(mapItem.order_return_date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "-"}
                                </Td>
                                <Td className="ro-content-middle-e-days-data">
                                  {mapItem.labour_charge}
                                </Td>
                                <Td className="ro-content-middle-e-days-data">
                                  {mapItem.vehicle_charge}
                                </Td>
                                <Td className="ro-content-middle-e-days-data">
                                  {mapItem.labour_charge +
                                    mapItem.vehicle_charge}
                                </Td>
                                <Td className="cat-content-middle-name-data">
                                  <div className="cl-content-middle-name-data-div">
                                    <img
                                      className="cl-content-middle-action-data-div-delete"
                                      src={EyeIcon}
                                      onClick={() =>
                                        toggleSummeryPopup(mapItem)
                                      }
                                    />
                                  </div>
                                </Td>
                              </Tr>
                            );
                          })
                        : null}
                    </Tbody>
                  </Table>
                </div>
              ) : (
                <LotiFiles message={"No Data Found For Return Orders!"} />
              )}
            </div>
          )}
          <div>
            {placeOrder?.list?.length > 0 ? (
              <div className="content-bottam">
                <Pagination
                  selectedPage={siteSelectedPage}
                  totalCount={placeOrder?.total_pages ?? 1}
                  onPageChange={(page) => setSiteSelectedPage(page)}
                  itemsPerPage={4}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          {summeryOpen && (
            <ReturnSummery
              summeryContent={
                <div>
                  <div>
                    <span className="cl-summary-title">
                      RETURN ORDER DETAILS &nbsp;
                      <span style={{ color: AppColors.darkRed }}>
                        RETURN DATE : &nbsp;
                      </span>
                      {summeryItem?.order_return_date?.trim() !== ""
                        ? moment(summeryItem.order_return_date).format(
                            "DD-MM-YYYY"
                          )
                        : "-"}
                    </span>
                    <div className="cl-summary-hr" />
                    {isLoading ? (
                      <TableLoader />
                    ) : (
                      <div>
                        {summeryItem?.return_order_details?.length > 0 ? (
                          <div className="content-middle">
                            <Table className="content-middle-main">
                              <Thead>
                                <Tr>
                                  <Th className="content-middle-title-tr ro-content-middle-no">
                                    No
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-name">
                                    Item Name
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-name">
                                    Order Date
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-name">
                                    Return Order Date
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-r-qty">
                                    Return Qty
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-d-qty">
                                    Unit Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-d-q-charge">
                                    Total Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-e-days">
                                    Extra Days
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-e-days">
                                    Discount Days
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-t-days">
                                    Extra Days Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-e-d-rent">
                                    Total Extra Days Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-t-rent">
                                    Damage Qty
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-t-e-d-rent">
                                    Total Damage Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-o-date">
                                    Damage Qty Cost
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-t-amount">
                                    Payable Amount
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {summeryItem?.return_order_details &&
                                summeryItem?.return_order_details?.length > 0
                                  ? summeryItem?.return_order_details?.map(
                                      (item, index) => {
                                        return (
                                          <Tr>
                                            <Td className="ro-content-middle-no-data">
                                              {index + 1}
                                            </Td>
                                            <Td className="ro-content-middle-name-data">
                                              {item.item_name}
                                            </Td>
                                            <Td className="ro-content-middle-name-data">
                                              {item?.order_date?.trim() !== ""
                                                ? moment(
                                                    item.order_date
                                                  ).format("DD-MM-YYYY")
                                                : "-"}
                                            </Td>
                                            <Td className="ro-content-middle-name-data">
                                              {item?.order_date?.trim() !== ""
                                                ? moment(item.order_date)
                                                    .add(14, "days")
                                                    .format("DD-MM-YYYY")
                                                : "-"}
                                            </Td>
                                            <Td className="ro-content-middle-r-qty-data">
                                              {item.return_quantity}
                                            </Td>
                                            <Td className="ro-content-middle-e-d-rent-data">
                                              {item.unit_rent.toFixed(2)}
                                            </Td>
                                            <Td className="ro-content-middle-t-e-d-rent-data">
                                              {item.total_rent.toFixed(2)}
                                            </Td>
                                            <Td className="ro-content-middle-r-date-data">
                                              {item.extra_days}
                                            </Td>
                                            <Td className="ro-content-middle-r-date-data">
                                              {item.discounted_days}
                                            </Td>
                                            <Td className="ro-content-middle-u-rent-data">
                                              {item.extra_days_rent.toFixed(2)}
                                            </Td>
                                            <Td className="ro-content-middle-e-days-data">
                                              {item.total_extra_days_rent.toFixed(
                                                2
                                              )}
                                            </Td>
                                            <Td className="ro-content-middle-t-rent-data">
                                              {item.damaged_quantity}
                                            </Td>
                                            <Td className="ro-content-middle-t-days-data">
                                              {item.total_damaged_cost.toFixed(
                                                2
                                              )}
                                            </Td>
                                            <Td className="ro-content-middle-o-date-data">
                                              {item.damaged_cost_item.toFixed(
                                                2
                                              )}
                                            </Td>
                                            <Td className="ro-content-middle-d-qty-data">
                                              {item.payable_amount.toFixed(2)}
                                            </Td>
                                          </Tr>
                                        );
                                      }
                                    )
                                  : null}
                              </Tbody>
                              <Tbody>
                                <Tr>
                                  <Td
                                    className="ro-content-middle-ext-3"
                                    colspan="6"
                                  >
                                    Total Rent
                                  </Td>
                                  <Td className="ro-content-middle-ext-4">
                                    {total_ext_days.toFixed(2)}
                                  </Td>
                                  <Td
                                    className="ro-content-middle-ext-5"
                                    colspan="3"
                                    style={{ textAlign: "right" }}
                                  >
                                    Total Ext. Days Rent
                                  </Td>
                                  <Td className="ro-content-middle-ext-6">
                                    {total_ext_days_rent?.toFixed(2)}
                                  </Td>
                                  <Td
                                    className="ro-content-middle-ext-1"
                                    colspan="1"
                                  >
                                    Total Damage Cost
                                  </Td>
                                  <Td className="ro-content-middle-ext-2">
                                    {total_damage_qty?.toFixed(2)}
                                  </Td>
                                  <Td
                                    className="ro-content-middle-ext-1"
                                    colspan="1"
                                  >
                                    Total Amount
                                  </Td>
                                  <Td className="ro-content-middle-ext-7">
                                    ₹ {total_payable_payment?.toFixed(2)}
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td
                                    className="ro-content-middle-v-1"
                                    colspan="14"
                                  >
                                    Vehicle Rent
                                  </Td>
                                  <Td className="ro-content-middle-v-2">
                                    ₹ {summeryItem?.vehicle_charge?.toFixed(2)}
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td
                                    className="ro-content-middle-l-1"
                                    colspan="14"
                                  >
                                    Labour Rent
                                  </Td>
                                  <Td className="ro-content-middle-l-2">
                                    ₹ {summeryItem?.labour_charge?.toFixed(2)}
                                  </Td>
                                </Tr>
                                <Tr style={{ fontWeight: "bold" }}>
                                  <Td
                                    className="ro-content-middle-t-1"
                                    colspan="12"
                                  >
                                    Total Remaining Payment
                                  </Td>
                                  <Td className="ro-content-middle-t-2">
                                    ₹ {total_remain_payment?.toFixed(2)}
                                  </Td>
                                  <Td className="ro-content-middle-t-1">
                                    Total Payable Amount
                                  </Td>
                                  <Td className="ro-content-middle-t-2">
                                    ₹{" "}
                                    {(
                                      total_payable_payment + total_charges
                                    )?.toFixed(2)}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </div>
                        ) : (
                          <LotiFiles
                            message={"No Data Found For Return Orders!"}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="cl-summary-btn-div">
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className="cl-summary-btn"
                          style={{ backgroundColor: AppColors.red }}
                          onClick={printDocument}
                        >
                          Print
                        </button>
                      )}
                      content={() => componentRef.current}
                    />
                    <button
                      style={{ marginLeft: "10px" }}
                      className="cl-summary-btn"
                      onClick={toggleSummeryPopup}
                    >
                      Close
                    </button>
                  </div>
                </div>
              }
            />
          )}
        </div>
      ) : (
        <div>
          <span className="cat-content-top-left-dropdown">
            Show&nbsp;
            <select
              id="number-dd"
              name="number"
              onChange={(e) => setCustomerSize(e.target.value)}
            >
              {[10, 25, 50, 75, 100, 125, 150, 175, 200].map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            &nbsp; Entries
          </span>
          {isLoading ? (
            <TableLoader />
          ) : (
            <div>
              {returnOrder?.list?.length > 0 ? (
                <div className="content-middle">
                  <Table className="cat-content-middle-main">
                    <Thead>
                      <Tr>
                        <Th className="content-middle-title-tr">
                          <div className="sort-main">
                            <div className="content-middle-title-tr-sort-name">
                              Chalan No.
                            </div>
                            <div>
                              <SortIcon
                                onClick={() => onShortData("chalan_no")}
                              />
                            </div>
                          </div>
                        </Th>
                        <Th className="content-middle-title-tr">Site Name</Th>
                        <Th className="content-middle-title-tr">
                          <div className="sort-main">
                            <div className="content-middle-title-tr-sort-name">
                              Return Order Date
                            </div>
                            <div>
                              <SortIcon
                                onClick={() => onShortData("order_return_date")}
                              />
                            </div>
                          </div>
                        </Th>
                        <Th className="content-middle-title-tr">
                          <div className="sort-main">
                            <div className="content-middle-title-tr-sort-name">
                              Labour Charge
                            </div>
                            <div>
                              <SortIcon
                                onClick={() => onShortData("labour_charge")}
                              />
                            </div>
                          </div>
                        </Th>
                        <Th className="content-middle-title-tr">
                          <div className="sort-main">
                            <div className="content-middle-title-tr-sort-name">
                              Vehicle Charge
                            </div>
                            <div>
                              <SortIcon
                                onClick={() => onShortData("vehicle_charge")}
                              />
                            </div>
                          </div>
                        </Th>
                        <Th className="content-middle-title-tr">
                          Total Charge
                        </Th>
                        <Th className="content-middle-title-tr">Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {returnOrder?.list?.length > 0
                        ? returnOrder?.list?.map((mapItem, index) => {
                            return (
                              <Tr key={index}>
                                <div className="div-square-check">
                                  <Checkbox
                                    value={mapItem.return_order_id}
                                    onChange={(e) => handleCheckbox(e, mapItem)}
                                  />
                                  <div className="cl-content-chalan-box">
                                    <Td
                                      className="cat-content-middle-name-data customer-name"
                                      onClick={() =>
                                        toggleSummeryPopup(mapItem)
                                      }
                                    >
                                      {mapItem?.chalan_no !== null
                                        ? mapItem.chalan_no
                                        : "-"}
                                    </Td>
                                  </div>
                                </div>
                                <Td className="cat-content-middle-name-data">
                                  {mapItem.site_name}
                                </Td>
                                <Td className="content-middle-email-data">
                                  {mapItem?.order_return_date?.trim() !== ""
                                    ? moment(mapItem.order_return_date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "-"}
                                </Td>
                                <Td className="ro-content-middle-e-days-data">
                                  {mapItem.labour_charge}
                                </Td>
                                <Td className="ro-content-middle-e-days-data">
                                  {mapItem.vehicle_charge}
                                </Td>
                                <Td className="ro-content-middle-e-days-data">
                                  {mapItem.labour_charge +
                                    mapItem.vehicle_charge}
                                </Td>
                                <Td className="cat-content-middle-name-data">
                                  <div className="cl-content-middle-name-data-div">
                                    <img
                                      className="cl-content-middle-action-data-div-delete"
                                      src={EyeIcon}
                                      onClick={() =>
                                        toggleSummeryPopup(mapItem)
                                      }
                                    />
                                  </div>
                                </Td>
                              </Tr>
                            );
                          })
                        : null}
                    </Tbody>
                  </Table>
                </div>
              ) : (
                <LotiFiles message={"No Data Found For Return Orders!"} />
              )}
            </div>
          )}
          <div>
            {returnOrder?.list?.length > 0 ? (
              <div
                className="content-bottam"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Pagination
                  selectedPage={customerSelectedPage}
                  totalCount={returnOrder?.total_pages ?? 1}
                  onPageChange={(page) => {
                    setCustomerSelectedPage(page);
                  }}
                  itemsPerPage={4}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          {summeryOpen && (
            <ReturnSummery
              summeryContent={
                <div>
                  <div>
                    <span className="cl-summary-title">
                      RETURN ORDER DETAILS &nbsp;
                      <span style={{ color: AppColors.darkRed }}>
                        RETURN DATE : &nbsp;
                      </span>
                      {summeryItem?.order_return_date?.trim() !== ""
                        ? moment(summeryItem.order_return_date).format(
                            "DD-MM-YYYY"
                          )
                        : "-"}
                    </span>
                    <div className="cl-summary-hr" />
                    {isLoading ? (
                      <TableLoader />
                    ) : (
                      <div>
                        {summeryItem?.return_order_details?.length > 0 ? (
                          <div className="content-middle">
                            <Table className="content-middle-main">
                              <Thead>
                                <Tr>
                                  <Th className="content-middle-title-tr ro-content-middle-no">
                                    No
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-name">
                                    Item Name
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-name">
                                    Order Date
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-name">
                                    Return Order Date
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-name">
                                    Site Name
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-r-qty">
                                    Return Qty
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-d-qty">
                                    Unit Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-d-q-charge">
                                    Total Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-e-days">
                                    Extra Days
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-e-days">
                                    Discount Days
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-t-days">
                                    Extra Days Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-e-d-rent">
                                    Total Extra Days Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-t-rent">
                                    Damage Qty
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-t-e-d-rent">
                                    Total Damage Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-o-date">
                                    Damage Qty Cost
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-t-amount">
                                    Payable Amount
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {summeryItem?.return_order_details &&
                                summeryItem?.return_order_details?.length > 0
                                  ? summeryItem?.return_order_details?.map(
                                      (item, index) => {
                                        return (
                                          <Tr key={index}>
                                            <Td className="ro-content-middle-no-data">
                                              {index + 1}
                                            </Td>
                                            <Td className="ro-content-middle-name-data">
                                              {item.item_name}
                                            </Td>
                                            <Td className="ro-content-middle-name-data">
                                              {item?.order_date?.trim() !== ""
                                                ? moment(
                                                    item.order_date
                                                  ).format("DD-MM-YYYY")
                                                : "-"}
                                            </Td>
                                            <Td className="ro-content-middle-name-data">
                                              {item?.order_date?.trim() !== ""
                                                ? moment(item.order_date)
                                                    .add(14, "days")
                                                    .format("DD-MM-YYYY")
                                                : "-"}
                                            </Td>
                                            <Td className="ro-content-middle-name-data">
                                              {item.site_name}
                                            </Td>
                                            <Td className="ro-content-middle-r-qty-data">
                                              {item.return_quantity}
                                            </Td>
                                            <Td className="ro-content-middle-e-d-rent-data">
                                              {item.unit_rent.toFixed(2)}
                                            </Td>
                                            <Td className="ro-content-middle-t-e-d-rent-data">
                                              {item.total_rent.toFixed(2)}
                                            </Td>
                                            <Td className="ro-content-middle-r-date-data">
                                              {item.extra_days}
                                            </Td>
                                            <Td className="ro-content-middle-r-date-data">
                                              {item.discounted_days}
                                            </Td>
                                            <Td className="ro-content-middle-u-rent-data">
                                              {item.extra_days_rent.toFixed(2)}
                                            </Td>
                                            <Td className="ro-content-middle-e-days-data">
                                              {item.total_extra_days_rent.toFixed(
                                                2
                                              )}
                                            </Td>
                                            <Td className="ro-content-middle-t-rent-data">
                                              {item.damaged_quantity}
                                            </Td>
                                            <Td className="ro-content-middle-t-days-data">
                                              {item.total_damaged_cost.toFixed(
                                                2
                                              )}
                                            </Td>
                                            <Td className="ro-content-middle-o-date-data">
                                              {item.damaged_cost_item.toFixed(
                                                2
                                              )}
                                            </Td>
                                            <Td className="ro-content-middle-d-qty-data">
                                              {item.payable_amount.toFixed(2)}
                                            </Td>
                                          </Tr>
                                        );
                                      }
                                    )
                                  : null}
                              </Tbody>
                              <Tbody>
                                <Tr>
                                  <Td
                                    className="ro-content-middle-ext-3"
                                    colspan="7"
                                  >
                                    Total Rent
                                  </Td>
                                  <Td className="ro-content-middle-ext-4">
                                    {total_ext_days.toFixed(2)}
                                  </Td>
                                  <Td
                                    className="ro-content-middle-ext-5"
                                    colspan="3"
                                    style={{ textAlign: "right" }}
                                  >
                                    Total Ext. Days Rent
                                  </Td>
                                  <Td className="ro-content-middle-ext-6">
                                    {total_ext_days_rent?.toFixed(2)}
                                  </Td>
                                  <Td
                                    className="ro-content-middle-ext-1"
                                    colspan="1"
                                  >
                                    Total Damage Cost
                                  </Td>
                                  <Td className="ro-content-middle-ext-2">
                                    {total_damage_qty?.toFixed(2)}
                                  </Td>
                                  <Td
                                    className="ro-content-middle-ext-1"
                                    colspan="1"
                                  >
                                    Total Amount
                                  </Td>
                                  <Td className="ro-content-middle-ext-7">
                                    ₹ {total_payable_payment?.toFixed(2)}
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td
                                    className="ro-content-middle-v-1"
                                    colspan="15"
                                  >
                                    Vehicle Rent
                                  </Td>
                                  <Td className="ro-content-middle-v-2">
                                    ₹ {summeryItem?.vehicle_charge?.toFixed(2)}
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td
                                    className="ro-content-middle-l-1"
                                    colspan="15"
                                  >
                                    Labour Rent
                                  </Td>
                                  <Td className="ro-content-middle-l-2">
                                    ₹ {summeryItem?.labour_charge?.toFixed(2)}
                                  </Td>
                                </Tr>
                                <Tr style={{ fontWeight: "bold" }}>
                                  <Td
                                    className="ro-content-middle-t-1"
                                    colspan="13"
                                  >
                                    Total Remaining Payment
                                  </Td>
                                  <Td className="ro-content-middle-t-2">
                                    ₹ {total_remain_payment?.toFixed(2)}
                                  </Td>
                                  <Td className="ro-content-middle-t-1">
                                    Total Payable Amount
                                  </Td>
                                  <Td className="ro-content-middle-t-2">
                                    ₹
                                    {(
                                      total_payable_payment + total_charges
                                    )?.toFixed(2)}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </div>
                        ) : (
                          <LotiFiles
                            message={"No Data Found For Return Orders!"}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="cl-summary-btn-div">
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className="cl-summary-btn"
                          style={{ backgroundColor: AppColors.red }}
                          onClick={printDocument}
                        >
                          Print
                        </button>
                      )}
                      content={() => componentRef.current}
                    />
                    <button
                      style={{ marginLeft: "10px" }}
                      className="cl-summary-btn"
                      onClick={toggleSummeryPopup}
                    >
                      Close
                    </button>
                  </div>
                </div>
              }
            />
          )}
        </div>
      )}
      {openInvoice ? (
        <div ref={componentRef}>
          {arr == "" ? (
            <ReturnOrderInvoice
              customer_phone={customer_phone}
              customer_name={customer_name}
              summeryItem={summeryItem}
              isChecked={isChecked}
            />
          ) : (
            <div>
              {arr.map((summeryItem) => (
                <ReturnOrderInvoice
                  customer_phone={customer_phone}
                  customer_name={customer_name}
                  summeryItem={summeryItem}
                  isChecked={isChecked}
                />
              ))}
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ROContentBottam;
