import axios from "axios";
import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { BASEURL } from "../../constant/constant";
import { ValidateMobile, ValidateName } from "../../helpers/validators";
import { AlertType, alertService } from "../../utils/alert.service";
import AddressInputText from "../AcInputText/AddressInputText";
import ButtonTop from "../AcInputText/ButtonTop";
import PaymentLimitInput from "../AcInputText/PaymentLimitInput";
import SearchBar from "../AcInputText/SearchBar";
import { BlackCrossIcon, DocPlusIcon } from "../../assets";
import AcInputText from "./../AcInputText/AcInputText";
import { AddCustomer } from "./../Popup/Popup";

const ContentTop = ({
  setSize,
  getCustomerList,
  isOpen,
  setIsOpen,
  siteList,
  setSiteList,
  initialValues,
  customerData,
  setCustomerData,
  errors,
  handleInputChange,
  setErrors,
  documentList,
  setDocumentList,
  handleDocumentChange,
  handleDocumentImageChange,
  data,
  searchInput,
  handleChangeSearch,
  setTimeLimitDrop,
  timeLimitDrop,
}) => {
  const [selected, setSelected] = useState("yes");

  const addCustomerDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    // const formData = new FormData();
    // documentList?.forEach((document) => {
    //   formData.append("docName", document.docName);
    //   formData.append("docImage", document.docImage[0]);
    // });

    const item = {
      full_name: customerData.name,
      phone: customerData.mobile,
      whatsapp: customerData.whatsapp,
      email: customerData.email,
      city: customerData.city,
      address: customerData.address,
      ref_name: customerData.refName,
      ref_phone: customerData.refMobile,
      unit: selected === "yes" ? "Rupees" : timeLimitDrop,
      amount:
        selected === "yes"
          ? customerData?.paymentLimit
          : customerData?.timeLimit,
      sites: siteList.map((a) => ({
        site_name: a?.siteName,
        site_address: a?.siteAddress,
      })),
    };

    try {
      const response = await axios({
        url: BASEURL + "customer/create",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        togglePopup();
        getCustomerList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const validator = () => {
    let newErrors = {};
    let isValid = true;
    const statusName = ValidateName(customerData.name);
    const statusMobile = ValidateMobile(customerData.mobile);

    if (statusName) {
      newErrors.name = statusName === 1 ? "Name is Required" : "";
      isValid = false;
    }
    if (statusMobile) {
      newErrors.mobile =
        statusMobile === 1 ? "Mobile is Required" : "Mobile is invalid";
      isValid = false;
    }

    siteList.map((a, b) => (a.siteName === "" ? letsToast() : ""));
    setErrors(newErrors);
    return isValid;
  };

  const AddClickSite = (e) => {
    e.preventDefault();
    setSiteList([...siteList, { siteName: "", siteAddress: "" }]);
  };

  const RemoveClickSite = (e, index) => {
    e.preventDefault();
    const rows = [...siteList];
    rows.splice(index, 1);
    setSiteList(rows);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    setCustomerData(initialValues);
    setSiteList([{ siteName: "", siteAddress: "" }]);
    setErrors("");
    setDocumentList([{ docName: "", docImage: "" }]);
  };

  const letsToast = () => {
    alertService.alert({
      type: AlertType.Error,
      message: "SiteName is Required",
    });
  };

  {
    letsToast === false ? setIsOpen(!isOpen) : setIsOpen(isOpen);
  }

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator()) {
      addCustomerDetail();
    }
  };

  const handlePaymentChange = (event) => {
    setSelected(event.target.value);
  };

  const addDropdown = (e) => {
    e.preventDefault();
    setDocumentList([...documentList, { docName: "", docImage: "" }]);
  };

  const removeDropdown = (e, index) => {
    e.preventDefault();
    const updatedFiles = documentList.filter((_, i) => i !== index);
    setDocumentList(updatedFiles);
  };

  return (
    <div className="content-top">
      <div className="content-top-left">
        <span className="content-top-left-title">CUSTOMER LIST</span>
        <span className="content-top-left-desc">
          All Customers Details Listed Here Also You Can Update or Delete
        </span>
        {data?.list?.length > 0 ? (
          <span className="content-top-left-dropdown">
            Show &nbsp;
            <select
              id="number-dd"
              name="number"
              onChange={(e) => setSize(e.target.value)}
            >
              {[10, 25, 50, 75, 100, 125, 150, 175, 200].map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            &nbsp; Entries
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="content-top-right">
        <ButtonTop onClick={togglePopup} name={"Add Customer"} />
        <SearchBar onChange={handleChangeSearch} value={searchInput} />
      </div>
      {isOpen && (
        <AddCustomer
          content={
            <form>
              <div className="addCustomer-first">
                <span className="addCustomer-title">CUSTOMER DETAILS</span>
                <span className="addCustomer-desc">All field are required</span>
              </div>
              <div className="addCustomer-second">
                <span className="addCustomer-second-title">
                  Personal Details
                  <div className="addCustomer-second-hr">
                    <hr />
                  </div>
                </span>
                <div className="addCustomer-second-firstRow">
                  <AcInputText
                    label="Name"
                    type="text"
                    placeholder="Name"
                    value={customerData.name}
                    onChange={(e) =>
                      setCustomerData((prevValue) => ({
                        ...prevValue,
                        name: e.target.value,
                      }))
                    }
                    error={errors?.name}
                  />
                  <AcInputText
                    label="Mobile"
                    maxLength={"10"}
                    placeholder="Mobile"
                    value={customerData.mobile}
                    onChange={(e) =>
                      e.target.value.length <= 10 &&
                      setCustomerData((prevValue) => ({
                        ...prevValue,
                        mobile: e.target.value.replace(/\D/g, ""),
                      }))
                    }
                    error={errors?.mobile}
                  />
                  <AcInputText
                    label="WhatsApp Mobile"
                    placeholder="Alternet Mobile"
                    maxLength={"10"}
                    value={customerData.whatsapp}
                    onChange={(e) =>
                      e.target.value.length <= 10 &&
                      setCustomerData((prevValue) => ({
                        ...prevValue,
                        whatsapp: e.target.value.replace(/\D/g, ""),
                      }))
                    }
                  />
                </div>
                <div className="addCustomer-second-firstRow">
                  <AcInputText
                    style={{ textTransform: "lowercase" }}
                    label="E-mail"
                    type="text"
                    placeholder="E-mail"
                    value={customerData.email}
                    onChange={(e) =>
                      setCustomerData((prevValue) => ({
                        ...prevValue,
                        email: e.target.value,
                      }))
                    }
                    error={errors.email}
                  />
                  <AcInputText
                    label="City"
                    type="text"
                    placeholder="City"
                    value={customerData.city}
                    onChange={(e) =>
                      setCustomerData((prevValue) => ({
                        ...prevValue,
                        city: e.target.value,
                      }))
                    }
                  />
                  <AddressInputText
                    label="Address"
                    type="text"
                    placeholder="Address"
                    value={customerData.address}
                    onChange={(e) =>
                      setCustomerData((prevValue) => ({
                        ...prevValue,
                        address: e.target.value,
                      }))
                    }
                  />
                </div>
                {siteList?.map((x, i) => {
                  return (
                    <div>
                      <span className="addCustomer-second-title">
                        Site Details
                        {siteList.length === 1 ? (
                          ""
                        ) : (
                          <button
                            className="content-top-right-btn-close"
                            onClick={(e) => RemoveClickSite(e, i)}
                          >
                            <img
                              className="content-top-right-btn-close-icon"
                              src={BlackCrossIcon}
                            />
                          </button>
                        )}
                        {siteList.length === 1 ? (
                          <button
                            className="addCustomer-second-right-btn"
                            onClick={AddClickSite}
                          >
                            + Add Site
                          </button>
                        ) : (
                          <button
                            className="addCustomer-second-right-btn-two"
                            onClick={AddClickSite}
                          >
                            + Add Site
                          </button>
                        )}
                        <div className="addCustomer-second-hr">
                          <hr />
                        </div>
                      </span>
                      <div className="addCustomer-second-firstRow">
                        <AcInputText
                          label="Site Name"
                          type="text"
                          name="siteName"
                          placeholder="Name"
                          value={x.siteName}
                          onChange={(e) => handleInputChange(e, i)}
                          error={errors.siteName}
                        />
                        <AddressInputText
                          label="Address"
                          type="text"
                          name="siteAddress"
                          placeholder="Address"
                          value={x.siteAddress}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </div>
                    </div>
                  );
                })}
                <span className="addCustomer-second-title">
                  Payment Limit
                  <div className="addCustomer-second-hr">
                    <hr />
                  </div>
                </span>
                <div
                  className="addCustomer-second-firstRow"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "-10px",
                    marginBottom: "10px",
                  }}
                >
                  <div className="payment-limit-div">
                    <label for="s-option" class="l-radio">
                      <input
                        type="radio"
                        name="selector"
                        tabindex="2"
                        value="yes"
                        checked={selected === "yes"}
                        onChange={handlePaymentChange}
                      />
                      <span className="payment-limit-text">Payment Limit</span>
                    </label>
                    <label for="f-option" className="l-radio">
                      <input
                        type="radio"
                        name="selector"
                        tabindex="1"
                        value="no"
                        checked={selected === "no"}
                        onChange={handlePaymentChange}
                      />
                      <span className="payment-limit-text">Time Limit</span>
                    </label>
                  </div>
                  {selected === "yes" ? (
                    <AcInputText
                      placeholder="Payment"
                      value={customerData.paymentLimit}
                      onChange={(e) =>
                        setCustomerData((prevValue) => ({
                          ...prevValue,
                          paymentLimit: e.target.value.replace(/\D/g, ""),
                        }))
                      }
                    />
                  ) : (
                    <div>
                      <PaymentLimitInput
                        placeholder="Time"
                        value={customerData.timeLimit}
                        onChange={(e) =>
                          setCustomerData((prevValue) => ({
                            ...prevValue,
                            timeLimit: e.target.value.replace(/\D/g, ""),
                          }))
                        }
                      />
                      <div className="payment-limit-dropdown">
                        <select
                          className="payment-limit-dropdown-select"
                          onChange={(e) => setTimeLimitDrop(e.target.value)}
                        >
                          {["Day", "Month", "Year"].map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
                <span className="addCustomer-second-title">
                  Reference Details
                  <div className="addCustomer-second-hr">
                    <hr />
                  </div>
                </span>
                <div className="addCustomer-second-firstRow">
                  <AcInputText
                    label="Name"
                    type="text"
                    placeholder="Name"
                    value={customerData.refName}
                    onChange={(e) =>
                      setCustomerData((prevValue) => ({
                        ...prevValue,
                        refName: e.target.value,
                      }))
                    }
                  />
                  <AcInputText
                    label="Mobile"
                    maxLength={"10"}
                    placeholder="Mobile"
                    value={customerData.refMobile}
                    onChange={(e) =>
                      e.target.value.length <= 10 &&
                      setCustomerData((prevValue) => ({
                        ...prevValue,
                        refMobile: e.target.value.replace(/\D/g, ""),
                      }))
                    }
                  />
                </div>
                <div>
                  {documentList.map((x, i) => (
                    <div key={i}>
                      <span className="addCustomer-second-title">
                        Document Details
                        {documentList.length > 1 && (
                          <button
                            className="content-top-right-btn-close"
                            onClick={(e) => removeDropdown(e, i)}
                          >
                            <img
                              className="content-top-right-btn-close-icon"
                              src={BlackCrossIcon}
                              alt="Remove"
                            />
                          </button>
                        )}
                        <button
                          className={
                            documentList.length === 1
                              ? "addCustomer-second-right-btn"
                              : "addCustomer-second-right-btn-two"
                          }
                          onClick={addDropdown}
                        >
                          + Add Document
                        </button>
                        <div className="addCustomer-second-hr">
                          <hr />
                        </div>
                      </span>
                      <div className="addCustomer-second-firstRow">
                        <div className="ac-inputText-input-wrapper">
                          <label
                            className="ac-inputText-label"
                            htmlFor={`docName-${i}`}
                          >
                            Select Document
                          </label>
                          <select
                            id={`docName-${i}`}
                            className="ac-inputText-input-container"
                            name="docName"
                            value={x.docName}
                            onChange={(e) => handleDocumentChange(e, i)}
                          >
                            <option value="">---select---</option>
                            <option value="Aadharcard">Aadharcard</option>
                            <option value="Passport">Passport</option>
                            <option value="Licence">Licence</option>
                            <option value="Voterid">Voterid</option>
                            <option value="Pancard">Pancard</option>
                          </select>
                        </div>
                        <div className="add-doc-main-div">
                          <label className="ac-inputText-label">
                            Upload Documents
                          </label>
                          <div className="add-doc">
                            {x.docImage.length === 0 ? (
                              <div className="add-doc">
                                <label
                                  className="doc-img"
                                  htmlFor={`files-${i}`}
                                >
                                  <img src={DocPlusIcon} alt="Upload" />
                                  <input
                                    id={`files-${i}`}
                                    style={{ visibility: "hidden" }}
                                    accept="image/*"
                                    type="file"
                                    name="docImage"
                                    multiple
                                    onChange={(e) =>
                                      handleDocumentImageChange(e, i)
                                    }
                                  />
                                </label>
                                <span className="doc-title">
                                  Upload Documents
                                </span>
                                <span className="doc-sub">
                                  You can only upload png and jpeg format
                                </span>
                              </div>
                            ) : (
                              <div className="selected-img">
                                {x.docImage.slice(1).map((img, idx) => (
                                  <img
                                    key={idx}
                                    src={img}
                                    className="selected-img"
                                    alt={`Selected ${idx}`}
                                  />
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="addCustomer-second-bottam-btn">
                <button
                  onClick={submitHandler}
                  className="addCustomer-second-bottam-btn-sub"
                >
                  Submit
                </button>
                <button
                  className="addCustomer-second-bottam-btn-can"
                  onClick={togglePopup}
                >
                  Cancel
                </button>
              </div>
            </form>
          }
        />
      )}
    </div>
  );
};

export default ContentTop;
