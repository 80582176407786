import moment from "moment";
import React from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { SahjanandLogoIcon } from "../assets";

const ReturnOrderInvoice = ({
  customer_phone,
  customer_name,
  summeryItem,
  arr,
  isChecked,
}) => {
  const total_damage_qty =
    summeryItem?.return_order_details?.reduce(
      (a, b) => a + b?.total_damaged_cost ?? 0,
      0
    ) ?? 0;
  const total_ext_days =
    summeryItem?.return_order_details?.reduce(
      (a, b) => a + b?.extra_days ?? 0,
      0
    ) ?? 0;
  const total_ext_days_rent =
    summeryItem?.return_order_details?.reduce(
      (a, b) => a + b?.total_extra_days_rent ?? 0,
      0
    ) ?? 0;
  const total_payable_payment =
    summeryItem?.return_order_details?.reduce(
      (a, b) => a + b?.payable_amount ?? 0,
      0
    ) ?? 0;
  const total_charges = summeryItem.labour_charge + summeryItem.vehicle_charge;

  return (
    <div>
      <div className="col-md-12 invoice-date-name">
        <div className="col-md-6">
          <span>{moment().format("DD-MM-YYYY, h:mm")}</span>
        </div>
        <div className="col-md-6">
          <span>Sahjanand Construction</span>
        </div>
      </div>
      <div className="invoice-main">
        <div className="col-md-12 invoice-part-first">
          <div className="col-md-4 invoice-part-first-left">
            <span className="invoice-part-first-text">Gopal Patel</span>
          </div>
          <div className="col-md-4 invoice-part-first-middle">
            <img
              className="invoice-part-first-middle-logo"
              src={SahjanandLogoIcon}
            />
            <span className="invoice-part-first-middle-name">
              સહજાનંદ કન્ટ્રકશન
            </span>
          </div>
          <div className="col-md-4 invoice-part-first-last">
            <span className="invoice-part-first-text">9173735497</span>
          </div>
        </div>
        <div>
          <div className="col-md-12 invoice-part-second">
            <div className="col-md-12 invoice-part-second-one">
              <span className="invoice-part-second-one-text">
                Returned Item Details
              </span>
            </div>
            <div className="col-md-12 invoice-part-second-two">
              <div className="col-md-6 invoice-part-second-two-1">
                <span>Return Date :</span>
                <span>
                  {" "}
                  {summeryItem?.order_return_date?.trim() !== ""
                    ? moment(summeryItem.order_return_date).format("DD-MM-YYYY")
                    : "-"}
                </span>
              </div>
              <div className="col-md-6">
                <span>Mobile :</span>
                <span>{customer_phone}</span>
              </div>
            </div>
            <div className="col-md-12 invoice-part-second-three">
              <div className="col-md-6 invoice-part-second-two-1">
                <span>Name :</span>
                <span>{customer_name}</span>
              </div>
              <div className="col-md-6">
                <span>Returned By :</span>
                <span>{customer_name}</span>
              </div>
            </div>
          </div>
          <div className="col-md-12 invoice-part-second">
            <div>
              <div className="ro-in">
                <Table className=" ro-in-table">
                  <Thead>
                    <Tr>
                      <Th className="content-middle-title-tr ro-in-content-middle-no">
                        No
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-name">
                        Item Name
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                        Order Date
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                        Return Date
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                        Return Qty
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-d-qty">
                        Unit Rent
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-d-q-charge">
                        Total Rent
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-e-days">
                        Extra Days
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-t-days">
                        Extra Days Rent
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-e-d-rent">
                        Total Extra Days Rent
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-t-rent">
                        Damage Qty
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-o-date">
                        Damage Qty Cost
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-t-e-d-rent">
                        Total Damage Rent
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-t-amount">
                        Remaining Payment
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-t-amount">
                        Payable Amount
                      </Th>
                    </Tr>
                  </Thead>
                  {summeryItem?.return_order_details &&
                  summeryItem?.return_order_details?.length > 0
                    ? summeryItem?.return_order_details?.map((item, index) => {
                        return (
                          <Tbody key={index}>
                            <Tr>
                              <Td className="ro-in-content-middle-no-data">
                                {index + 1}
                              </Td>
                              <Td className="ro-in-content-middle-name-data">
                                {item.item_name}
                              </Td>
                              <Td className="ro-in-content-middle-r-qty-data">
                                {item?.order_date?.trim() !== ""
                                  ? moment(item?.order_date).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </Td>
                              <Td className="ro-in-content-middle-r-qty-data">
                                {item?.order_date?.trim() !== ""
                                  ? moment(item.order_date)
                                      .add(14, "days")
                                      .format("DD-MM-YYYY")
                                  : "-"}
                              </Td>
                              <Td className="ro-in-content-middle-r-qty-data">
                                {item.return_quantity}
                              </Td>
                              <Td className="ro-in-content-middle-e-d-rent-data">
                                {item.unit_rent}
                              </Td>
                              <Td className="ro-in-content-middle-t-e-d-rent-data">
                                {item?.total_rent?.toLocaleString("en-US", {
                                  minimumFractionDigits: 0,
                                  useGrouping: true,
                                })}
                              </Td>
                              <Td className="ro-in-content-middle-r-date-data">
                                {item?.extra_days}
                              </Td>
                              <Td className="ro-in-content-middle-u-rent-data">
                                {item?.extra_days_rent?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 0,
                                    useGrouping: true,
                                  }
                                )}
                              </Td>
                              <Td className="ro-in-content-middle-e-days-data">
                                {item?.total_extra_days_rent?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 0,
                                    useGrouping: true,
                                  }
                                )}
                              </Td>
                              <Td className="ro-in-content-middle-t-rent-data">
                                {item?.damaged_quantity}
                              </Td>
                              <Td className="ro-in-content-middle-o-date-data">
                                {item?.damaged_cost_item}
                              </Td>
                              <Td className="ro-in-content-middle-t-days-data">
                                {item?.total_damaged_cost?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 0,
                                    useGrouping: true,
                                  }
                                )}
                              </Td>
                              <Td className="ro-in-content-middle-d-qty-data">
                                {item?.remaining_payment?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 0,
                                    useGrouping: true,
                                  }
                                )}
                              </Td>
                              <Td className="ro-in-content-middle-d-qty-data">
                                {item?.payable_amount?.toLocaleString("en-US", {
                                  minimumFractionDigits: 0,
                                  useGrouping: true,
                                })}
                              </Td>
                            </Tr>
                          </Tbody>
                        );
                      })
                    : null}
                  <Tbody>
                    <Tr>
                      <Td className="ro-in-content-middle-ext-3" colspan="7">
                        Total Ext. Days
                      </Td>
                      <Td className="ro-in-content-middle-ext-4">
                        {total_ext_days}
                      </Td>
                      <Td className="ro-in-content-middle-ext-5">
                        Total Ext. Days Rent
                      </Td>
                      <Td className="ro-in-content-middle-ext-6">
                        ₹{" "}
                        {total_ext_days_rent?.toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                          useGrouping: true,
                        })}
                      </Td>
                      <Td className="ro-in-content-middle-ext-1" colspan="2">
                        Total Damage Cost
                      </Td>
                      <Td className="ro-in-content-middle-ext-2">
                        ₹{" "}
                        {total_damage_qty?.toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                          useGrouping: true,
                        })}
                      </Td>
                      <Td className="ro-in-content-middle-ext-1" colspan="1">
                        Total Amount
                      </Td>
                      <Td className="ro-in-content-middle-ext-7">
                        ₹{" "}
                        {total_payable_payment?.toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                          useGrouping: true,
                        })}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td className="ro-in-content-middle-v-1" colspan="14">
                        Vehicle Rent
                      </Td>
                      <Td className="ro-in-content-middle-v-2">
                        ₹{" "}
                        {summeryItem?.vehicle_charge?.toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                          useGrouping: true,
                        })}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td className="ro-in-content-middle-l-1" colspan="14">
                        Labour Rent
                      </Td>
                      <Td className="ro-in-content-middle-l-2">
                        ₹{" "}
                        {summeryItem?.labour_charge?.toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                          useGrouping: true,
                        })}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td className="ro-in-content-middle-t-1" colspan="14">
                        Total Payable Amount
                      </Td>
                      <Td className="ro-in-content-middle-t-2">
                        ₹{" "}
                        {(
                          total_payable_payment + total_charges
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                          useGrouping: true,
                        })}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-med-12 invoice-part-three">
          <div className="col-md-4 invoice-part-three-1">
            <span>Signature of Customer</span>
          </div>
          <div className="col-md-4 invoice-part-three-2">
            <span>Thank You</span>
          </div>
          <div className="col-md-4 invoice-part-three-3">
            <span>Signature of Receiver</span>
          </div>
        </div>
      </div>
      {isChecked ? <div className="page-break" /> : ""}
    </div>
  );
};

export default ReturnOrderInvoice;
