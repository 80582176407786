import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASEURL } from "../../constant/constant";
import PaymentContentTop from "./PaymentTop";
import PaymentMiddle from "./PaymentMiddle";
import { AlertType, alertService } from "../../utils/alert.service";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { DeletePopup } from "../Popup/Popup";
import { RedCrossIcon } from "../../assets";

const PaymentContent = ({ state }) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [size, setSize] = useState(10);
  const [startDate, setStartDate] = useState(new Date());
  const [customer, setCustomer] = useState("");
  const [customerPayDetails, setCustomerPayDetails] = useState("");
  const [c_id, setC_id] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState({
    pay_amt: "",
    dis_amt: "",
    depo_amt: "",
    return_depo_amt: "",
  });
  const [customer_payment_list, setCustomer_payment_list] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [delItem, setDelItem] = useState("");
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();

  const CustomerList = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    try {
      const response = await axios({
        url: BASEURL + "customer/drop_down",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
      });
      setCustomer(response.data);
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  useEffect(() => {
    CustomerList();
  }, []);

  const CustomerPaymentDetails = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    try {
      const response = await axios({
        url: BASEURL + "payment/pending_by_customer",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: {
          customer_id: state == null ? c_id : state.customer_id,
        },
      });
      setCustomerPayDetails(response.data);
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  useEffect(() => {
    CustomerPaymentDetails();
  }, [c_id]);

  const CustomerPayment = async () => {
    axios({
      method: "post",
      maxBodyLength: Infinity,
      url: BASEURL + "payment/pay",
      headers: {
        Authorization: sessionStorage.getItem("auth_token"),
        "Content-Type": "application/json",
      },
      data: {
        customer_id: state == null ? c_id : state.customer_id,
        payment_date: moment(startDate, "DD-MM-YYYY"),
        paid_amount: parseFloat(paymentAmount.pay_amt),
        discount_amount:
          paymentAmount.dis_amt == "" ? 0 : paymentAmount.dis_amt,
        deposit_amount:
          paymentAmount.depo_amt == "" ? 0 : paymentAmount.depo_amt,
        return_deposit_amount:
          paymentAmount.return_depo_amt == ""
            ? 0
            : paymentAmount.return_depo_amt,
      },
    })
      .then(async function (response) {
        if (response.status === 200) {
          alertService.alert({
            type: AlertType.Success,
            message: response.data.message,
          });
          {
            state == null ? window.location.reload(true) : navigate(-1);
          }
        }
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        } else if (error.response.status === 409) {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        } else {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        }
      });
  };

  const CustomerPaymentList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const response = await axios({
      url: BASEURL + "payment/list",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        customer_id: state == null ? c_id : state.customer_id,
        page: selectedPage?.selected ?? 1,
        size: size,
        sort_by: sortData,
        order_by: orderBy,
      },
    });
    setCustomer_payment_list(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    CustomerPaymentList(true);
  }, [c_id, selectedPage, size, sortData, orderBy]);

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const onDelete = (delValue) => {
    setDelItem(delValue);
    toggleDeletePopup();
  };

  const deletePaymentDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const deleteItem = {
      payment_id: delItem.payment_id,
      customer_id: delItem.customer_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "payment/delete",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: deleteItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleDeletePopup();
        CustomerPaymentList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const deleteSubmitHandler = () => {
    deletePaymentDetail();
    toggleDeletePopup();
  };

  const onShortData = (sortItem) => {
    if (sortItem !== sortData) {
      setSortData(sortItem);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  return (
    <div className="content-scroll">
      <div class="content-card card">
        <PaymentContentTop
          customer={customer}
          setCustomer={setCustomer}
          c_id={c_id}
          setC_id={setC_id}
          paymentAmount={paymentAmount}
          setPaymentAmount={setPaymentAmount}
          startDate={startDate}
          setStartDate={setStartDate}
          CustomerPayment={CustomerPayment}
          customerPayDetails={customerPayDetails}
          state={state}
          searchInput={searchInput}
          handleChangeSearch={handleChangeSearch}
        />
      </div>
      <div class="content-card card">
        <PaymentMiddle
          customer_payment_list={customer_payment_list}
          isLoading={isLoading}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          setSize={setSize}
          onShortData={onShortData}
          onDelete={onDelete}
        />
      </div>
      {isDeleteOpen && (
        <DeletePopup
          deleteContent={
            <div className="del-pop">
              <div className="del-pop-main">
                <img className="del-pop-icon" src={RedCrossIcon} />
              </div>
              <div className="del-pop-text-div">
                <span className="del-pop-text">
                  Are you sure want to delete payment : <br />
                  {delItem.customer_name}?
                </span>
              </div>
              <div className="del-pop-btn">
                <button
                  className="del-pop-btn-yes"
                  onClick={deleteSubmitHandler}
                >
                  Yes
                </button>
                <button className="del-pop-btn-no" onClick={toggleDeletePopup}>
                  No
                </button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default PaymentContent;
