import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASEURL } from "../../constant/constant";
import { AlertType, alertService } from "../../utils/alert.service";
import AcInputText from "../AcInputText/AcInputText";
import AddressInputText from "../AcInputText/AddressInputText";
import PaymentLimitInput from "../AcInputText/PaymentLimitInput";
import ToggleLoading from "../LoadingSpinner/ToggleLoading";
import { AddUpdateCustomer, DeletePopup } from "./../Popup/Popup";
import ContentMiddle from "./ContentMiddle";
import ContentTop from "./ContentTop";
import { RedCrossIcon } from "../../assets";

const Content = () => {
  const [data, setData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [size, setSize] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const initialValues = {
    name: "",
    mobile: "",
    whatsapp: "",
    email: "",
    city: "",
    address: "",
    refName: "",
    refMobile: "",
    paymentLimit: "",
    timeLimit: "",
  };
  const [customerData, setCustomerData] = useState(initialValues);
  const [siteList, setSiteList] = useState([{ siteName: "", siteAddress: "" }]);
  const [documentList, setDocumentList] = useState([
    { docName: "", docImage: "" },
  ]);
  const [errors, setErrors] = useState(initialValues);
  const [item, setItem] = useState(initialValues);
  const [delItem, setDelItem] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState("yes");
  const [timeLimitDrop, setTimeLimitDrop] = useState("Day");

  useEffect(() => {
    console.log("size >> ", selectedPage, size);
  }, [selectedPage, size]);

  const CustomerList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const response = await axios({
      url: BASEURL + "customer/list",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        size: size,
        page: selectedPage?.selected ?? 1,
        sort_by: sortData,
        order_by: orderBy,
        search_keyword: searchInput,
      },
    });
    setData(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    CustomerList(true);
  }, [selectedPage, size, orderBy, sortData, searchInput]);

  const updateStatus = async (value) => {
    setToggleLoading(true);
    axios({
      method: "post",
      maxBodyLength: Infinity,
      url: BASEURL + "customer/update_status",
      headers: {
        Authorization: sessionStorage.getItem("auth_token"),
        "Content-Type": "application/json",
      },
      data: value,
    })
      .then(async function (response) {
        await CustomerList();
        setToggleLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        } else if (error.response.status === 409) {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        } else {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        }
      })
      .finally(() => setToggleLoading(false));
  };

  const updateCustomerDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const updateItem = {
      phone: item.customer_phone,
      whatsapp: item.customer_whatsapp,
      email: item.customer_email,
      city: item.customer_city,
      address: item.customer_address,
      ref_name: item.ref_name,
      ref_phone: item.ref_phone,
      customer_id: item.customer_id,
      unit: selected === "yes" ? "Rupees" : timeLimitDrop,
      amount:
        selected === "yes"
          ? customerData?.paymentLimit
          : customerData?.timeLimit,
    };
    try {
      const response = await axios({
        url: BASEURL + "customer/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: updateItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleUpdatePopup();
        CustomerList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const deleteCustomerDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const deleteItem = {
      customer_id: delItem.customer_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "customer/delete",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: deleteItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleDeletePopup();
        CustomerList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...siteList];
    list[index][name] = value;
    setSiteList(list);
  };

  const handleDocumentChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...documentList];
    list[index][name] = value;
    setDocumentList(list);
  };

  const handleDocumentImageChange = (e, index) => {
    const { name, files } = e.target;
    const list = [...documentList];
    const selectedImageArray = Array.from(files);
    const previews = selectedImageArray.map((file) =>
      URL.createObjectURL(file)
    );
    list[index][name] = [...selectedImageArray, ...previews];
    setDocumentList(list);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const onChangeToggle = (vale) => {
    updateStatus(vale);
  };

  const onEdit = (value) => {
    setItem(value);
    toggleUpdatePopup();
    setErrors("");
  };

  const toggleUpdatePopup = () => {
    setIsUpdateOpen(!isUpdateOpen);
  };

  const updateSubmitHandler = (e) => {
    e.preventDefault();
    updateCustomerDetail();
    setIsUpdateOpen(!isUpdateOpen);
  };

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const onDelete = (delValue) => {
    setDelItem(delValue);
    toggleDeletePopup();
  };

  const deleteSubmitHandler = () => {
    deleteCustomerDetail();
    toggleDeletePopup();
  };

  const onShortData = (sortItem) => {
    if (sortItem !== sortData) {
      setSortData(sortItem);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev === "ASC" ? "DESC" : "ASC"));
    }
  };

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const handlePaymentChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <div className="content-scroll">
      {toggleLoading ? <ToggleLoading /> : null}
      <div class="content-card card">
        <ContentTop
          setSize={(value) => {
            setSize(value);
            setSelectedPage(1);
          }}
          getCustomerList={CustomerList}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          siteList={siteList}
          setSiteList={setSiteList}
          initialValues={initialValues}
          customerData={customerData}
          setCustomerData={setCustomerData}
          errors={errors}
          setErrors={setErrors}
          handleInputChange={handleInputChange}
          data={data}
          handleChangeSearch={handleChangeSearch}
          searchInput={searchInput}
          timeLimitDrop={timeLimitDrop}
          setTimeLimitDrop={setTimeLimitDrop}
          documentList={documentList}
          setDocumentList={setDocumentList}
          handleDocumentChange={handleDocumentChange}
          handleDocumentImageChange={handleDocumentImageChange}
        />
        <div>
          <ContentMiddle
            data={data}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            onChangeToggle={onChangeToggle}
            togglePopup={togglePopup}
            customerData={customerData}
            initialValues={initialValues}
            setCustomerData={setCustomerData}
            errors={errors}
            setErrors={setErrors}
            onEdit={onEdit}
            onDelete={onDelete}
            isLoading={isLoading}
            toggleLoading={toggleLoading}
            onShortData={onShortData}
          />
        </div>
      </div>
      {isUpdateOpen && (
        <AddUpdateCustomer
          updateContent={
            <form>
              <div className="addCustomer-first">
                <span className="addCustomer-title">
                  UPDATE CUSTOMER DETAILS
                </span>
                <span className="addCustomer-desc">All field are required</span>
              </div>
              <div className="addCustomer-second">
                <span className="addCustomer-second-title">
                  Personal Details
                  <div className="addCustomer-second-hr">
                    <hr />
                  </div>
                </span>
                <div className="addCustomer-second-firstRow">
                  <AcInputText
                    label="Name"
                    type="text"
                    disabled
                    placeholder="Name"
                    value={item.customer_full_name}
                  />
                  <AcInputText
                    label="Mobile"
                    maxLength={"10"}
                    placeholder="Mobile"
                    value={item.customer_phone}
                    onChange={(e) =>
                      e.target.value.length <= 10 &&
                      setItem((prevValue) => ({
                        ...prevValue,
                        customer_phone: e.target.value.replace(/\D/g, ""),
                      }))
                    }
                  />
                  <AcInputText
                    label="WhatsApp Mobile"
                    placeholder="Alternet Mobile"
                    value={item.customer_whatsapp}
                    onChange={(e) =>
                      setItem((prevValue) => ({
                        ...prevValue,
                        customer_whatsapp: e.target.value.replace(/\D/g, ""),
                      }))
                    }
                  />
                </div>
                <div className="addCustomer-second-firstRow">
                  <AcInputText
                    style={{ textTransform: "lowercase" }}
                    label="E-mail"
                    type="text"
                    placeholder="E-mail"
                    value={item.customer_email}
                    onChange={(e) =>
                      setItem((prevValue) => ({
                        ...prevValue,
                        customer_email: e.target.value,
                      }))
                    }
                  />
                  <AcInputText
                    label="City"
                    type="text"
                    placeholder="City"
                    value={item.customer_city}
                    onChange={(e) =>
                      setItem((prevValue) => ({
                        ...prevValue,
                        customer_city: e.target.value,
                      }))
                    }
                  />
                  <AddressInputText
                    label="Address"
                    type="text"
                    placeholder="address"
                    value={item.customer_address}
                    onChange={(e) =>
                      setItem((prevValue) => ({
                        ...prevValue,
                        customer_address: e.target.value,
                      }))
                    }
                  />
                </div>
                <span className="addCustomer-second-title">
                  Refrence Details
                  <div className="addCustomer-second-hr">
                    <hr />
                  </div>
                </span>
                <div className="addCustomer-second-firstRow">
                  <AcInputText
                    label="Name"
                    type="text"
                    placeholder="Name"
                    value={item.ref_name}
                    onChange={(e) =>
                      setItem((prevValue) => ({
                        ...prevValue,
                        ref_name: e.target.value,
                      }))
                    }
                  />
                  <AcInputText
                    label="Mobile"
                    maxLength="10"
                    placeholder="Mobile"
                    value={item.ref_phone}
                    onChange={(e) =>
                      setItem((prevValue) => ({
                        ...prevValue,
                        ref_phone: e.target.value.replace(/\D/g, ""),
                      }))
                    }
                  />
                </div>
                <span className="addCustomer-second-title">
                  Payment Limit
                  <div className="addCustomer-second-hr">
                    <hr />
                  </div>
                </span>
                <div
                  className="addCustomer-second-firstRow"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "-10px",
                    marginBottom: "10px",
                  }}
                >
                  <div className="payment-limit-div">
                    <label for="s-option" class="l-radio">
                      <input
                        type="radio"
                        name="selector"
                        tabindex="2"
                        value="yes"
                        checked={selected === "yes"}
                        onChange={handlePaymentChange}
                      />
                      <span className="payment-limit-text">Payment Limit</span>
                    </label>
                    <label for="f-option" className="l-radio">
                      <input
                        type="radio"
                        name="selector"
                        tabindex="1"
                        value="no"
                        checked={selected === "no"}
                        onChange={handlePaymentChange}
                      />
                      <span className="payment-limit-text">Time Limit</span>
                    </label>
                  </div>
                  {selected === "yes" ? (
                    <AcInputText
                      placeholder="Payment"
                      value={customerData.paymentLimit}
                      onChange={(e) =>
                        setCustomerData((prevValue) => ({
                          ...prevValue,
                          paymentLimit: e.target.value.replace(/\D/g, ""),
                        }))
                      }
                    />
                  ) : (
                    <div>
                      <PaymentLimitInput
                        placeholder="Time"
                        value={customerData.timeLimit}
                        onChange={(e) =>
                          setCustomerData((prevValue) => ({
                            ...prevValue,
                            timeLimit: e.target.value.replace(/\D/g, ""),
                          }))
                        }
                      />
                      <div className="payment-limit-dropdown">
                        <select
                          className="payment-limit-dropdown-select"
                          onChange={(e) => setTimeLimitDrop(e.target.value)}
                        >
                          {["Day", "Month", "Year"].map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="addCustomer-second-bottam-btn">
                <button
                  onClick={updateSubmitHandler}
                  className="addCustomer-second-bottam-btn-sub"
                >
                  Submit
                </button>
                <button
                  className="addCustomer-second-bottam-btn-can"
                  onClick={toggleUpdatePopup}
                >
                  Cancel
                </button>
              </div>
            </form>
          }
        />
      )}
      {isDeleteOpen && (
        <DeletePopup
          deleteContent={
            <div className="del-pop">
              <div className="del-pop-main">
                <img className="del-pop-icon" src={RedCrossIcon} />
              </div>
              <div className="del-pop-text-div">
                <span className="del-pop-text">
                  Are you sure want to delete customer : <br />
                  {delItem.customer_full_name}?
                </span>
              </div>
              <div className="del-pop-btn">
                <button
                  className="del-pop-btn-yes"
                  onClick={deleteSubmitHandler}
                >
                  Yes
                </button>
                <button className="del-pop-btn-no" onClick={toggleDeletePopup}>
                  No
                </button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default Content;
