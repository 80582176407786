import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import ReactToPrint from "react-to-print";
import "react-toastify/dist/ReactToastify.css";
import { BASEURL } from "../../constant/constant";
import { AlertType, alertService } from "../../utils/alert.service";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import LotiFiles from "../Lotifiles/LotiFiles";
import PlaceOrderInvoice from "../PlaceOrderInvoice";
import { OrderSummery } from "../Popup/Popup";
import { AppColors } from "../colors";
import POContentMiddle from "./POContentMiddle";
import POContentTop from "./POContentTop";

const POContent = () => {
  const { state } = useLocation();

  const [selectedPage, setSelectedPage] = useState(1);
  const [size, setSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [siteList, setSiteList] = useState();
  const [varData, setVarData] = useState();
  const [placeOrder, setPlaceOrder] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [arr, setArr] = useState([]);
  const [isChecked, setIsChecked] = useState("");
  const [s_id, setS_id] = useState(state.site_id);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [summeryOpen, setSummeryOpen] = useState(false);
  const [summery, setSummery] = useState("");
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [poData, setPodata] = useState({
    location: "",
    vRent: "",
    lRent: "",
    payAmount: "",
    deposite: "",
    cNumber: "",
  });

  var date = new Date();
  date.setDate(date.getDate() + 14);

  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(date);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [placeArr, setPlaceArr] = useState([]);

  const SiteList = async () => {
    setIsLoading(true);
    const auth_token = sessionStorage.getItem("auth_token");
    const item = {
      customer_id: state.customer_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "customer/site/drop_down",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      setSiteList(response.data);
      setIsLoading(false);
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  useEffect(() => {
    SiteList();
  }, []);

  const varList = async () => {
    setIsLoading(true);
    const auth_token = sessionStorage.getItem("auth_token");

    const response = await axios({
      url: BASEURL + "product/variant/List_active",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
    });
    setVarData(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    varList();
  }, []);

  const placeOrderDetail = async () => {
    setIsLoading(true);
    const auth_token = sessionStorage.getItem("auth_token");

    const item = {
      order_date: moment(selectedFromDate),
      expected_return_date: moment(selectedToDate),
      order_deposit: parseInt(poData.deposite),
      order_location: poData.location,
      labour_charge: parseInt(poData.lRent),
      vehicle_charge: parseInt(poData.vRent),
      chalan_no: poData.cNumber == "" ? undefined : parseInt(poData.cNumber),
      site_id: s_id,
      items: orderData
        .filter(
          (filterItem) =>
            filterItem.quantity.trim() !== "" &&
            parseInt(filterItem.quantity) !== 0
        )
        .map((b) => ({
          product_variants_id: b.product_variants_id,
          quantity: parseInt(b.quantity),
          rent: parseInt(b.fix_rent),
        })),
    };
    try {
      const response = await axios({
        url: BASEURL + "order/place",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        setIsLoading(false);
        setOrderData([""]);
        setArr([""]);
        setPodata({
          fDate: "",
          tDate: "",
          location: "",
          vRent: "",
          lRent: "",
          payAmount: "",
          deposite: "",
          cNumber: "",
        });
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };
  const placeOrderList = async () => {
    setIsLoading(true);
    const auth_token = sessionStorage.getItem("auth_token");
    const item = {
      site_id: s_id,
      size: size,
      page: selectedPage?.selected ?? 1,
      sort_by: sortData,
      order_by: orderBy,
    };

    const response = await axios({
      url: BASEURL + "order/list_by_site",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setPlaceOrder(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    placeOrderList();
  }, [s_id, selectedPage, size, sortData, orderBy]);

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const toggleSummeryPopup = () => {
    setSummeryOpen(!summeryOpen);
    setOpenInvoice(!openInvoice);
  };

  const onSummery = (item) => {
    setSummery(item);
    toggleSummeryPopup();
  };

  const onShortData = (sortItem) => {
    if (sortItem !== sortData) {
      setSortData(sortItem);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  let total_rent = 0;

  for (let i = 0; i < summery?.order_details?.length; i++) {
    total_rent += summery?.order_details[i]?.rent_fifteen_days;
  }

  const componentRef = useRef();

  const printDocument = () => {
    window.print();
  };

  const handlePlaceCheckbox = (e, mapItem) => {
    const { value, checked } = e.target;
    if (checked == true) {
      setPlaceArr((prevValue) => [...prevValue, mapItem]);
    } else {
      setPlaceArr(placeArr.filter((item) => item !== mapItem));
    }
    setIsChecked(checked);
  };

  return (
    <div className="content-scroll">
      <div
        class="content-card card"
        style={{
          overflow: "auto",
        }}
      >
        <POContentTop
          siteList={siteList}
          varData={varData}
          poData={poData}
          setPoData={setPodata}
          orderData={orderData}
          setOrderData={setOrderData}
          state={state}
          placeOrderDetail={placeOrderDetail}
          arr={arr}
          setArr={setArr}
          setS_id={setS_id}
          s_id={s_id}
          placeOrderList={placeOrderList}
          selectedFromDate={selectedFromDate}
          setSelectedFromDate={setSelectedFromDate}
          selectedToDate={selectedToDate}
          setSelectedToDate={setSelectedToDate}
          setIsChecked={setIsChecked}
        />
      </div>
      <div class="cl-content-card card">
        <POContentMiddle
          setSize={(value) => {
            setSize(value);
            setSelectedPage(1);
          }}
          placeOrder={placeOrder}
          isLoading={isLoading}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          onSummery={onSummery}
          onShortData={onShortData}
          handlePlaceCheckbox={handlePlaceCheckbox}
        />
      </div>
      {summeryOpen && (
        <OrderSummery
          summeryContent={
            <div>
              <div>
                <span className="cl-summary-title">Order Summary</span>
                <div className="cl-summary-hr" />
                {isLoading ? (
                  <TableLoader />
                ) : (
                  <div>
                    {summery ? (
                      <div className="content-middle">
                        <Table className="content-middle-main">
                          <Thead>
                            <Tr>
                              <Th className="cl-content-middle-title-tr">
                                Chalan No.
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Site Name
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Order Date
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Location
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                For Days
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Vehicle charge
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Labour charge
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Total charges
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr>
                              <Td className="cl-content-middle-name-data">
                                {summery?.chalan_no !== null
                                  ? summery.chalan_no
                                  : "-"}
                              </Td>
                              <Td className="cl-content-middle-email-data">
                                {summery.site_name}
                              </Td>
                              <Td className="cl-content-middle-mobile-data">
                                {summery?.order_date?.trim() !== ""
                                  ? moment(summery.order_date).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </Td>
                              <Td className="cl-content-middle-active-data">
                                {summery?.order_location?.trim() !== ""
                                  ? summery.order_location
                                  : "-"}
                              </Td>
                              <Td className="cl-content-middle-date-data">
                                15 DAYS
                              </Td>
                              <Td className="cl-content-middle-city-data">
                                {summery.vehicle_charge}
                              </Td>
                              <Td className="cl-content-middle-address-data">
                                {summery.labour_charge}
                              </Td>
                              <Td className="cl-content-middle-refName-data">
                                {summery.vehicle_charge + summery.labour_charge}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </div>
                    ) : (
                      <LotiFiles message={"No Data Found For Order!"} />
                    )}
                  </div>
                )}
              </div>
              <div className="cl-summary-hr" />
              <div>
                {isLoading ? (
                  <TableLoader />
                ) : (
                  <div>
                    {summery.order_details.length > 0 ? (
                      <div className="content-middle">
                        <Table className="content-middle-main">
                          <Thead>
                            <Tr>
                              <Th className="cl-content-middle-title-tr cl-summary-middle-no">
                                No
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Item Name
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Order Date
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Order Qty
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Unit Rent
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Rent (Per Day Rs.)
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Rent (Per 15 Days)
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {summery?.order_details &&
                            summery.order_details.length > 0
                              ? summery.order_details.map((item, index) => {
                                  return (
                                    <Tr>
                                      <Td className="cl-summary-middle-no-data">
                                        {index + 1}
                                      </Td>
                                      <Td className="cl-summary-middle-product-data">
                                        {item.item_name}
                                      </Td>
                                      <Td className="cl-summary-middle-measure-data">
                                        {summery?.order_date?.trim() !== ""
                                          ? moment(summery.order_date).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "-"}
                                      </Td>
                                      <Td className="cl-summary-middle-quantity-data">
                                        {item.quantity}
                                      </Td>
                                      <Td className="cl-summary-middle-rent3-data">
                                        {item.rent}
                                      </Td>
                                      <Td className="cl-summary-middle-rent3-data">
                                        {item.rent_per_day}
                                      </Td>
                                      <Td className="cl-summary-middle-total-data">
                                        {item?.rent_fifteen_days}
                                      </Td>
                                    </Tr>
                                  );
                                })
                              : null}
                          </Tbody>
                          <Tbody>
                            <Tr style={{ background: AppColors.lightGreen }}>
                              <Td
                                style={{
                                  textAlign: "right",
                                }}
                                className="cl-summary-middle-rent1-data"
                                colspan="6"
                              >
                                Total Rent
                              </Td>
                              <Td className="cl-summary-middle-rent1-data">
                                ₹ {total_rent}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td className="ro-content-middle-v-1" colspan="6">
                                Vehicle Rent
                              </Td>
                              <Td className="ro-content-middle-v-2">
                                ₹ {summery?.vehicle_charge}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td className="ro-content-middle-l-1" colspan="6">
                                Labour Rent
                              </Td>
                              <Td className="ro-content-middle-l-2">
                                ₹ {summery?.labour_charge}
                              </Td>
                            </Tr>
                            <Tr style={{ fontWeight: "bold" }}>
                              <Td className="ro-content-middle-t-1" colspan="6">
                                Total Payable Amount
                              </Td>
                              <Td className="ro-content-middle-t-2">
                                ₹{" "}
                                {total_rent +
                                  summery?.labour_charge +
                                  summery?.vehicle_charge}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </div>
                    ) : (
                      <LotiFiles message={"No Data Found For Orders!"} />
                    )}
                  </div>
                )}
                <div className="cl-summary-btn-div">
                  <ReactToPrint
                    trigger={() => (
                      <button
                        className="cl-summary-btn"
                        style={{ backgroundColor: AppColors.red }}
                        onClick={printDocument}
                      >
                        Print
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                  <button
                    style={{ marginLeft: "10px" }}
                    className="cl-summary-btn"
                    onClick={toggleSummeryPopup}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}
      {openInvoice ? (
        <div ref={componentRef}>
          {placeArr == "" ? (
            <PlaceOrderInvoice
              customer_phone={state.customer_phone}
              customer_name={state.customer_full_name}
              summeryItem={summery}
              isChecked={isChecked}
            />
          ) : (
            <div>
              {placeArr.map((summeryItem) => (
                <PlaceOrderInvoice
                  customer_phone={state.customer_phone}
                  customer_name={state.customer_full_name}
                  summeryItem={summeryItem}
                  isChecked={isChecked}
                />
              ))}
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default POContent;
