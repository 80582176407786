import axios from "axios";
import React, { useState, useEffect } from "react";
import { BASEURL } from "../../constant/constant";
import AcInputText from "../AcInputText/AcInputText";
import ToggleLoading from "../LoadingSpinner/ToggleLoading";
import { AddCategory, DeletePopup } from "../Popup/Popup";
import MeasureMiddle from "./MeasureMiddle";
import MeasureTop from "./MeasureTop";
import { alertService, AlertType } from "../../utils/alert.service";
import { RedCrossIcon } from "../../assets";

const MeasureContent = () => {
  const [size, setSize] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [mInch, setMInch] = useState("");
  const [mesData, setMesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [measurement, setMeasurement] = useState();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [delItem, setDelItem] = useState("");
  const [errors, setErrors] = useState("");
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const mesList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const response = await axios({
      url: BASEURL + "product/measurement/list",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        size: size,
        page: selectedPage?.selected ?? 1,
        sort_by: sortData,
        order_by: orderBy,
        search_keyword: searchInput,
      },
    });
    setMesData(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    mesList(true);
  }, [selectedPage, size, orderBy, sortData, searchInput]);

  const updateStatus = async (value) => {
    setToggleLoading(true);
    axios({
      method: "post",
      maxBodyLength: Infinity,
      url: BASEURL + "product/measurement/update_status",
      headers: {
        Authorization: sessionStorage.getItem("auth_token"),
        "Content-Type": "application/json",
      },
      data: value,
    })
      .then(async function (response) {
        await mesList();
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        } else if (error.response.status === 409) {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        } else {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        }
      })
      .finally(() => setToggleLoading(false));
  };

  const updateMeasureDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const updateProduct = {
      name: measurement.measurement_name,
      measurement_id: measurement.measurement_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "product/measurement/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: updateProduct,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleUpdatePopup();
        mesList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const deleteProductDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const deleteItem = {
      measurement_id: delItem.measurement_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "product/measurement/delete",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: deleteItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleDeletePopup();
        mesList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const toggleAddCatPopup = () => {
    setIsOpen(!isOpen);
    setMInch("");
    setErrors("");
  };

  const onChangeToggle = (vale) => {
    updateStatus(vale);
  };

  const toggleUpdatePopup = () => {
    setUpdateOpen(!updateOpen);
  };

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const onEdit = (value) => {
    setMeasurement(value);
    toggleUpdatePopup();
  };

  const onDelete = (delValue) => {
    setDelItem(delValue);
    toggleDeletePopup();
  };

  const submitMeasureHandler = (e) => {
    e.preventDefault();
    updateMeasureDetail();
  };

  const deleteSubmitHandler = () => {
    deleteProductDetail();
  };

  const onShortData = (sortItem) => {
    if (sortItem !== sortData) {
      setSortData(sortItem);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  return (
    <div className="cl-content-scroll">
      {toggleLoading ? <ToggleLoading /> : null}
      <div class="cl-content-card card">
        <MeasureTop
          setSize={(value) => {
            setSize(value);
            setSelectedPage(1);
          }}
          toggleAddCatPopup={toggleAddCatPopup}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          mInch={mInch}
          setMInch={setMInch}
          mesList={mesList}
          errors={errors}
          setErrors={setErrors}
          mesData={mesData}
          handleChangeSearch={handleChangeSearch}
          searchInput={searchInput}
        />
        <MeasureMiddle
          mesData={mesData}
          mInch={mInch}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          onChangeToggle={onChangeToggle}
          isLoading={isLoading}
          toggleLoading={toggleLoading}
          onEdit={onEdit}
          onDelete={onDelete}
          onShortData={onShortData}
        />
      </div>
      {updateOpen && (
        <AddCategory
          content={
            <form>
              <div className="cat-first">
                <span className="cat-title">UPDATE MEASUREMENT DETAILS</span>
                <span className="cat-desc">All field are required</span>
              </div>
              <div className="cat-second">
                <span className="cat-second-title">
                  Personal Details
                  <div className="cat-second-hr">
                    <hr />
                  </div>
                </span>
                <div className="cat-second-firstRow">
                  <AcInputText
                    label="Inch"
                    type="text"
                    placeholder="inch"
                    value={measurement.measurement_name}
                    onChange={(e) =>
                      setMeasurement((prevValue) => ({
                        ...prevValue,
                        measurement_name: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="cat-second-bottam-btn">
                <button
                  className="cat-second-bottam-btn-sub"
                  onClick={submitMeasureHandler}
                >
                  Submit
                </button>
                <button
                  className="cat-second-bottam-btn-can"
                  onClick={toggleUpdatePopup}
                >
                  Cancel
                </button>
              </div>
            </form>
          }
        />
      )}
      {isDeleteOpen && (
        <DeletePopup
          deleteContent={
            <div className="del-pop">
              <div className="del-pop-main">
                <img className="del-pop-icon" src={RedCrossIcon} />
              </div>
              <div className="del-pop-text-div">
                <span className="del-pop-text">
                  Are you sure want to delete measurement : <br />
                  {delItem.measurement_name}?
                </span>
              </div>
              <div className="del-pop-btn">
                <button
                  className="del-pop-btn-yes"
                  onClick={deleteSubmitHandler}
                >
                  Yes
                </button>
                <button className="del-pop-btn-no" onClick={toggleDeletePopup}>
                  No
                </button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default MeasureContent;
