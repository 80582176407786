import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AccordionDeleteIcon,
  DownArrowIcon,
  RightArrowIcon,
} from "../../assets";
import { BASEURL } from "../../constant/constant";
import { AlertType, alertService } from "../../utils/alert.service";
import ToggleLoading from "../LoadingSpinner/ToggleLoading";

const Accordion = ({
  content,
  siteName,
  onEdit,
  is_active,
  site_id,
  state,
  item,
  setP_s_id,
  p_s_id,
  onDelete,
  toggleLoading,
  setToggleLoading,
  accordionData,
  customer_name,
  customer_phone,
  setPlaceArr,
  show_site_payment,
}) => {
  const navigate = useNavigate();
  const [varData, setVarData] = useState("");
  const [accordionOpen, setAccordionOpen] = useState(false);
  const bActive = is_active;

  const updateStatus = async (is_active, site_id) => {
    const updateStatus = {
      site_id: site_id,
      status: !is_active,
    };

    axios({
      method: "post",
      maxBodyLength: Infinity,
      url: BASEURL + "customer/site/update_status",
      headers: {
        Authorization: sessionStorage.getItem("auth_token"),
        "Content-Type": "application/json",
      },
      data: updateStatus,
    })
      .then(async function (response) {
        if (response.status === 200) {
          alertService.alert({
            type: AlertType.Success,
            message: response.data.message,
          });
          window.location.reload(true);
        }
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        } else if (error.response.status === 409) {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        } else {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        }
      })
      .finally(() => setToggleLoading(false));
  };

  const varList = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const response = await axios({
      url: BASEURL + "product/variant/List_active",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
    });
    setVarData(response.data);
  };

  useEffect(() => {
    if (p_s_id?.site_id == item?.site_id && is_active) {
      varList();
    }
  }, [p_s_id, is_active]);

  const letsToast = () => {
    alertService.alert({
      type: AlertType.Error,
      message: "There are no variants.ㅤㅤㅤㅤPlease add Variant first!",
    });
  };

  const placeOrderHandler = () => {
    varData.list.length > 0
      ? navigate("/customer/details/place-order", {
          state: {
            customer_id: state.customer_id,
            customer_full_name: state.customer_full_name,
            customer_phone: state.customer_phone,
            site_id: site_id,
            siteName: siteName,
          },
        })
      : letsToast();
  };

  const returnOrderHandler = () => {
    accordionData?.length > 0
      ? navigate("/customer/details/return-order", {
          state: {
            customer_id: state.customer_id,
            customer_name: state.customer_full_name,
            customer_phone: customer_phone,
            site_id: site_id,
            siteName: siteName,
          },
        })
      : alertService.alert({
          type: AlertType.Error,
          message: "Please add Site first!",
        });
  };

  const onCLickAccordion = () => {
    setP_s_id((prevState) => (prevState == item ? "" : item));
  };

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
    setPlaceArr("");
  };

  return (
    <div>
      {toggleLoading ? (
        <ToggleLoading />
      ) : (
        <div className="cl-accordion-item">
          <div className="accordion-title" onClick={onCLickAccordion}>
            {p_s_id?.site_id == item?.site_id ? (
              <img
                className="cl-down-arrow"
                src={DownArrowIcon}
                onClick={toggleAccordion}
              />
            ) : (
              <img
                className="cl-right-arrow"
                src={RightArrowIcon}
                onClick={toggleAccordion}
              />
            )}
            <div className="accordion-title-txt">
              {siteName} &nbsp;{" "}
              {p_s_id?.site_id == item?.site_id ? (
                <span className="accordion-title-txt-desc">
                  [{show_site_payment?.gross_payable_amount}]
                </span>
              ) : null}
            </div>
          </div>
          <div>
            {p_s_id?.site_id == item?.site_id ? (
              <div className="cl-accordion-btn">
                {bActive == true ? (
                  <div>
                    <button
                      className="cl-content-accordion-right-btn-active "
                      onClick={() => updateStatus(is_active, site_id)}
                    >
                      Active
                    </button>
                    <button
                      className="cl-content-accordion-right-btn cl-accordion-btn-two"
                      onClick={placeOrderHandler}
                    >
                      + Place Order
                    </button>
                    <button
                      className="cl-content-accordion-right-btn cl-accordion-btn-two"
                      onClick={returnOrderHandler}
                    >
                      + Return Order
                    </button>
                  </div>
                ) : (
                  <button
                    className="cl-content-accordion-right-btn-inActive "
                    onClick={() => updateStatus(is_active, site_id)}
                  >
                    In-Active
                  </button>
                )}
                <button
                  className="cl-content-accordion-right-btn cl-accordion-btn-two"
                  onClick={() => onEdit(item)}
                >
                  + Edit Site
                </button>
                <button
                  className="cl-content-accordion-right-btn-del cl-accordion-btn-two"
                  onClick={() => onDelete(item)}
                >
                  <img
                    className="cl-content-accordion-right-btn-icon-del"
                    src={AccordionDeleteIcon}
                  />
                  Delete Site
                </button>
              </div>
            ) : null}
          </div>
          {p_s_id?.site_id == item?.site_id ? (
            <div className="accordion-content">{content}</div>
          ) : (
            p_s_id?.site_id != item?.site_id
          )}
        </div>
      )}
    </div>
  );
};

export default Accordion;
