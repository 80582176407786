import React from "react";

const ButtonTop = ({ onClick, name }) => {
  return (
    <div>
      <button className="content-top-right-btn" onClick={onClick}>
        +ㅤ{name}
      </button>
    </div>
  );
};

export default ButtonTop;
