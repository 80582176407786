import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ReactSwitch from "react-switch";
import { DeleteIcon, EditIcon } from "../../assets";
import SortIcon from "../AcInputText/SortIcon";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import LotiFiles from "../Lotifiles/LotiFiles";
import Pagination from "../Pagination/Pagination";

const CatContentMiddle = ({
  selectedPage,
  setSelectedPage,
  catData,
  onChangeToggle,
  isLoading,
  onEdit,
  onDelete,
  toggleLoading,
  onShortData,
}) => {
  const handleChange = (val) => {
    onChangeToggle(val);
  };

  return (
    <div>
      {isLoading ? (
        <TableLoader />
      ) : (
        <div>
          {catData?.list?.length > 0 ? (
            <div className="cat-content-middle">
              <Table className="content-middle-main">
                <Thead>
                  <Tr>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Name
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("product_name")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Is Active
                        </div>
                        <div>
                          <SortIcon onClick={() => onShortData("is_active")} />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {catData?.list && catData.list.length > 0
                    ? catData.list.map((item, index) => {
                        return (
                          <Tr key={index}>
                            <Td className="cat-content-middle-name-data">
                              {item.product_name}
                            </Td>
                            <Td className="cat-content-middle-active-data">
                              <ReactSwitch
                                height={25}
                                width={53}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                checked={item.is_active}
                                onChange={(val) =>
                                  handleChange({
                                    product_id: item.product_id,
                                    status: val,
                                  })
                                }
                              />
                            </Td>
                            <Td className="cat-content-middle-action-data">
                              <div className="cat-content-middle-action-data-div">
                                <img
                                  src={EditIcon}
                                  onClick={() => onEdit(item)}
                                />
                                <img
                                  className="cat-content-middle-action-data-div-delete"
                                  src={DeleteIcon}
                                  onClick={() => onDelete(item)}
                                />
                              </div>
                            </Td>
                          </Tr>
                        );
                      })
                    : null}
                </Tbody>
              </Table>
            </div>
          ) : (
            <LotiFiles message={"No Data Found For Product!"} />
          )}
        </div>
      )}
      {catData?.list?.length > 0 ? (
        <div className="content-bottam">
          <Pagination
            selectedPage={selectedPage}
            totalCount={catData?.total_pages ?? 1}
            onPageChange={(page) => setSelectedPage(page)}
            itemsPerPage={4}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CatContentMiddle;
