import React from "react";
import { BlackSearchIcon } from "../../assets";

const SearchBar = ({ onChange, value }) => {
  return (
    <div className="content-top-right-searchbar">
      <div className="content-searchBar">
        <img className="content-searchBar-search" src={BlackSearchIcon} />
        <input
          className="content-searchBar-input"
          placeholder="Search..."
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};

export default SearchBar;
