import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ChalanIcon,
  CustomerIcon,
  DashBoardIcon,
  GraySearchIcon,
  InvoiceIcon,
  MeasurementIcon,
  PaymentIcon,
  ProductIcon,
  SahjanandLogoIcon,
  SizeIcon,
  StockIcon,
  TransactionIcon,
  WhiteCrossIcon,
} from "../assets";

const Sidebar = ({ className, isActive, setIsActive, classNameTwo }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentTab = "/" + pathname.split("/").at(1);

  const handleClick = (event) => {
    event.preventDefault();
    setIsActive((current) => !current);
  };

  const sidebarMenu_data = [
    {
      id: 1,
      name: "DASHBOARD",
      icon: DashBoardIcon,
      path: "/dashboard",
    },
    {
      id: 2,
      name: "CUSTOMER",
      icon: CustomerIcon,
      path: "/customer",
    },
    {
      id: 3,
      name: "PRODUCT",
      icon: ProductIcon,
      path: "/product",
    },
    {
      id: 4,
      name: "MEASUREMENT",
      icon: MeasurementIcon,
      path: "/measurement",
    },
    {
      id: 5,
      name: "VARIANT",
      icon: SizeIcon,
      path: "/variant",
    },
    {
      id: 6,
      name: "STOCK",
      icon: StockIcon,
      path: "/stock",
    },
    {
      id: 7,
      name: "PAYMENT",
      icon: PaymentIcon,
      path: "/payment",
    },
    {
      id: 7,
      name: "TRANSACTION",
      icon: TransactionIcon,
      path: "/transaction-year",
    },
    {
      id: 8,
      name: "EXPENSE / INCOME",
      icon: PaymentIcon,
      path: "/expenses",
    },
    {
      id: 9,
      name: "SEARCH",
      icon: GraySearchIcon,
      path: "/search",
    },
    {
      id: 10,
      name: "CHALAN",
      icon: ChalanIcon,
      path: "/chalan-book",
    },
    {
      id: 11,
      name: "INVOICE",
      icon: InvoiceIcon,
      path: "/invoice",
    },
  ];

  return (
    <div className={`${isActive ? classNameTwo : ""} ${className}`}>
      <div class="sidebar">
        {isActive ? (
          <img
            onClick={handleClick}
            className="close-icon"
            src={WhiteCrossIcon}
          />
        ) : (
          ""
        )}
        <div>
          <h3 className="sidebar-title">
            <img className="sidebar-logo" src={SahjanandLogoIcon} />
            <span className="sidebar-logo-name">SAHJANAND</span>
          </h3>
        </div>
        <div className="sidebar-tab">
          {sidebarMenu_data.map((item) => (
            <div
              className={`side-bar-navigate ${
                currentTab === item.path ? "active" : ""
              }`}
              onClick={() => {
                navigate(item.path);
              }}
            >
              <img className="dash" src={item.icon} />
              &nbsp; <span className="sb-text">{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
