import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASEURL } from "../../constant/constant";
import SearchContentTop from "./SearchContentTop";
import SearchMiddle from "./SearchMiddle";

const SearchContent = () => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [size, setSize] = useState();
  const [ispd_Id, setpd_IsId] = useState("");
  const [isv_Id, setv_IsId] = useState("");
  const [productDropDown, setProductDropDownData] = useState("");
  const [varientDropDown, setVarientDropDown] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchList, setSearchList] = useState("");
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const ProductDropDown = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const response = await axios({
      url: BASEURL + "product/drop_down",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
    });
    setProductDropDownData(response.data);
  };

  useEffect(() => {
    ProductDropDown();
  }, []);

  const VarientDropDown = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const vDropDown = {
      product_id: ispd_Id,
    };

    const response = await axios({
      url: BASEURL + "product/variant/dropdown_by_productid",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: vDropDown,
    });
    setVarientDropDown(response.data);
  };

  useEffect(() => {
    if (ispd_Id) {
      VarientDropDown();
    }
  }, [ispd_Id]);

  const SearchList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const response = await axios({
      url: BASEURL + "search/details",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        product_variants_id: isv_Id,
        // page: selectedPage?.selected ?? 1,
        // size: size,
        // sort_by: sortData,
        // order_by: orderBy,
      },
    });
    setSearchList(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    SearchList(true);
  }, [isv_Id]);

  const onShortData = (sortItem) => {
    if (sortItem !== sortData) {
      setSortData(sortItem);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  return (
    <div className="content-scroll">
      <div class="content-card card">
        <SearchContentTop
          ispd_Id={ispd_Id}
          setpd_IsId={setpd_IsId}
          isv_Id={isv_Id}
          setv_IsId={setv_IsId}
          productDropDown={productDropDown}
          varientDropDown={varientDropDown}
        />
      </div>
      <div class="content-card card">
        <SearchMiddle
          isLoading={isLoading}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          size={size}
          setSize={setSize}
          searchList={searchList}
          onShortData={onShortData}
        />
      </div>
    </div>
  );
};

export default SearchContent;
