import React, { useRef, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { AppColors } from "../colors";
import LotiFiles from "../Lotifiles/LotiFiles";
import { SahjanandLogoIcon } from "../../assets";

const InvoiceMiddle = ({ customerInDetails, isLoading, customerDetails }) => {
  const [openInvoice, setOpenInvoice] = useState(false);

  const componentRef = useRef();

  const printDocument = () => {
    setOpenInvoice(!openInvoice);
    window.print();
  };

  let total_rent = 0;

  for (let i = 0; i < customerInDetails?.list?.length; i++) {
    total_rent += customerInDetails?.list[i]?.total_amount;
  }

  return (
    <div>
      <div
        style={{ marginLeft: "20px", display: "flex", flexDirection: "row" }}
      >
        <span className="payment-top-left-title">INVOICE PREVIEW</span>
      </div>
      {customerInDetails?.list?.length > 0 ? (
        <div>
          <div
            className="content-top-right"
            style={{ float: "right", marginTop: "-30px" }}
          >
            <ReactToPrint
              trigger={() => (
                <button
                  className="cl-summary-btn"
                  style={{ backgroundColor: AppColors.red }}
                  onClick={printDocument}
                >
                  Print
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div ref={componentRef}>
            <div className="col-md-12 invoice-date-name">
              <div className="col-md-6">
                <span>{moment().format("DD-MM-YYYY, h:mm")}</span>
              </div>
              <div className="col-md-6">
                <span>Sahjanand Construction</span>
              </div>
            </div>
            <div className="invoice-main">
              <div className="col-md-12 invoice-part-first">
                <div className="col-md-4 invoice-part-first-left">
                  <span className="invoice-part-first-text">Gopal Patel</span>
                </div>
                <div className="col-md-4 invoice-part-first-middle">
                  <img
                    className="invoice-part-first-middle-logo"
                    src={SahjanandLogoIcon}
                  />
                  <span className="invoice-part-first-middle-name">
                    સહજાનંદ કન્ટ્રકશન
                  </span>
                </div>
                <div className="col-md-4 invoice-part-first-last">
                  <span className="invoice-part-first-text">9173735497</span>
                </div>
              </div>
              <div>
                <div className="col-md-12 invoice-part-second">
                  <div className="col-md-12 invoice-part-second-one">
                    <span className="invoice-part-second-one-text">
                      Order Item Details
                    </span>
                  </div>
                  <div className="col-md-12 invoice-part-second-two">
                    <div className="col-md-3 invoice-part-second-two-1">
                      <span>Time : &nbsp;</span>
                      <span>{moment().format("h:mm a")}</span>
                    </div>
                    <div className="col-md-3 invoice-part-second-two-1">
                      <span>Date : &nbsp;</span>
                      <span>{moment().format("DD-MM-YYYY")}</span>
                    </div>
                    <div className="col-md-3 invoice-part-second-two-1">
                      <span style={{ marginLeft: "10px" }}>Name : &nbsp;</span>
                      <span>
                        {customerDetails?.details?.customer_full_name}
                      </span>
                    </div>
                    <div className="col-md-3">
                      <span>Mobile : &nbsp;</span>
                      <span>{customerDetails?.details?.customer_phone}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 invoice-part-second">
                  <div>
                    <div className="cat-content-middle">
                      <Table className="content-middle-main">
                        <Thead>
                          <Tr>
                            <Th className="content-middle-title-tr ro-in-content-middle-no">
                              No
                            </Th>
                            <Th className="content-middle-title-tr ro-in-content-middle-name">
                              Item Name
                            </Th>
                            <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                              From Date
                            </Th>
                            <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                              To Date
                            </Th>
                            <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                              Rent (Per day)
                            </Th>
                            <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                              Quantity
                            </Th>
                            <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                              Days
                            </Th>
                            <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                              Total Amount
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {customerInDetails &&
                          customerInDetails?.list?.length > 0
                            ? customerInDetails?.list?.map((item, index) => {
                                return (
                                  <Tr>
                                    <Td
                                      className="ro-in-content-middle-no-data"
                                      style={{ borderLeft: "#000 1px solid" }}
                                    >
                                      {index + 1}
                                    </Td>
                                    <Td className="ro-in-content-middle-name-data">
                                      {item?.item_name?.trim() !== ""
                                        ? item.item_name
                                        : "-"}
                                    </Td>
                                    <Td className="ro-in-content-middle-r-qty-data">
                                      {item?.order_date !== ""
                                        ? item?.order_date
                                        : "-"}
                                    </Td>
                                    <Td className="ro-in-content-middle-r-qty-data">
                                      {item?.order_return_date !== ""
                                        ? item?.order_return_date
                                        : "-"}
                                    </Td>
                                    <Td className="ro-in-content-middle-e-d-rent-data">
                                      {item?.rent_per_day !== ""
                                        ? item.rent_per_day?.toFixed(2)
                                        : "-"}
                                    </Td>
                                    <Td className="ro-in-content-middle-r-qty-data">
                                      {item?.quantity !== ""
                                        ? item.quantity
                                        : "-"}
                                    </Td>
                                    <Td className="ro-in-content-middle-r-date-data">
                                      {item?.days !== "" ? item.days : "-"}
                                    </Td>
                                    <Td className="ro-in-content-middle-d-qty-data">
                                      {item?.total_amount !== ""
                                        ? item.total_amount?.toFixed(2)
                                        : "-"}
                                    </Td>
                                  </Tr>
                                );
                              })
                            : ""}
                        </Tbody>
                        <Tbody>
                          <Tr
                            style={{
                              fontWeight: "bold",
                              border: "#000 1px solid",
                            }}
                          >
                            <Td
                              className="in-content-middle-t-1"
                              style={{ borderRight: "#000 1px solid" }}
                              colspan="7"
                            >
                              Total Amount
                            </Td>
                            <Td className="in-content-middle-t-2">
                              ₹ {total_rent?.toFixed(2)}
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-med-12 invoice-part-three">
                <div className="col-md-4 invoice-part-three-1">
                  <span>Signature of Customer</span>
                </div>
                <div className="col-md-4 invoice-part-three-2">
                  <span>Thank You</span>
                </div>
                <div className="col-md-4 invoice-part-three-3">
                  <span>Signature of Receiver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LotiFiles message={"Select Customer and Date!"} />
      )}
    </div>
  );
};

export default InvoiceMiddle;
