import React, { useState } from "react";
import ContentHeader from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import ComingSoon from "../../components/ComingSoon";
import "./../Expenses/Expenses.css";
import SearchContent from "../../components/Search-comp/SearchContent";
import SearchNavigation from "../../components/Search-comp/SearchNavigation";

const Search = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="col-md-12 content">
      <Sidebar
        className="sidebar-main"
        isActive={isActive}
        setIsActive={setIsActive}
        classNameTwo="open-sidebar"
      />
      <div className="col-12 sb-content">
        <div className="sb-content-header">
          <ContentHeader setIsActive={setIsActive} />
        </div>
        <div className="sb-nav">
          <SearchNavigation />
        </div>
        <div className="content-index">
          <SearchContent />
        </div>
      </div>
    </div>
  );
};

export default Search;
