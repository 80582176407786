import React from "react";
import DatePicker from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import { CreditCardIcon, WhiteCalenderIcon } from "../../assets";
import { AlertType, alertService } from "../../utils/alert.service";
import AcInputText from "../AcInputText/AcInputText";
import SearchbleDropDown from "../AcInputText/SearchbleDropDown";
import { AppColors } from "../colors";

const PaymentContentTop = ({
  startDate,
  setStartDate,
  customer,
  c_id,
  setC_id,
  paymentAmount,
  setPaymentAmount,
  CustomerPayment,
  customerPayDetails,
  state,
  searchInput,
  handleChangeSearch,
}) => {
  const letsToastDate = () => {
    alertService.alert({
      type: AlertType.Error,
      message: "Please Select Date",
    });
  };

  const letsToastCustomer = () => {
    alertService.alert({
      type: AlertType.Error,
      message: "Please Select Customer",
    });
  };

  const letsToastPay = () => {
    alertService.alert({
      type: AlertType.Error,
      message: "Please Add Payable Amount ",
    });
  };

  const submitHandler = (e) => {
    if (state == null) {
      if (startDate === "") {
        letsToastDate();
      } else if (c_id == "") {
        letsToastCustomer();
      } else if (paymentAmount.pay_amt === "") {
        letsToastPay();
      } else {
        CustomerPayment();
      }
    } else {
      if (paymentAmount.pay_amt === "") {
        letsToastPay();
      } else {
        CustomerPayment();
      }
    }
  };

  return (
    <div>
      <div className="col-md-12 payment-main">
        <div className="col-md-6 payment-top">
          <div className="payment-top-left-first">
            <span className="payment-top-left-title">PAYMENT DETAILS</span>
          </div>
          <div className="payment-top-left-second">
            <div className="po-accordion-date">
              <label
                style={{ paddingLeft: "15px" }}
                className="ac-inputText-label"
                htmlFor={"Date"}
              >
                Date
              </label>
              <div className="payment-inputText-date-container">
                <DatePicker
                  className="payment-inputText-input-date"
                  onChange={(value) => {
                    setStartDate(value);
                  }}
                  selected={startDate}
                  name="startDate"
                  dateFormat="dd-MM-yyyy"
                />
                <img src={WhiteCalenderIcon} />
              </div>
            </div>
            <div className="payment-date-row">
              {state == null ? (
                <SearchbleDropDown
                  options={customer?.list}
                  value={c_id}
                  onChange={(e) => {
                    setC_id(e[0]?.value);
                  }}
                />
              ) : (
                <AcInputText
                  className="payment-select-input"
                  label={"Customer"}
                  value={state.customer_name}
                />
              )}
            </div>
          </div>
          <table className="payment-table">
            <tbody>
              <tr className="payment-tr">
                <td className="payment-td-one">ORDER AMOUNT : </td>
                <td className="payment-td-two">
                  ₹ {customerPayDetails?.order_amount}
                </td>
              </tr>
              <tr className="payment-tr">
                <td className="payment-td-one">TOTAL EXTRA DAY AMOUNT : </td>
                <td className="payment-td-two">
                  ₹ {customerPayDetails?.total_extra_days_rent}
                </td>
              </tr>
              <tr className="payment-tr">
                <td className="payment-td-one">TOTAL DAMAGE ITEM AMOUNT : </td>
                <td className="payment-td-two">
                  ₹ {customerPayDetails?.damage_cost}
                </td>
              </tr>
              <tr className="payment-tr">
                <td className="payment-td-one">TOTAL VEHICLE CHARGE : </td>
                <td className="payment-td-two">
                  ₹ {customerPayDetails?.vehicle_charge}
                </td>
              </tr>
              <tr className="payment-tr">
                <td className="payment-td-one">TOTAL LABOUR CHARGE : </td>
                <td className="payment-td-two">
                  ₹ {customerPayDetails?.labour_charge}
                </td>
              </tr>
              <tr className="payment-tr">
                <td className="payment-td-one">GROSS PAYBLE AMOUNT : </td>
                <td className="payment-td-two">
                  ₹ {customerPayDetails?.gross_payable_amount}
                </td>
              </tr>
              <tr className="payment-tr">
                <td className="payment-td-one">PAID AMOUNT : </td>
                <td className="payment-td-two">
                  ₹ {customerPayDetails?.paid_amount}
                </td>
              </tr>
              <tr className="payment-tr">
                <td className="payment-td-one">PENDING AMOUNT : </td>
                <td className="payment-td-two">
                  ₹ {customerPayDetails?.pending_amount}
                </td>
              </tr>
              <tr className="payment-tr">
                <td
                  className="payment-td-one-total"
                  style={{ background: AppColors.green, color: "#000" }}
                >
                  PAY AMOUNT :
                </td>
                <td
                  className="payment-td-two-total"
                  style={{
                    background: AppColors.green,
                    color: AppColors.black,
                  }}
                >
                  ₹&nbsp;
                  <input
                    className="payment-td-two-total-input"
                    value={paymentAmount.pay_amt}
                    onChange={(e) =>
                      setPaymentAmount((prevValue) => ({
                        ...prevValue,
                        pay_amt: e.target.value,
                      }))
                    }
                  />
                  <div className="payment-total-border" />
                </td>
              </tr>
              <tr className="payment-tr">
                <td
                  className="payment-td-one-total"
                  style={{ background: "#FFFFE0", color: "#000" }}
                >
                  DEPOSITE AMOUNT :
                </td>
                <td
                  className="payment-td-two-total"
                  style={{
                    background: "#FFFFE0",
                    color: AppColors.black,
                  }}
                >
                  ₹&nbsp;
                  <input
                    className="payment-td-two-depo-input"
                    value={paymentAmount.depo_amt}
                    onChange={(e) =>
                      setPaymentAmount((prevValue) => ({
                        ...prevValue,
                        depo_amt: e.target.value,
                      }))
                    }
                  />
                  <div className="payment-total-border" />
                </td>
              </tr>
              <tr className="payment-tr">
                <td
                  className="payment-td-one-total"
                  style={{ background: "#A0E7E5", color: "#000" }}
                >
                  RETURN DEPOSITE AMOUNT :
                </td>
                <td
                  className="payment-td-two-total"
                  style={{
                    background: "#A0E7E5",
                    color: AppColors.black,
                  }}
                >
                  ₹&nbsp;
                  <input
                    className="payment-td-two-r-depo-input"
                    value={paymentAmount.return_depo_amt}
                    onChange={(e) =>
                      setPaymentAmount((prevValue) => ({
                        ...prevValue,
                        return_depo_amt: e.target.value,
                      }))
                    }
                  />
                  <div className="payment-total-border" />
                </td>
              </tr>
              <tr className="payment-tr">
                <td
                  className="payment-td-one-total"
                  style={{
                    background: AppColors.lightRed,
                    color: AppColors.black,
                  }}
                >
                  DISCOUNT AMOUNT :
                </td>
                <td
                  className="payment-td-two-total"
                  style={{
                    background: AppColors.lightRed,
                    color: AppColors.black,
                  }}
                >
                  ₹&nbsp;
                  <input
                    className="payment-td-two-loss-input"
                    value={paymentAmount.dis_amt}
                    onChange={(e) =>
                      setPaymentAmount((prevValue) => ({
                        ...prevValue,
                        dis_amt: e.target.value,
                      }))
                    }
                  />
                  <div className="payment-total-border" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <div className="payment-card-main">
            <div className="payment-card-main-one">
              <div className="payment-card-one card">
                <span className="payment-card-title-one">Payable Amount</span>
                <span className="payment-card-desc-one">
                  ₹ {customerPayDetails?.gross_payable_amount}
                </span>
                <img className="payment-card-icon" src={CreditCardIcon} />
              </div>
              <div className="payment-card-two card">
                <span className="payment-card-title-two">Paid Amount</span>
                <span className="payment-card-desc-two">
                  ₹ {customerPayDetails?.paid_amount}
                </span>
                <img className="payment-card-icon" src={CreditCardIcon} />
              </div>
            </div>
            <div className="payment-card-main-one">
              <div className="payment-card-three card">
                <span className="payment-card-title-three">
                  Discount Amount
                </span>
                <span className="payment-card-desc-three">
                  ₹ {customerPayDetails?.discount_amount}
                </span>
                <img className="payment-card-icon" src={CreditCardIcon} />
              </div>
              <div className="payment-card-four card">
                <span className="payment-card-title-four">Pending Amount</span>
                <span className="payment-card-desc-four">
                  ₹ {customerPayDetails?.pending_amount}
                </span>
                <img className="payment-card-icon" src={CreditCardIcon} />
              </div>
              <div className="payment-card-four card">
                <span className="payment-card-title-four">Deposite Amount</span>
                <span className="payment-card-desc-four">
                  ₹ {customerPayDetails?.remaining_deposit}
                </span>
                <img className="payment-card-icon" src={CreditCardIcon} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="payment-border" />
      <div className="payment-btn">
        <button className="payment-btn-sub" onClick={submitHandler}>
          Submit
        </button>
        <button
          className="payment-btn-can"
          onClick={() => window.location.reload()}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default PaymentContentTop;
