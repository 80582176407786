import React from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import LotiFiles from "../Lotifiles/LotiFiles";
import Pagination from "../Pagination/Pagination";
import SortIcon from "../AcInputText/SortIcon";
import moment from "moment";
import { DeleteIcon } from "../../assets";

const ExpenseMiddle = ({
  isLoading,
  expenseList,
  selectedPage,
  setSelectedPage,
  setSize,
  onShortData,
  onDelete,
}) => {
  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}
      >
        <span className="payment-top-left-title">EXPENSE / INCOME SUMMERY</span>
        {expenseList?.list?.length > 0 ? (
          <span className="content-top-left-dropdown">
            Show &nbsp;
            <select
              id="number-dd"
              name="number"
              onChange={(e) => setSize(e.target.value)}
            >
              {[10, 25, 50, 75, 100, 125, 150, 175, 200].map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            &nbsp; Entries
          </span>
        ) : null}
      </div>
      <div>
        <div className="ex-content-middle">
          {isLoading ? (
            <TableLoader />
          ) : expenseList?.list?.length > 0 ? (
            <div className="cat-content-middle">
              <Table className="content-middle-main">
                <Thead>
                  <Tr>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Date
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("expense_date")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Expense / Income
                        </div>
                        <div>
                          <SortIcon onClick={() => onShortData("name")} />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Name
                        </div>
                        <div>
                          <SortIcon onClick={() => onShortData("name")} />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Amount
                        </div>
                        <div>
                          <SortIcon onClick={() => onShortData("amount")} />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Description
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("description")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {expenseList && expenseList?.list?.length > 0
                    ? expenseList?.list?.map((item, index) => {
                        return (
                          <Tr>
                            <Td className="content-middle-mobile-data">
                              {item?.date?.trim() !== ""
                                ? moment(item.date).format("DD-MM-YYYY")
                                : "-"}
                            </Td>
                            <Td className="content-middle-mobile-data">
                              {item?.revenue_type?.trim() !== ""
                                ? item.revenue_type
                                : "-"}
                            </Td>
                            <Td className="content-middle-mobile-data">
                              {item?.name?.trim() !== "" ? item.name : "-"}
                            </Td>
                            <Td className="content-middle-mobile-data">
                              {item?.amount !== "" ? item.amount : "-"}
                            </Td>
                            <Td className="content-middle-mobile-data">
                              {item?.description?.trim() !== ""
                                ? item.description
                                : "-"}
                            </Td>
                            <Td className="content-middle-action-data">
                              <div className="content-middle-action-data-div">
                                <img
                                  className="content-middle-action-data-div-delete"
                                  src={DeleteIcon}
                                  onClick={() => onDelete(item)}
                                />
                              </div>
                            </Td>
                          </Tr>
                        );
                      })
                    : null}
                </Tbody>
              </Table>
            </div>
          ) : (
            <LotiFiles message={"No Expenses!!!"} />
          )}
        </div>
      </div>
      {expenseList?.list?.length > 0 ? (
        <div className="content-bottam">
          <Pagination
            selectedPage={selectedPage}
            totalCount={expenseList?.total_pages ?? 1}
            onPageChange={(page) => setSelectedPage(page)}
            itemsPerPage={4}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ExpenseMiddle;
