import axios from "axios";
import React, { useState, useEffect } from "react";
import { BASEURL } from "../../constant/constant";
import CatContentTop from "./ProductContentTop";
import CatContentMiddle from "./ProductContentMiddle";
import AcInputText from "../AcInputText/AcInputText";
import { AddCategory, DeletePopup } from "../Popup/Popup";
import ToggleLoading from "../LoadingSpinner/ToggleLoading";
import { alertService, AlertType } from "../../utils/alert.service";
import { RedCrossIcon } from "../../assets";

const CatContent = () => {
  const [size, setSize] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [catName, setCatName] = useState("");
  const [errors, setErrors] = useState("");
  const [catData, setCatData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productUpdateOpen, setProductUpdateOpen] = useState(false);
  const [productItem, setProductItem] = useState();
  const [delItem, setDelItem] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const catList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const response = await axios({
      url: BASEURL + "product/list",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        size: size,
        page: selectedPage?.selected ?? 1,
        sort_by: sortData,
        order_by: orderBy,
        search_keyword: searchInput,
      },
    });
    setCatData(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    catList(true);
  }, [selectedPage, size, orderBy, sortData, searchInput]);

  const updateStatus = async (value) => {
    setToggleLoading(true);
    axios({
      method: "post",
      maxBodyLength: Infinity,
      url: BASEURL + "product/update_status",
      headers: {
        Authorization: sessionStorage.getItem("auth_token"),
        "Content-Type": "application/json",
      },
      data: value,
    })
      .then(async function (response) {
        setToggleLoading(false);
        await catList();
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        } else if (error.response.status === 409) {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        } else {
          alertService.alert({
            type: AlertType.Error,
            message: error.response.data.map((item) => item.message).join("\n"),
          });
        }
      })
      .finally(() => setToggleLoading(false));
  };

  const updateProductDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const updateProduct = {
      name: productItem.product_name,
      product_id: productItem.product_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "product/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: updateProduct,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleUpdateProductPopup();
        catList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const deleteProductDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const deleteItem = {
      product_id: delItem.product_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "product/delete",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: deleteItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleDeletePopup();
        catList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const toggleAddCatPopup = () => {
    setIsOpen(!isOpen);
    setCatName("");
    setErrors("");
  };

  const onChangeToggle = (val) => {
    updateStatus(val);
  };

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const onEdit = (value) => {
    setProductItem(value);
    toggleUpdateProductPopup();
  };

  const toggleUpdateProductPopup = () => {
    setProductUpdateOpen(!productUpdateOpen);
  };

  const submitCategoryHandler = (e) => {
    e.preventDefault();
    updateProductDetail();
  };

  const onDelete = (delValue) => {
    setDelItem(delValue);
    toggleDeletePopup();
  };

  const deleteSubmitHandler = () => {
    deleteProductDetail();
    toggleDeletePopup();
  };

  const onShortData = (sortItem) => {
    if (sortItem !== sortData) {
      setSortData(sortItem);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  return (
    <div className="content-scroll">
      {toggleLoading ? <ToggleLoading /> : null}
      <div class="content-card card">
        <CatContentTop
          setSize={(value) => {
            setSize(value);
            setSelectedPage(1);
          }}
          toggleAddCatPopup={toggleAddCatPopup}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          catName={catName}
          setCatName={setCatName}
          catList={catList}
          errors={errors}
          setErrors={setErrors}
          catData={catData}
          handleChangeSearch={handleChangeSearch}
          searchInput={searchInput}
        />
        <CatContentMiddle
          catData={catData}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          onChangeToggle={onChangeToggle}
          isLoading={isLoading}
          onEdit={onEdit}
          onDelete={onDelete}
          toggleLoading={toggleLoading}
          onShortData={onShortData}
        />
        {productUpdateOpen && (
          <AddCategory
            content={
              <form>
                <div className="cat-first">
                  <span className="cat-title">PRODUCT DETAILS</span>
                  <span className="cat-desc">All field are required</span>
                </div>
                <div className="cat-second">
                  <span className="cat-second-title">
                    Personal Details
                    <div className="cat-second-hr">
                      <hr />
                    </div>
                  </span>
                  <div className="cat-second-firstRow">
                    <AcInputText
                      label="Name"
                      type="text"
                      placeholder="Name"
                      value={productItem.product_name}
                      onChange={(e) =>
                        setProductItem((prevValue) => ({
                          ...prevValue,
                          product_name: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="cat-second-bottam-btn">
                  <button
                    onClick={submitCategoryHandler}
                    className="cat-second-bottam-btn-sub"
                  >
                    Submit
                  </button>
                  <button
                    className="cat-second-bottam-btn-can"
                    onClick={toggleUpdateProductPopup}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            }
          />
        )}
        {isDeleteOpen && (
          <DeletePopup
            deleteContent={
              <div className="del-pop">
                <div className="del-pop-main">
                  <img className="del-pop-icon" src={RedCrossIcon} />
                </div>
                <div className="del-pop-text-div">
                  <span className="del-pop-text">
                    Are you sure want to delete product : <br />
                    {delItem.product_name}?
                  </span>
                </div>
                <div className="del-pop-btn">
                  <button
                    className="del-pop-btn-yes"
                    onClick={deleteSubmitHandler}
                  >
                    Yes
                  </button>
                  <button
                    className="del-pop-btn-no"
                    onClick={toggleDeletePopup}
                  >
                    No
                  </button>
                </div>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export default CatContent;
