import React from "react";
import "./AcInputText.css";
import { RemovePng } from "../../assets";

const AddressInputText = ({
  type,
  placeholder,
  value,
  onChange,
  label,
  disabled,
  error,
  name,
}) => {
  return (
    <div className="ac-inputText-input-wrapper">
      <label className="ac-inputText-label" htmlFor={label}>
        {label}
      </label>
      <div className="ac-add-inputText-input-container">
        <input
          className="ac-add-inputText-input"
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      {error ? (
        <div className="ac-inputText-error-container">
          <img
            className="inputText-img"
            style={{ verticalAlign: "middle" }}
            src={RemovePng}
            alt=""
          />
          <span>{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default AddressInputText;
