import React from "react";
import LoadingSpin from "react-loading-spin";

const ToggleLoading = () => {
  return (
    <div className="toggle-box-main">
      <div className="toggle-box">
        <LoadingSpin primaryColor="black" size="50px" animationDuration="1s" />
      </div>
    </div>
  );
};

export default ToggleLoading;
