import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ColorGenerator from "random-color-array-generator/ColorGenerator.min.js";
import { useState } from "react";
import { useEffect } from "react";

ChartJS.register(ArcElement);

const getColorsArray = (length) => {
  return new ColorGenerator(length).generateRGBA();
};

const DonutChartComp = ({ productData }) => {
  const [data, setData] = useState("");
  const [itemList, setItemList] = useState("");

  let total_product =
    productData?.result?.reduce((a, b) => a + b?.qty ?? 0, 0) ?? 0;

  useEffect(() => {
    createItemList();
  }, [productData?.result]);

  const createItemList = () => {
    let labels = [];
    let datasetData = [];
    let backgroundColor = getColorsArray(productData?.result?.length);
    const newItemList = productData?.result?.map((mapItem, mapIndex) => {
      labels.push(mapItem.pName);
      datasetData.push(parseInt(mapItem.qty));
      return {
        ...mapItem,
        color: backgroundColor[mapIndex],
      };
    });
    const newData = {
      datasets: [
        {
          labels: labels,
          data: datasetData,
          backgroundColor: backgroundColor,
          borderWidth: 1,
          cutout: "70%",
        },
      ],
    };
    setData(newData);
    setItemList(newItemList);
  };
  const doughnutText = {
    id: "doughnutText",
    afterDatasetsDraw(chart, args, pluginOptions) {
      const {
        ctx,
        data,
        options,
        _active,
        chartArea: { width, height },
      } = chart;

      ctx.save();

      let x = width / 2;
      let y = height / 2.1;
      let b = height / 1.8;

      if (_active.length == 1) {
        const datasetIndexValue = _active[0].datasetIndex;
        const dataIndexValue = _active[0].index;
        const dataLabel =
          data.datasets[datasetIndexValue].labels[dataIndexValue];
        const dataPoint = data.datasets[datasetIndexValue].data[dataIndexValue];
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "bold 16px sans-serif";
        ctx.fillText(dataLabel ? dataLabel : "", x, y);
        ctx.fillText(dataPoint ? dataPoint : "", x, b);
      } else {
        const dataLabel = data.datasets[0].labels[0];
        const dataPoint = data.datasets[0].data[0];
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "bold 16px sans-serif";
        ctx.fillText(dataLabel ? dataLabel : "", x, y);
        ctx.fillText(dataPoint ? dataPoint : "", x, b);
      }
    },
  };

  const plugins = [doughnutText];

  return (
    <div>
      <div className="donut-main-div">
        <div className="donut-div">
          {data ? (
            <Doughnut
              type="doughnut"
              data={data}
              plugins={plugins}
              // options={options}
            />
          ) : null}
        </div>
        <div className="donut-list-card">
          <div className="donut-list">
            <div className="col-md-8 donut-list-card-product">Product Name</div>
            <div className="col-md-4 donut-list-card-quantity">Quantity</div>
          </div>
          <div className="donut-list-hr" />
          <div className="donut-list-scroll">
            {itemList
              ? itemList?.map((item) => (
                  <div>
                    <div className="donut-list">
                      <div className="col-md-8 donut-list-card-product-detail">
                        <div
                          className="donut-list-color-dot"
                          style={{
                            backgroundColor: item.color,
                          }}
                        />
                        <span style={{ paddingLeft: "5px", fontSize: "14px" }}>
                          {item?.pName}
                        </span>
                      </div>
                      <div className="col-md-4 donut-list-card-quantity-detail">
                        <span style={{ fontSize: "14px" }}>{item?.qty}</span>
                      </div>
                    </div>
                    <div className="donut-list-hr" />
                  </div>
                ))
              : null}
          </div>
          <div
            className="donut-list"
            style={{ marginTop: "-20px", marginBottom: "10px" }}
          >
            <div
              className="col-md-8 donut-list-card-product-detail"
              style={{ fontWeight: "bold", fontSize: "14px" }}
            >
              Total Product
            </div>
            <div
              className="col-md-4 donut-list-card-quantity-detail"
              style={{ fontWeight: "bold", fontSize: "14px" }}
            >
              {total_product}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonutChartComp;
