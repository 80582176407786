import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ROAccordion from "./ROAccordion";
import AcInputText from "../AcInputText/AcInputText";
import { alertService, AlertType } from "../../utils/alert.service";
import { useNavigate } from "react-router-dom";
import RoInputText from "./../AcInputText/RoInputText";
import LotiFiles from "../Lotifiles/LotiFiles";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import { ReturnSummery } from "../Popup/Popup";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppColors } from "../colors";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import { useState } from "react";
import { CalenderIcon, SahjanandLogoIcon } from "../../assets";

const ROContentTop = ({
  siteList,
  varData,
  poData,
  setPoData,
  orderData,
  setOrderData,
  returnOrderDetail,
  arr,
  setArr,
  setIsChecked,
  setS_id,
  s_id,
  customer_name,
  customer_full_name,
  customerPList,
  returnOrderDetailCustomer,
  selectedDate,
  setSelectedDate,
  returnOrderCalculateDetail,
  returnOrderCalculateDetailCustomer,
  openCalcPop,
  setOpenCalcPop,
  calcReturnList,
  isLoading,
  customer_phone,
}) => {
  const navigate = useNavigate();
  const disabled = true;
  const disabledF = false;
  const [openInvoice, setOpenInvoice] = useState(false);

  const componentRef = useRef();

  const printDocument = () => {
    window.print();
  };

  let total_damage_qty = 0;
  let total_ext_days = 0;
  let total_ext_days_rent = 0;
  let total_payable_payment = 0;
  let total_remain_payment = 0;
  let total_charges =
    calcReturnList.labour_charge + calcReturnList.vehicle_charge;

  for (let i = 0; i < calcReturnList?.list?.length; i++) {
    total_ext_days += calcReturnList?.list[i]?.total_rent;
    total_damage_qty += calcReturnList?.list[i]?.total_damaged_cost;
    total_ext_days_rent += calcReturnList?.list[i]?.total_extra_days_rent;
    total_payable_payment += calcReturnList?.list[i]?.payable_amount;
    total_remain_payment += calcReturnList?.list[i]?.remaining_payment;
  }

  const onReturnQty = (value, mapObject, item) => {
    const isPresent = orderData.find(
      (findItem) =>
        findItem.product_variants_id === mapObject.product_variants_id
    );
    if (isPresent && value == 0) {
      setOrderData(
        orderData.filter(
          (filterItem) =>
            filterItem.product_variants_id !== mapObject.product_variants_id
        )
      );
    } else if (isPresent) {
      setOrderData(
        orderData.map((mapItem) => {
          if (mapItem.product_variants_id === mapObject.product_variants_id) {
            return {
              ...mapItem,
              product_name: item?.product_name,
              product_id: item?.product_id,
              return_qty: value,
            };
          } else {
            return mapItem;
          }
        })
      );
    } else if (value != 0) {
      setOrderData((prevState) => [
        ...prevState,
        {
          ...mapObject,
          return_qty: value,
        },
      ]);
    }
  };

  const onDamageQty = (value, mapObject, item) => {
    const isPresent = orderData.find(
      (findItem) =>
        findItem.product_variants_id === mapObject.product_variants_id
    );
    if (isPresent) {
      setOrderData(
        orderData.map((mapItem) => {
          if (mapItem.product_variants_id === mapObject.product_variants_id) {
            return {
              ...mapItem,
              product_name: item?.product_name,
              product_id: item?.product_id,
              damage_qty: value,
            };
          } else {
            return mapItem;
          }
        })
      );
    } else if (value != 0) {
      setOrderData((prevState) => [
        ...prevState,
        {
          ...mapObject,
          damage_qty: value,
        },
      ]);
    }
  };

  const onDamageCharge = (value, mapObject, item) => {
    const isPresent = orderData.find(
      (findItem) =>
        findItem.product_variants_id === mapObject.product_variants_id
    );
    if (isPresent) {
      setOrderData(
        orderData.map((mapItem) => {
          if (mapItem.product_variants_id === mapObject.product_variants_id) {
            return {
              ...mapItem,
              product_name: item?.product_name,
              product_id: item?.product_id,
              damage_charge: value,
            };
          } else {
            return mapItem;
          }
        })
      );
    } else if (value != 0) {
      setOrderData((prevState) => [
        ...prevState,
        {
          ...mapObject,
          damage_charge: value,
        },
      ]);
    }
  };

  const handlecheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked == true) {
      setArr((prevValue) => [...prevValue, value]);
    } else {
      setArr(arr.filter((item) => item !== value));
    }
    setIsChecked(checked);
  };

  const returnOrderCancel = () => {
    navigate(-1);
  };

  const toggleSummeryCalcPopup = () => {
    setOpenCalcPop(!openCalcPop);
    setOpenInvoice(!openInvoice);
  };

  const o_data = orderData.filter(
    (filterItem) =>
      filterItem?.return_qty?.trim() !== "" &&
      parseInt(filterItem.return_qty) !== 0
  );

  const returnOrderSubmitHandler = (e) => {
    e.preventDefault();
    if (o_data.length == 0) {
      alertService.alert({
        type: AlertType.Error,
        message: "Please Add Return Quantity!",
      });
    } else if (s_id) {
      returnOrderDetail();
    } else {
      returnOrderDetailCustomer();
    }
  };

  const returnOrderCalculateHandler = (e) => {
    e.preventDefault();
    setOpenInvoice(!openInvoice);
    if (o_data.length == 0) {
      alertService.alert({
        type: AlertType.Error,
        message: "Please Add Return Quantity!",
      });
    } else if (s_id) {
      returnOrderCalculateDetail();
    } else {
      returnOrderCalculateDetailCustomer();
    }
  };

  return (
    <div>
      <div className="content-top">
        <div className="content-top-left">
          <span className="content-top-left-title">
            RETURN ORDER &nbsp;
            <span style={{ color: "gray" }}>
              ({customer_name ?? customer_full_name})
            </span>
          </span>
          <div className="ro-content-top">
            <div className="po-accordion-date" style={{ marginLeft: "20px" }}>
              <label className="ac-inputText-label" htmlFor={"Date"}>
                Date
              </label>
              <div className="ac-inputText-date-container">
                <DatePicker
                  className="ac-inputText-input-date"
                  onChange={(value) => setSelectedDate(value)}
                  selected={selectedDate}
                  name="startDate"
                  dateFormat="dd-MM-yyyy"
                />
                <img src={CalenderIcon} />
              </div>
            </div>
            <div className="po-accordion-input">
              <AcInputText
                label="Chalan Number"
                type="text"
                placeholder="Chalan Number"
                value={poData.cNumber}
                onChange={(e) =>
                  setPoData((prevValue) => ({
                    ...prevValue,
                    cNumber: e.target.value,
                  }))
                }
              />
            </div>
            {s_id ? (
              <div className="ac-inputText-input-wrapper">
                <label className="ac-inputText-label" htmlFor={"Site Name"}>
                  Site Name
                </label>
                <select
                  style={{ width: "300px" }}
                  className="ac-inputText-input-container"
                  onChange={(e) => {
                    setS_id(e.target.value);
                  }}
                  value={s_id}
                >
                  {siteList?.list &&
                    siteList?.list?.map((item) => {
                      return (
                        <option
                          className="ac-inputText-input"
                          value={item.value}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                </select>
              </div>
            ) : null}
          </div>
          <div className="po-content-top-hr" />
          <span className="po-content-top-second-txt">Item Details</span>
          {s_id ? (
            <div>
              {varData?.list && varData?.list?.length > 0 ? (
                varData?.list.map((item) => {
                  return (
                    <ROAccordion
                      product_name={item?.product_name}
                      content={
                        item.product_variants &&
                        item.product_variants.length > 0
                          ? item.product_variants.map((mapItem) => {
                              return (
                                <div>
                                  <div className="po-accordion-main">
                                    <div className="po-accordion">
                                      <input
                                        className="po-accordion-checkbox"
                                        type="checkbox"
                                        value={mapItem.product_variants_id}
                                        onChange={(e) => handlecheckbox(e)}
                                      />
                                      &nbsp;
                                      {arr.includes(
                                        mapItem.product_variants_id
                                      ) ? (
                                        <span
                                          className="po-accordion-checkbox-label"
                                          style={{
                                            color: "black",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {mapItem.product_variant_size}&nbsp;
                                          {mapItem.measurement_name}
                                        </span>
                                      ) : (
                                        <span className="po-accordion-checkbox-label">
                                          {mapItem.product_variant_size}&nbsp;
                                          {mapItem.measurement_name}
                                        </span>
                                      )}
                                    </div>
                                    <div className="po-accordion-main-input">
                                      <div className="po-accordion-input">
                                        <RoInputText
                                          label="Order Quantity"
                                          type="text"
                                          value={mapItem.ordered_quantity}
                                          disabled
                                        />
                                      </div>
                                      <div className="po-accordion-input">
                                        <RoInputText
                                          label="Remain Quantity"
                                          type="text"
                                          value={mapItem.pending_quantity}
                                          disabled
                                        />
                                      </div>
                                      <div className="po-accordion-input one">
                                        <RoInputText
                                          label="Return Quantity"
                                          type="text"
                                          placeholder="Return Qty"
                                          disabled={
                                            arr.includes(
                                              mapItem.product_variants_id
                                            )
                                              ? disabledF
                                              : disabled
                                          }
                                          value={
                                            arr.includes(
                                              mapItem.product_variants_id
                                            )
                                              ? orderData.find(
                                                  (findItem) =>
                                                    findItem.product_variants_id ===
                                                    mapItem.product_variants_id
                                                )?.return_qty
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onReturnQty(
                                              e.target.value.replace(/\D/g, ""),
                                              mapItem,
                                              item
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="po-accordion-input one">
                                        <RoInputText
                                          label="Damage Quantity"
                                          type="text"
                                          placeholder="Damage Qty"
                                          disabled={
                                            arr.includes(
                                              mapItem.product_variants_id
                                            )
                                              ? disabledF
                                              : disabled
                                          }
                                          value={
                                            arr.includes(
                                              mapItem.product_variants_id
                                            )
                                              ? orderData.find(
                                                  (findItem) =>
                                                    findItem.product_variants_id ===
                                                    mapItem.product_variants_id
                                                )?.damage_qty
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onDamageQty(
                                              e.target.value.replace(/\D/g, ""),
                                              mapItem,
                                              item
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="po-accordion-input one">
                                        <RoInputText
                                          label="Damage Item Charge"
                                          type="text"
                                          placeholder="Damage Charge"
                                          disabled={
                                            arr.includes(
                                              mapItem.product_variants_id
                                            )
                                              ? disabledF
                                              : disabled
                                          }
                                          value={
                                            arr.includes(
                                              mapItem.product_variants_id
                                            )
                                              ? orderData.find(
                                                  (findItem) =>
                                                    findItem.product_variants_id ===
                                                    mapItem.product_variants_id
                                                )?.damage_charge
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onDamageCharge(
                                              e.target.value.replace(/\D/g, ""),
                                              mapItem,
                                              item
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : null
                      }
                    />
                  );
                })
              ) : (
                <LotiFiles message={"No Return Items!"} />
              )}
            </div>
          ) : (
            <div>
              {customerPList?.list && customerPList?.list?.length > 0 ? (
                customerPList?.list.map((item) => {
                  return (
                    <ROAccordion
                      product_name={item?.product_name}
                      content={
                        item.product_variants &&
                        item.product_variants.length > 0
                          ? item.product_variants.map((mapItem) => {
                              return (
                                <div>
                                  <div className="po-accordion-main">
                                    <div className="po-accordion">
                                      <input
                                        className="po-accordion-checkbox"
                                        type="checkbox"
                                        value={mapItem.product_variants_id}
                                        onChange={(e) => handlecheckbox(e)}
                                      />
                                      &nbsp;
                                      {arr.includes(
                                        mapItem.product_variants_id
                                      ) ? (
                                        <span
                                          className="po-accordion-checkbox-label"
                                          style={{
                                            color: "black",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {mapItem.product_variant_size}&nbsp;
                                          {mapItem.measurement_name}
                                        </span>
                                      ) : (
                                        <span className="po-accordion-checkbox-label">
                                          {mapItem.product_variant_size}&nbsp;
                                          {mapItem.measurement_name}
                                        </span>
                                      )}
                                    </div>
                                    <div className="po-accordion-main-input">
                                      <div className="po-accordion-input">
                                        <RoInputText
                                          label="Order Quantity"
                                          type="text"
                                          value={mapItem.ordered_quantity}
                                          disabled
                                        />
                                      </div>
                                      <div className="po-accordion-input">
                                        <RoInputText
                                          label="Remain Quantity"
                                          type="text"
                                          value={mapItem.pending_quantity}
                                          disabled
                                        />
                                      </div>
                                      <div className="po-accordion-input one">
                                        <RoInputText
                                          label="Return Quantity"
                                          type="text"
                                          placeholder="Return Qty"
                                          disabled={
                                            arr.includes(
                                              mapItem.product_variants_id
                                            )
                                              ? disabledF
                                              : disabled
                                          }
                                          value={
                                            arr.includes(
                                              mapItem.product_variants_id
                                            )
                                              ? orderData.find(
                                                  (findItem) =>
                                                    findItem.product_variants_id ===
                                                    mapItem.product_variants_id
                                                )?.return_qty
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onReturnQty(
                                              e.target.value.replace(/\D/g, ""),
                                              mapItem,
                                              item
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="po-accordion-input one">
                                        <RoInputText
                                          label="Damage Quantity"
                                          type="text"
                                          placeholder="Damage Qty"
                                          disabled={
                                            arr.includes(
                                              mapItem.product_variants_id
                                            )
                                              ? disabledF
                                              : disabled
                                          }
                                          value={
                                            arr.includes(
                                              mapItem.product_variants_id
                                            )
                                              ? orderData.find(
                                                  (findItem) =>
                                                    findItem.product_variants_id ===
                                                    mapItem.product_variants_id
                                                )?.damage_qty
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onDamageQty(
                                              e.target.value.replace(/\D/g, ""),
                                              mapItem,
                                              item
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="po-accordion-input one">
                                        <RoInputText
                                          label="Damage Item Charge"
                                          type="text"
                                          placeholder="Damage Charge"
                                          disabled={
                                            arr.includes(
                                              mapItem.product_variants_id
                                            )
                                              ? disabledF
                                              : disabled
                                          }
                                          value={
                                            arr.includes(
                                              mapItem.product_variants_id
                                            )
                                              ? orderData.find(
                                                  (findItem) =>
                                                    findItem.product_variants_id ===
                                                    mapItem.product_variants_id
                                                )?.damage_charge
                                              : ""
                                          }
                                          onChange={(e) => {
                                            onDamageCharge(
                                              e.target.value.replace(/\D/g, ""),
                                              mapItem,
                                              item
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : null
                      }
                    />
                  );
                })
              ) : (
                <LotiFiles message={"No Return Items!"} />
              )}
            </div>
          )}

          <div className="po-content-top-hr" />
          <div>
            <span
              className="content-top-left-title"
              style={{ marginTop: "10px" }}
            >
              LABOUR CHARGES
            </span>
            <div className="po-accordion-second">
              <div className="po-accordion-second-input">
                <AcInputText
                  label="Vehicle Rent"
                  type="text"
                  placeholder="Vehicle Rent"
                  value={poData.vRent}
                  onChange={(e) =>
                    setPoData((prevValue) => ({
                      ...prevValue,
                      vRent: e.target.value.replace(/\D/g, ""),
                    }))
                  }
                />
              </div>
              <div className="po-accordion-second-input">
                <AcInputText
                  label="Labour Rent"
                  type="text"
                  placeholder="Labour Rent"
                  value={poData.lRent}
                  onChange={(e) =>
                    setPoData((prevValue) => ({
                      ...prevValue,
                      lRent: e.target.value.replace(/\D/g, ""),
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="po-content-top-hr" />
          <div className="po-second-bottam-btn">
            <button
              className="po-second-bottam-btn-sub"
              onClick={returnOrderSubmitHandler}
            >
              Submit
            </button>
            <button
              style={{ marginLeft: "7px" }}
              className="po-second-bottam-btn-sub"
              onClick={returnOrderCalculateHandler}
            >
              Calculate
            </button>
            <button
              className="po-second-bottam-btn-can"
              onClick={returnOrderCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div>
        {orderData?.length > 0 ? (
          <div>
            <span className="content-top-left-title">
              RETURN ORDER LIST PREVIEW
            </span>
            <div className="content-middle">
              <Table className="cat-content-middle-main">
                <Thead>
                  <Tr>
                    <Th className="content-middle-title-tr">Item Name</Th>
                    <Th className="content-middle-title-tr">Order Qty</Th>
                    <Th className="content-middle-title-tr">Remain Qty</Th>
                    <Th className="content-middle-title-tr">Return Qty</Th>
                    <Th className="content-middle-title-tr">Damage Qty</Th>
                    <Th className="content-middle-title-tr">
                      Damage Item Charge
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {orderData
                    .filter((filterItem) => filterItem?.return_qty !== 0)
                    .map((orderItem, key) =>
                      arr.includes(orderItem?.product_variants_id) ? (
                        <Tr key={key}>
                          <Td className="content-middle-name-data">
                            {orderItem?.product_name}&nbsp;
                            {orderItem?.product_variant_size}&nbsp;
                            {orderItem?.measurement_name}
                          </Td>
                          <Td className="content-middle-email-data">
                            {orderItem?.ordered_quantity}
                          </Td>
                          <Td className="content-middle-email-data">
                            {orderItem?.pending_quantity}
                          </Td>
                          <Td className="content-middle-name-data">
                            {orderItem?.return_qty ? orderItem?.return_qty : 0}
                          </Td>
                          <Td className="content-middle-email-data">
                            {orderItem?.damage_qty ? orderItem.damage_qty : 0}
                          </Td>
                          <Td className="content-middle-name-data">
                            {orderItem?.damage_charge
                              ? orderItem?.damage_charge
                              : 0}
                          </Td>
                        </Tr>
                      ) : null
                    )}
                </Tbody>
              </Table>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {openCalcPop ? (
        <div>
          <ReturnSummery
            summeryContent={
              <div>
                <div>
                  <span className="cl-summary-title">
                    RETURN ORDER DETAILS &nbsp;
                    <span style={{ color: AppColors.darkRed }}>
                      RETURN DATE : &nbsp;
                    </span>
                    {moment(selectedDate).format("DD-MM-YYYY")}
                  </span>
                  <div className="cl-summary-hr" />
                  {isLoading ? (
                    <TableLoader />
                  ) : (
                    <div>
                      {calcReturnList?.list?.length > 0 ? (
                        <div className="content-middle">
                          <Table className="content-middle-main">
                            <Thead>
                              <Tr>
                                <Th className="content-middle-title-tr ro-content-middle-no">
                                  No
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-name">
                                  Item Name
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-name">
                                  Order Date
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-name">
                                  Return Order Date
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-name">
                                  Site Name
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-r-qty">
                                  Return Qty
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-d-qty">
                                  Unit Rent
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-d-q-charge">
                                  Total Rent
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-e-days">
                                  Extra Days
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-e-days">
                                  Discount Days
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-t-days">
                                  Extra Days Rent
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-e-d-rent">
                                  Total Extra Days Rent
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-t-rent">
                                  Damage Qty
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-t-e-d-rent">
                                  Total Damage Rent
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-o-date">
                                  Damage Qty Cost
                                </Th>
                                <Th className="content-middle-title-tr ro-content-middle-t-amount">
                                  Payable Amount
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {calcReturnList?.list &&
                              calcReturnList?.list?.length > 0
                                ? calcReturnList?.list?.map((item, index) => {
                                    return (
                                      <Tr key={index}>
                                        <Td className="ro-content-middle-no-data">
                                          {index + 1}
                                        </Td>
                                        <Td className="ro-content-middle-name-data">
                                          {item.item_name}
                                        </Td>
                                        <Td className="ro-content-middle-name-data">
                                          {item?.order_date?.trim() !== ""
                                            ? moment(item.order_date).format(
                                                "DD-MM-YYYY"
                                              )
                                            : "-"}
                                        </Td>
                                        <Td className="ro-content-middle-name-data">
                                          {item?.order_date?.trim() !== ""
                                            ? moment(item.order_date)
                                                .add(14, "days")
                                                .format("DD-MM-YYYY")
                                            : "-"}
                                        </Td>
                                        <Td className="ro-content-middle-name-data">
                                          {item.site_name}
                                        </Td>
                                        <Td className="ro-content-middle-r-qty-data">
                                          {item.return_quantity}
                                        </Td>
                                        <Td className="ro-content-middle-e-d-rent-data">
                                          {item.unit_rent.toFixed(2)}
                                        </Td>
                                        <Td className="ro-content-middle-t-e-d-rent-data">
                                          {item.total_rent.toFixed(2)}
                                        </Td>
                                        <Td className="ro-content-middle-r-date-data">
                                          {item.extra_days}
                                        </Td>
                                        <Td className="ro-content-middle-r-date-data">
                                          {item.discounted_days}
                                        </Td>
                                        <Td className="ro-content-middle-u-rent-data">
                                          {item.extra_days_rent.toFixed(2)}
                                        </Td>
                                        <Td className="ro-content-middle-e-days-data">
                                          {item.total_extra_days_rent.toFixed(
                                            2
                                          )}
                                        </Td>
                                        <Td className="ro-content-middle-t-rent-data">
                                          {item.damaged_quantity}
                                        </Td>
                                        <Td className="ro-content-middle-t-days-data">
                                          {item.total_damaged_cost.toFixed(2)}
                                        </Td>
                                        <Td className="ro-content-middle-o-date-data">
                                          {item.damaged_cost_item.toFixed(2)}
                                        </Td>
                                        <Td className="ro-content-middle-d-qty-data">
                                          {item.payable_amount?.toFixed(2)}
                                        </Td>
                                      </Tr>
                                    );
                                  })
                                : null}
                            </Tbody>
                            <Tbody>
                              <Tr>
                                <Td
                                  className="ro-content-middle-ext-3"
                                  colspan="7"
                                >
                                  Total Rent
                                </Td>
                                <Td className="ro-content-middle-ext-4">
                                  {total_ext_days.toFixed(2)}
                                </Td>
                                <Td
                                  className="ro-content-middle-ext-5"
                                  colspan="3"
                                  style={{ textAlign: "right" }}
                                >
                                  Total Ext. Days Rent
                                </Td>
                                <Td className="ro-content-middle-ext-6">
                                  {total_ext_days_rent?.toFixed(2)}
                                </Td>
                                <Td
                                  className="ro-content-middle-ext-1"
                                  colspan="1"
                                >
                                  Total Damage Cost
                                </Td>
                                <Td className="ro-content-middle-ext-2">
                                  {total_damage_qty?.toFixed(2)}
                                </Td>
                                <Td
                                  className="ro-content-middle-ext-1"
                                  colspan="1"
                                >
                                  Total Amount
                                </Td>
                                <Td className="ro-content-middle-ext-7">
                                  ₹ {total_payable_payment?.toFixed(2)}
                                </Td>
                              </Tr>
                              <Tr>
                                <Td
                                  className="ro-content-middle-v-1"
                                  colspan="15"
                                >
                                  Vehicle Rent
                                </Td>
                                <Td className="ro-content-middle-v-2">
                                  ₹ {calcReturnList.vehicle_charge?.toFixed(2)}
                                </Td>
                              </Tr>
                              <Tr>
                                <Td
                                  className="ro-content-middle-l-1"
                                  colspan="15"
                                >
                                  Labour Rent
                                </Td>
                                <Td className="ro-content-middle-l-2">
                                  ₹ {calcReturnList.labour_charge?.toFixed(2)}
                                </Td>
                              </Tr>
                              <Tr style={{ fontWeight: "bold" }}>
                                <Td
                                  className="ro-content-middle-t-1"
                                  colspan="13"
                                >
                                  Total Remaining Payment
                                </Td>
                                <Td className="ro-content-middle-t-2">
                                  ₹ {total_remain_payment?.toFixed(2)}
                                </Td>
                                <Td className="ro-content-middle-t-1">
                                  Total Payable Amount
                                </Td>
                                <Td className="ro-content-middle-t-2">
                                  ₹
                                  {(
                                    total_payable_payment + total_charges
                                  )?.toFixed(2)}
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </div>
                      ) : (
                        <LotiFiles
                          message={"No Data Found For Return Orders!"}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="cl-summary-btn-div">
                  <ReactToPrint
                    trigger={() => (
                      <button
                        className="cl-summary-btn"
                        style={{ backgroundColor: AppColors.red }}
                        onClick={printDocument}
                      >
                        Print
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                  <button
                    style={{ marginLeft: "10px" }}
                    className="cl-summary-btn"
                    onClick={toggleSummeryCalcPopup}
                  >
                    Close
                  </button>
                </div>
              </div>
            }
          />
        </div>
      ) : null}
      {openInvoice ? (
        <div ref={componentRef}>
          <div>
            <div className="col-md-12 invoice-date-name">
              <div className="col-md-6">
                <span>{moment().format("DD-MM-YYYY, h:mm")}</span>
              </div>
              <div className="col-md-6">
                <span>Sahjanand Construction</span>
              </div>
            </div>
            <div className="invoice-main">
              <div className="col-md-12 invoice-part-first">
                <div className="col-md-4 invoice-part-first-left">
                  <span className="invoice-part-first-text">Gopal Patel</span>
                </div>
                <div className="col-md-4 invoice-part-first-middle">
                  <img
                    className="invoice-part-first-middle-logo"
                    src={SahjanandLogoIcon}
                  />
                  <span className="invoice-part-first-middle-name">
                    સહજાનંદ કન્ટ્રકશન
                  </span>
                </div>
                <div className="col-md-4 invoice-part-first-last">
                  <span className="invoice-part-first-text">9173735497</span>
                </div>
              </div>
              <div>
                <div className="col-md-12 invoice-part-second">
                  <div className="col-md-12 invoice-part-second-one">
                    <span className="invoice-part-second-one-text">
                      Returned Item Details
                    </span>
                  </div>
                  <div className="col-md-12 invoice-part-second-two">
                    <div className="col-md-6 invoice-part-second-two-1">
                      <span>Date :</span>
                      <span>
                        {" "}
                        {calcReturnList?.order_return_date?.trim() !== ""
                          ? moment(calcReturnList.order_return_date).format(
                              "DD-MM-YYYY"
                            )
                          : "-"}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <span>Mobile :</span>
                      <span>{customer_phone}</span>
                    </div>
                  </div>
                  <div className="col-md-12 invoice-part-second-three">
                    <div className="col-md-6 invoice-part-second-two-1">
                      <span>Name :</span>
                      <span>{customer_name}</span>
                    </div>
                    <div className="col-md-6">
                      <span>Returned By :</span>
                      <span>{customer_name}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 invoice-part-second">
                  <div>
                    <div className="ro-in">
                      <div>
                        {calcReturnList?.list?.length > 0 ? (
                          <div className="col-md-12 invoice-part-second">
                            <div>
                              <div className="ro-in">
                                <Table className=" ro-in-table">
                                  <Thead>
                                    <Tr>
                                      <Th className="content-middle-title-tr ro-in-content-middle-no">
                                        No
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-name">
                                        Item Name
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                                        Order Date
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                                        Return Date
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                                        Return Qty
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-d-qty">
                                        Unit Rent
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-d-q-charge">
                                        Total Rent
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-e-days">
                                        Extra Days
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-t-days">
                                        Extra Days Rent
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-e-d-rent">
                                        Total Extra Days Rent
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-t-rent">
                                        Damage Qty
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-o-date">
                                        Damage Qty Cost
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-t-e-d-rent">
                                        Total Damage Rent
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-t-amount">
                                        Remaining Payment
                                      </Th>
                                      <Th className="content-middle-title-tr ro-in-content-middle-t-amount">
                                        Payable Amount
                                      </Th>
                                    </Tr>
                                  </Thead>
                                  {calcReturnList?.list &&
                                  calcReturnList?.list?.length > 0
                                    ? calcReturnList?.list?.map(
                                        (item, index) => {
                                          return (
                                            <Tbody>
                                              <Tr>
                                                <Td className="ro-in-content-middle-no-data">
                                                  {index + 1}
                                                </Td>
                                                <Td className="ro-in-content-middle-name-data">
                                                  {item.item_name}
                                                </Td>
                                                <Td className="ro-in-content-middle-r-qty-data">
                                                  {item?.order_date?.trim() !==
                                                  ""
                                                    ? moment(
                                                        item?.order_date
                                                      ).format("DD-MM-YYYY")
                                                    : "-"}
                                                </Td>
                                                <Td className="ro-in-content-middle-r-qty-data">
                                                  {item?.order_date?.trim() !==
                                                  ""
                                                    ? moment(item.order_date)
                                                        .add(14, "days")
                                                        .format("DD-MM-YYYY")
                                                    : "-"}
                                                </Td>
                                                <Td className="ro-in-content-middle-r-qty-data">
                                                  {item.return_quantity}
                                                </Td>
                                                <Td className="ro-in-content-middle-e-d-rent-data">
                                                  {item.unit_rent}
                                                </Td>
                                                <Td className="ro-in-content-middle-t-e-d-rent-data">
                                                  {item?.total_rent?.toLocaleString(
                                                    "en-US",
                                                    {
                                                      minimumFractionDigits: 0,
                                                      useGrouping: true,
                                                    }
                                                  )}
                                                </Td>
                                                <Td className="ro-in-content-middle-r-date-data">
                                                  {item?.extra_days}
                                                </Td>
                                                <Td className="ro-in-content-middle-u-rent-data">
                                                  {item?.extra_days_rent?.toLocaleString(
                                                    "en-US",
                                                    {
                                                      minimumFractionDigits: 0,
                                                      useGrouping: true,
                                                    }
                                                  )}
                                                </Td>
                                                <Td className="ro-in-content-middle-e-days-data">
                                                  {item?.total_extra_days_rent?.toLocaleString(
                                                    "en-US",
                                                    {
                                                      minimumFractionDigits: 0,
                                                      useGrouping: true,
                                                    }
                                                  )}
                                                </Td>
                                                <Td className="ro-in-content-middle-t-rent-data">
                                                  {item?.damaged_quantity}
                                                </Td>
                                                <Td className="ro-in-content-middle-o-date-data">
                                                  {item?.damaged_cost_item}
                                                </Td>
                                                <Td className="ro-in-content-middle-t-days-data">
                                                  {item?.total_damaged_cost?.toLocaleString(
                                                    "en-US",
                                                    {
                                                      minimumFractionDigits: 0,
                                                      useGrouping: true,
                                                    }
                                                  )}
                                                </Td>
                                                <Td className="ro-in-content-middle-d-qty-data">
                                                  {item?.remaining_payment?.toLocaleString(
                                                    "en-US",
                                                    {
                                                      minimumFractionDigits: 0,
                                                      useGrouping: true,
                                                    }
                                                  )}
                                                </Td>
                                                <Td className="ro-in-content-middle-d-qty-data">
                                                  {item?.payable_amount?.toLocaleString(
                                                    "en-US",
                                                    {
                                                      minimumFractionDigits: 0,
                                                      useGrouping: true,
                                                    }
                                                  )}
                                                </Td>
                                              </Tr>
                                            </Tbody>
                                          );
                                        }
                                      )
                                    : null}
                                  <Tbody>
                                    <Tr>
                                      <Td
                                        className="ro-in-content-middle-ext-3"
                                        colspan="7"
                                      >
                                        Total Ext. Days
                                      </Td>
                                      <Td className="ro-in-content-middle-ext-4">
                                        {total_ext_days}
                                      </Td>
                                      <Td className="ro-in-content-middle-ext-5">
                                        Total Ext. Days Rent
                                      </Td>
                                      <Td className="ro-in-content-middle-ext-6">
                                        {total_ext_days_rent?.toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 0,
                                            useGrouping: true,
                                          }
                                        )}
                                      </Td>
                                      <Td
                                        className="ro-in-content-middle-ext-1"
                                        colspan="2"
                                      >
                                        Total Damage Cost
                                      </Td>
                                      <Td className="ro-in-content-middle-ext-2">
                                        {total_damage_qty?.toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 0,
                                            useGrouping: true,
                                          }
                                        )}
                                      </Td>
                                      <Td
                                        className="ro-in-content-middle-ext-1"
                                        colspan="1"
                                      >
                                        Total Amount
                                      </Td>
                                      <Td className="ro-in-content-middle-ext-7">
                                        ₹{" "}
                                        {total_payable_payment?.toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 0,
                                            useGrouping: true,
                                          }
                                        )}
                                      </Td>
                                    </Tr>
                                    <Tr>
                                      <Td
                                        className="ro-in-content-middle-v-1"
                                        colspan="14"
                                      >
                                        Vehicle Rent
                                      </Td>
                                      <Td className="ro-in-content-middle-v-2">
                                        ₹{" "}
                                        {calcReturnList?.vehicle_charge?.toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 0,
                                            useGrouping: true,
                                          }
                                        )}
                                      </Td>
                                    </Tr>
                                    <Tr>
                                      <Td
                                        className="ro-in-content-middle-l-1"
                                        colspan="14"
                                      >
                                        Labour Rent
                                      </Td>
                                      <Td className="ro-in-content-middle-l-2">
                                        ₹{" "}
                                        {calcReturnList?.labour_charge?.toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 0,
                                            useGrouping: true,
                                          }
                                        )}
                                      </Td>
                                    </Tr>
                                    <Tr>
                                      <Td
                                        className="ro-in-content-middle-t-1"
                                        colspan="14"
                                      >
                                        Total Payable Amount
                                      </Td>
                                      <Td className="ro-in-content-middle-t-2">
                                        ₹{" "}
                                        {(
                                          total_payable_payment + total_charges
                                        )?.toLocaleString("en-US", {
                                          minimumFractionDigits: 0,
                                          useGrouping: true,
                                        })}
                                      </Td>
                                    </Tr>
                                  </Tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <LotiFiles
                            message={"No Data Found For Return Orders!"}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-med-12 invoice-part-three">
                <div className="col-md-4 invoice-part-three-1">
                  <span>Signature of Customer</span>
                </div>
                <div className="col-md-4 invoice-part-three-2">
                  <span>Thank You</span>
                </div>
                <div className="col-md-4 invoice-part-three-3">
                  <span>Signature of Receiver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ROContentTop;
