import axios from "axios";
import React, { useEffect, useState } from "react";
import { alertService, AlertType } from "../../utils/alert.service";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { BASEURL } from "../../constant/constant";
import ROContentTop from "./ROContentTop";
import ROContentMiddle from "./ROContentMiddle";
import ROContentBottam from "./ROContentBottam";
import moment from "moment";

const ROContent = () => {
  const { state } = useLocation();
  const [siteSelectedPage, setSiteSelectedPage] = useState(1);
  const [siteSize, setSiteSize] = useState(10);
  const [customerSelectedPage, setCustomerSelectedPage] = useState(1);
  const [customerSize, setCustomerSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [siteList, setSiteList] = useState("");
  const [varData, setVarData] = useState("");
  const [placeOrder, setPlaceOrder] = useState("");
  const [returnOrder, setReturnOrder] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [arr, setArr] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [s_id, setS_id] = useState(state.site_id);
  const [customerPList, setCustomerPList] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const navigate = useNavigate();
  const [p_I_Customer, setP_I_Customer] = useState("");
  const [p_I_Site, setP_I_Site] = useState("");
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [returnOrderData, setReturnOrderData] = useState([]);
  const [calcReturnList, setCalcReturnList] = useState("");
  const [openCalcPop, setOpenCalcPop] = useState(false);

  const [poData, setPodata] = useState({
    vRent: "",
    lRent: "",
    return_qty: "",
    damage_qty: "",
    damage_charge: "",
    cNumber: "",
  });

  const SiteList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const item = {
      customer_id: state.customer_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "customer/site/drop_down",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      setSiteList(response.data);
      showSkeleton && setIsLoading(false);
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  useEffect(() => {
    SiteList(true);
  }, []);

  const CustomerPendingList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);

    const item = {
      customer_id: state.customer_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "order/pending/variant/list_by_customer",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      setCustomerPList(response.data);
      showSkeleton && setIsLoading(false);
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  useEffect(() => {
    CustomerPendingList(true);
  }, []);

  const varList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const item = {
      site_id: s_id,
    };
    const response = await axios({
      url: BASEURL + "order/pending/variant/list_by_site",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setVarData(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    if (s_id) {
      varList(true);
    }
  }, [s_id]);

  const returnOrderDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const item = {
      order_return_date:
        selectedDate ??
        moment(
          selectedDate.day + "-" + selectedDate.month + "-" + selectedDate.year,
          "DD-MM-YYYY"
        ),
      labour_charge: poData.lRent == "" ? 0 : parseInt(poData.lRent),
      vehicle_charge: poData.vRent == "" ? 0 : parseInt(poData.vRent),
      order_location: "",
      chalan_no: poData.cNumber == "" ? undefined : parseInt(poData.cNumber),
      site_id: s_id,
      items: orderData.map((b) => ({
        product_variants_id: b.product_variants_id,
        return_quantity: b.return_qty == null ? 0 : parseInt(b.return_qty),
        damaged_cost_item: b.damage_charge ? parseFloat(b.damage_charge) : 0,
        damaged_quantity: b?.damage_qty ? parseInt(b.damage_qty) : 0,
      })),
    };
    try {
      const response = await axios({
        url: BASEURL + "order/return_by_site",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        navigate(-1);
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const returnOrderDetailCustomer = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const item = {
      order_return_date:
        selectedDate ??
        moment(
          selectedDate.day + "-" + selectedDate.month + "-" + selectedDate.year,
          "DD-MM-YYYY"
        ),
      labour_charge: poData.lRent == "" ? 0 : parseInt(poData.lRent),
      vehicle_charge: poData.vRent == "" ? 0 : parseInt(poData.vRent),
      order_location: "",
      chalan_no: poData.cNumber == "" ? undefined : parseInt(poData.cNumber),
      customer_id: state.customer_id,
      items: orderData.map((b) => ({
        product_variants_id: b.product_variants_id,
        return_quantity: b.return_qty == null ? 0 : parseInt(b.return_qty),
        damaged_cost_item: b.damage_charge ? parseFloat(b.damage_charge) : 0,
        damaged_quantity: b?.damage_qty ? parseInt(b.damage_qty) : 0,
      })),
    };
    try {
      const response = await axios({
        url: BASEURL + "order/return_by_customer",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        navigate(-1);
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const returnOrderCalculateDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const item = {
      order_return_date:
        selectedDate ??
        moment(
          selectedDate.day + "-" + selectedDate.month + "-" + selectedDate.year,
          "DD-MM-YYYY"
        ),
      labour_charge: poData.lRent == "" ? 0 : parseInt(poData.lRent),
      vehicle_charge: poData.vRent == "" ? 0 : parseInt(poData.vRent),
      order_location: "",
      chalan_no: poData.cNumber == "" ? undefined : parseInt(poData.cNumber),
      site_id: s_id,
      items: orderData.map((b) => ({
        product_variants_id: b.product_variants_id,
        return_quantity: b.return_qty == null ? 0 : parseInt(b.return_qty),
        damaged_cost_item: b.damage_charge ? parseFloat(b.damage_charge) : 0,
        damaged_quantity: b?.damage_qty ? parseInt(b.damage_qty) : 0,
      })),
    };
    try {
      const response = await axios({
        url: BASEURL + "order/return_by_site/calculate",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        setCalcReturnList(response.data);
        await setOpenCalcPop(!openCalcPop);
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const returnOrderDetailCalculateCustomer = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const item = {
      order_return_date:
        selectedDate ??
        moment(
          selectedDate.day + "-" + selectedDate.month + "-" + selectedDate.year,
          "DD-MM-YYYY"
        ),
      labour_charge: poData.lRent == "" ? 0 : parseInt(poData.lRent),
      vehicle_charge: poData.vRent == "" ? 0 : parseInt(poData.vRent),
      order_location: "",
      chalan_no: poData.cNumber == "" ? undefined : parseInt(poData.cNumber),
      customer_id: state.customer_id,
      items: orderData.map((b) => ({
        product_variants_id: b.product_variants_id,
        return_quantity: b.return_qty == null ? 0 : parseInt(b.return_qty),
        damaged_cost_item: b.damage_charge ? parseFloat(b.damage_charge) : 0,

        damaged_quantity: b?.damage_qty ? parseInt(b.damage_qty) : 0,
      })),
    };
    try {
      const response = await axios({
        url: BASEURL + "order/return_by_customer/calculate",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        setCalcReturnList(response.data);
        await setOpenCalcPop(!openCalcPop);
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const placeOrderList = async (
    showSkeleton = false,
    size = siteSize,
    page = siteSelectedPage
  ) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const item = {
      site_id: s_id,
      size: size,
      page: page?.selected ?? 1,
      sort_by: sortData,
      order_by: orderBy,
    };

    const response = await axios({
      url: BASEURL + "order/return/list_by_site",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setPlaceOrder(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    placeOrderList(true);
  }, [s_id, sortData, orderBy]);

  const returnOrderCustomerList = async (
    showSkeleton = false,
    size = customerSize,
    page = customerSelectedPage
  ) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const item = {
      customer_id: state.customer_id,
      size: size,
      page: page?.selected ?? 1,
      sort_by: sortData,
      order_by: orderBy,
    };

    const response = await axios({
      url: BASEURL + "order/return/list_by_customer",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setReturnOrder(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    returnOrderCustomerList();
  }, [orderBy, sortData]);

  const pendingItemCustomerList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const item = {
      customer_id: state.customer_id,
    };

    const response = await axios({
      url: BASEURL + "order/pending/variant/master_by_customer",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setP_I_Customer(response.data);
    showSkeleton && setIsLoading(false);
  };

  const pendingItemSiteList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const item = {
      site_id: s_id,
    };

    const response = await axios({
      url: BASEURL + "order/pending/variant/master_by_site",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: item,
    });
    setP_I_Site(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    pendingItemCustomerList(true);
  }, []);

  useEffect(() => {
    if (s_id) {
      pendingItemSiteList(true);
    }
  }, []);

  const onShortData = (sortItem) => {
    if (sortItem !== sortData) {
      setSortData(sortItem);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  return (
    <div>
      <div className="cl-content-scroll">
        <div class="cl-content-card card">
          <ROContentTop
            siteList={siteList}
            varData={varData}
            poData={poData}
            setPoData={setPodata}
            orderData={orderData}
            setOrderData={setOrderData}
            returnOrderDetail={returnOrderDetail}
            returnOrderDetailCustomer={returnOrderDetailCustomer}
            arr={arr}
            setArr={setArr}
            setIsChecked={setIsChecked}
            setS_id={setS_id}
            s_id={s_id}
            isChecked={isChecked}
            placeOrderList={placeOrderList}
            customer_name={state.customer_name}
            customer_full_name={state.customer_full_name}
            customerPList={customerPList}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            returnOrderData={returnOrderData}
            setReturnOrderData={setReturnOrderData}
            returnOrderCalculateDetail={returnOrderCalculateDetail}
            returnOrderCalculateDetailCustomer={
              returnOrderDetailCalculateCustomer
            }
            calcReturnList={calcReturnList}
            openCalcPop={openCalcPop}
            setOpenCalcPop={setOpenCalcPop}
            isLoading={isLoading}
            customer_phone={state.customer_phone}
          />
        </div>
        <div class="cl-content-card card">
          <ROContentMiddle
            s_id={s_id}
            p_I_Customer={p_I_Customer}
            p_I_Site={p_I_Site}
            customer_name={state.customer_name}
            customer_phone={state.customer_phone}
          />
        </div>
        <div class="cl-content-card card">
          <ROContentBottam
            setSiteSize={(value) => {
              setSiteSize(value);
              setSiteSelectedPage(1);
            }}
            setCustomerSize={(value) => {
              setCustomerSize(value);
              setCustomerSelectedPage(1);
            }}
            siteSize={siteSize}
            siteSelectedPage={siteSelectedPage}
            setSiteSelectedPage={(value) => {
              setSiteSelectedPage(value);
              placeOrderList(true, siteSize, value);
            }}
            customerSize={customerSize}
            customerSelectedPage={customerSelectedPage}
            setCustomerSelectedPage={(value) => {
              setCustomerSelectedPage(value);
              returnOrderCustomerList(true, customerSize, value);
            }}
            placeOrder={placeOrder}
            returnOrder={returnOrder}
            s_id={s_id}
            isLoading={isLoading}
            customer_name={state.customer_name}
            customer_phone={state.customer_phone}
            onShortData={onShortData}
          />
        </div>
      </div>
    </div>
  );
};

export default ROContent;
