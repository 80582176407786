import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Pagination from "../Pagination/Pagination";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import LotiFiles from "../Lotifiles/LotiFiles";
import moment from "moment";
import SortIcon from "../AcInputText/SortIcon";
import { DeleteIcon, RedCrossIcon } from "../../assets";

const PaymentMiddle = ({
  customer_payment_list,
  isLoading,
  selectedPage,
  setSelectedPage,
  setSize,
  onShortData,
  onDelete,
}) => {
  return (
    <div>
      <div className="payment-middle">
        <span className="payment-top-left-title">CUSTOMER PAYMENT SUMMERY</span>
        <span className="payment-top-left-desc">
          All Payment List Of Customer
        </span>
        {customer_payment_list?.list?.length > 0 ? (
          <span className="content-top-left-dropdown">
            Show &nbsp;
            <select
              id="number-dd"
              name="number"
              onChange={(e) => setSize(e.target.value)}
            >
              {[10, 25, 50, 75, 100, 125, 150, 175, 200].map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            &nbsp; Entries
          </span>
        ) : null}
      </div>
      <div className="cat-content-middle">
        {isLoading ? (
          <TableLoader />
        ) : (
          <div>
            {customer_payment_list?.list?.length > 0 ? (
              <Table>
                <Thead>
                  <Tr>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Customer Name
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("customer_name")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Payment Date
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("payment_date")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Total Amount
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("total_amount")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Paid Amount
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("paid_amount")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Discount Amount
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("discount_amount")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Deposite Amount
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("deposit_amount")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Return Deposite Amount
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("return_deposit_amount")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">
                      <div className="sort-main">
                        <div className="content-middle-title-tr-sort-name">
                          Pending Amount
                        </div>
                        <div>
                          <SortIcon
                            onClick={() => onShortData("pending_amount")}
                          />
                        </div>
                      </div>
                    </Th>
                    <Th className="content-middle-title-tr">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {customer_payment_list &&
                  customer_payment_list?.list?.length > 0
                    ? customer_payment_list?.list?.map((item, index) => {
                        return (
                          <Tr>
                            <Td className="cat-content-middle-name-data ">
                              {item?.customer_name?.trim() !== ""
                                ? item.customer_name
                                : "-"}
                            </Td>
                            <Td className="cat-content-middle-active-data">
                              {item?.payment_date?.trim() !== ""
                                ? moment(item.payment_date).format("DD-MM-YYYY")
                                : "-"}
                            </Td>
                            <Td className="cat-content-middle-action-data">
                              {item?.total_amount !== ""
                                ? item.total_amount
                                : "-"}
                            </Td>
                            <Td className="cat-content-middle-active-data">
                              {item?.paid_amount !== ""
                                ? item.paid_amount
                                : "-"}
                            </Td>
                            <Td className="cat-content-middle-active-data">
                              {item?.discount_amount !== ""
                                ? item.discount_amount
                                : "-"}
                            </Td>
                            <Td className="cat-content-middle-active-data">
                              {item?.deposit_amount !== ""
                                ? item.deposit_amount
                                : "-"}
                            </Td>
                            <Td className="cat-content-middle-active-data">
                              {item?.return_deposit_amount !== ""
                                ? item.return_deposit_amount
                                : "-"}
                            </Td>
                            <Td className="cat-content-middle-action-data">
                              {item?.pending_amount !== ""
                                ? item.pending_amount
                                : "-"}
                            </Td>
                            <Td className="content-middle-action-data">
                              <div className="content-middle-action-data-div">
                                <img
                                  className="content-middle-action-data-div-delete"
                                  src={DeleteIcon}
                                  onClick={() => onDelete(item)}
                                />
                              </div>
                            </Td>
                          </Tr>
                        );
                      })
                    : ""}
                </Tbody>
              </Table>
            ) : (
              <LotiFiles message={"No Data Found For Payment!"} />
            )}
          </div>
        )}
      </div>
      {customer_payment_list?.list?.length > 0 ? (
        <div className="content-bottam">
          <Pagination
            className="pagination-bar"
            selectedPage={selectedPage}
            totalCount={customer_payment_list?.total_pages ?? 1}
            onPageChange={(page) => setSelectedPage(page)}
            itemsPerPage={4}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PaymentMiddle;
