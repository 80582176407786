import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import SelectInputText from "../InputText/SelectInputText";

const SearchContentTop = ({
  ispd_Id,
  setpd_IsId,
  isv_Id,
  setv_IsId,
  productDropDown,
  varientDropDown,
}) => {
  return (
    <div>
      <div className="ex-content-top">
        <span className="ex-content-top-title">SEARCH DETAILS</span>
        <div
          className="addProduct-second-firstRow"
          style={{ marginTop: "10px" }}
        >
          <SelectInputText
            label="Product Name"
            setpd_IsId={setpd_IsId}
            onChange={(e) => {
              setpd_IsId(e.target.value);
            }}
            data={productDropDown?.list}
            value={ispd_Id}
          />
          <SelectInputText
            label="Variant"
            setv_IsId={setv_IsId}
            onChange={(e) => {
              setv_IsId(e.target.value);
            }}
            data={varientDropDown?.list}
            value={isv_Id}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchContentTop;
