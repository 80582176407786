import React from "react";
import { useNavigate } from "react-router-dom";
import { HomeIcon } from "../../assets";
const PaymentNavigation = ({ state }) => {
  const navigate = useNavigate();

  return (
    <div className="content-nav">
      {state == null ? (
        <div>
          <img src={HomeIcon} />
          <span className="content-nav-home">Home</span>
          <i class="bx bx-chevron-right"></i>
          <span className="content-nav-home">Payment</span>
        </div>
      ) : (
        <div>
          <img src={HomeIcon} />
          <span className="content-nav-home">Home</span>
          <i class="bx bx-chevron-right"></i>
          <span
            className="content-nav-home"
            onClick={() => navigate("/customer")}
          >
            Customer
          </span>
          <i class="bx bx-chevron-right"></i>
          <span className="content-nav-home" onClick={() => navigate(-1)}>
            Customer-Details
          </span>
          <i class="bx bx-chevron-right"></i>
          <span className="content-nav-home">Payment</span>
        </div>
      )}
    </div>
  );
};

export default PaymentNavigation;
