import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
  return <text x={x} y={y} fill="#000" textAnchor="middle" dy={-6} />;
};

function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

const RenderBarChart = ({ expenseData }) => {
  const data = expenseData?.result;

  return (
    <div>
      <div className="barchart">
        <ResponsiveContainer width={700} height={360}>
          <BarChart data={data}>
            <XAxis dataKey="name" />
            <YAxis tickFormatter={(value) => kFormatter(value)} />
            <Tooltip cursor={{ fill: "transparent" }} />
            <Bar
              dataKey="Expense"
              barSize={22}
              fill="#F6C77D"
              label={renderCustomBarLabel}
              radius={[22, 22, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default RenderBarChart;
