import React from "react";
import "./Popup.css";

export const AddCustomer = (props) => {
  return (
    <div className="addCustomer-box-main">
      <div className="addCustomer-box">
        <h1>{props.content}</h1>
      </div>
    </div>
  );
};

export const AddUpdateCustomer = (props) => {
  return (
    <div className="addCustomer-box-main">
      <div className="addCustomer-box">
        <h1>{props.updateContent}</h1>
      </div>
    </div>
  );
};

export const DeletePopup = (props) => {
  return (
    <div className="deleteCustomer-box-main">
      <div className="deleteCustomer-box">
        <h1>{props.deleteContent}</h1>
      </div>
    </div>
  );
};

export const OrderSummery = (props) => {
  return (
    <div className="summery-box-main">
      <div className="summery-box">
        <h1>{props.summeryContent}</h1>
      </div>
    </div>
  );
};

export const ReturnSummery = (props) => {
  return (
    <div className="summery-box-main">
      <div className="summery-box">
        <h1>{props.summeryContent}</h1>
      </div>
    </div>
  );
};

export const AddSitePop = (props) => {
  return (
    <div className="addSite-box-main">
      <div className="addSite-box">
        <h1>{props.content}</h1>
      </div>
    </div>
  );
};

export const AddCategory = (props) => {
  return (
    <div className="addCat-box-main">
      <div className="addCat-box">
        <h1>{props.content}</h1>
      </div>
    </div>
  );
};

export const AddSize = (props) => {
  return (
    <div className="addSize-box-main">
      <div className="addSize-box">
        <h1>{props.content}</h1>
      </div>
    </div>
  );
};

export const AddProduct = (props) => {
  return (
    <div className="addProduct-box-main">
      <div className="addProduct-box">
        <h1>{props.content}</h1>
      </div>
    </div>
  );
};

export const OpenProfile = (props) => {
  return (
    <div className="profile-box-main">
      <div className="profile-box">
        <h1>{props.content}</h1>
      </div>
    </div>
  );
};
