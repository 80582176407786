import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import LotiFiles from "../Lotifiles/LotiFiles";
import { useNavigate } from "react-router-dom";

const TransactionYear = ({ year_payment_list, isLoading }) => {
  const navigate = useNavigate();

  let total_paid_amount = 0;
  let total_expenses_amount = 0;

  for (let i = 0; i < year_payment_list?.list?.length; i++) {
    total_paid_amount += year_payment_list?.list[i]?.paid_amount;
  }
  for (let i = 0; i < year_payment_list?.list?.length; i++) {
    total_expenses_amount += year_payment_list?.list[i]?.expenses_amount;
  }
  return (
    <div>
      <div className="payment-middle">
        <span className="payment-top-left-title">YEARLY TRANSACTION</span>
      </div>
      <div>
        {isLoading ? (
          <TableLoader />
        ) : (
          <div className="cat-content-middle">
            {year_payment_list?.list?.length > 0 ? (
              <Table className="content-middle-main">
                <Thead>
                  <Tr>
                    <Th className="content-middle-title-tr">Years</Th>
                    <Th className="content-middle-title-tr">Income</Th>
                    <Th className="content-middle-title-tr">Expense</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {year_payment_list && year_payment_list?.list?.length > 0
                    ? year_payment_list?.list?.map((item, index) => {
                        return (
                          <Tr>
                            <Td
                              className="cat-content-middle-name-data customer-name"
                              onClick={() => {
                                navigate(
                                  "/transaction-year/transaction-month",
                                  {
                                    state: {
                                      year: item.year,
                                    },
                                  }
                                );
                              }}
                            >
                              {item?.year !== "" ? item.year : "-"}
                            </Td>
                            <Td
                              className="cat-content-middle-active-data"
                              style={{ color: "#00D100", fontWeight: "bold" }}
                            >
                              {item?.paid_amount !== ""
                                ? item?.paid_amount?.toFixed(2)
                                : "-"}
                            </Td>
                            <Td
                              className="cat-content-middle-action-data"
                              style={{ color: "#D10000", fontWeight: "bold" }}
                            >
                              {item?.expenses_amount !== ""
                                ? item.expenses_amount?.toFixed(2)
                                : "-"}
                            </Td>
                          </Tr>
                        );
                      })
                    : ""}
                  <Tr
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#FFD68A",
                      marginTop: "10px",
                    }}
                  >
                    <Td
                      className="cat-content-middle-name-data"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      TOTAL
                    </Td>
                    <Td
                      className="cat-content-middle-name-data"
                      style={{
                        color: "#00D100",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      {total_paid_amount?.toFixed(2)}
                    </Td>
                    <Td
                      className="cat-content-middle-name-data"
                      style={{
                        color: "#D10000",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      {total_expenses_amount?.toFixed(2)}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            ) : (
              <LotiFiles message={"No Data Found For Transaction!"} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionYear;
