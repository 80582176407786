import moment from "moment";
import React, { useRef } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import ReactToPrint from "react-to-print";
import LotiFiles from "../Lotifiles/LotiFiles";
import { AppColors } from "../colors";
import { SahjanandLogoIcon } from "../../assets";

const ROContentMiddle = ({
  s_id,
  p_I_Customer,
  p_I_Site,
  customer_name,
  customer_phone,
}) => {
  const componentRef = useRef();

  const printDocument = () => {
    window.print();
  };

  return (
    <div>
      <div
        style={{ marginLeft: "20px", display: "flex", flexDirection: "row" }}
      >
        <span className="payment-top-left-title">PENDING ITEM DETAILS</span>
      </div>
      <div>
        <div
          className="content-top-right"
          style={{ float: "right", marginTop: "-30px" }}
        >
          <ReactToPrint
            trigger={() => (
              <button
                className="cl-summary-btn"
                style={{ backgroundColor: AppColors.red }}
                onClick={printDocument}
              >
                Print
              </button>
            )}
            content={() => componentRef.current}
          />
        </div>
      </div>
      <div ref={componentRef}>
        <div className="col-md-12 invoice-date-name">
          <div className="col-md-6">
            <span>{moment().format("DD-MM-YYYY, h:mm")}</span>
          </div>
          <div className="col-md-6">
            <span>Sahjanand Construction</span>
          </div>
        </div>
        <div className="invoice-main">
          <div className="col-md-12 invoice-part-first">
            <div className="col-md-4 invoice-part-first-left">
              <span className="invoice-part-first-text">Gopal Patel</span>
            </div>
            <div className="col-md-4 invoice-part-first-middle">
              <img
                className="invoice-part-first-middle-logo"
                src={SahjanandLogoIcon}
              />
              <span className="invoice-part-first-middle-name">
                સહજાનંદ કન્ટ્રકશન
              </span>
            </div>
            <div className="col-md-4 invoice-part-first-last">
              <span className="invoice-part-first-text">9173735497</span>
            </div>
          </div>
          <div>
            <div className="col-md-12 invoice-part-second">
              <div className="col-md-12 invoice-part-second-one">
                <span className="invoice-part-second-one-text">
                  Pending Item Details
                </span>
              </div>
              <div className="col-md-12 invoice-part-second-two">
                <div className="col-md-6 invoice-part-second-two-1">
                  <span>Date :</span>
                  <span> {moment().format("DD-MM-YYYY")}</span>
                </div>
                <div className="col-md-6">
                  <span>Mobile : </span>
                  <span>{customer_phone}</span>
                </div>
              </div>
              <div className="col-md-12 invoice-part-second-three">
                <div className="col-md-6 invoice-part-second-two-1">
                  <span>Name :</span>
                  <span>{customer_name}</span>
                </div>
                <div className="col-md-6">
                  <span>Returned By :</span>
                  <span>{customer_name}</span>
                </div>
              </div>
            </div>
            <div className="col-md-12 invoice-part-second">
              <div>
                {s_id ? (
                  <div>
                    {p_I_Site?.list?.length > 0 ? (
                      <div className="col-md-12 invoice-part-second">
                        <div>
                          <div className="ro-in">
                            <Table className=" ro-in-table">
                              <Thead>
                                <Tr>
                                  <Th className="content-middle-title-tr ro-in-content-middle-name">
                                    Item Name
                                  </Th>
                                  <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                                    Order Quantity
                                  </Th>
                                  <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                                    Pending Quantity
                                  </Th>
                                </Tr>
                              </Thead>
                              {p_I_Site?.list?.length > 0
                                ? p_I_Site?.list?.map((item, index) => (
                                    <Tbody>
                                      <Tr>
                                        <Td className="ro-in-content-middle-name-data">
                                          {item.item_name}
                                        </Td>
                                        <Td className="ro-in-content-middle-r-qty-data">
                                          {item.ordered_quantity}
                                        </Td>
                                        <Td className="ro-in-content-middle-r-qty-data">
                                          {item.pending_quantity}
                                        </Td>
                                      </Tr>
                                    </Tbody>
                                  ))
                                : null}
                            </Table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <LotiFiles message={"No Pending Items!"} />
                    )}
                  </div>
                ) : (
                  <div>
                    {p_I_Customer?.list?.length > 0 ? (
                      <div className="col-md-12 invoice-part-second">
                        <div>
                          <div className="ro-in">
                            <Table className=" ro-in-table">
                              <Thead>
                                <Tr>
                                  <Th className="content-middle-title-tr ro-in-content-middle-name">
                                    Item Name
                                  </Th>
                                  <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                                    Order Quantity
                                  </Th>
                                  <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                                    Pending Quantity
                                  </Th>
                                </Tr>
                              </Thead>
                              {p_I_Customer?.list?.length > 0
                                ? p_I_Customer?.list?.map((item, index) => (
                                    <Tbody>
                                      <Tr key={index}>
                                        <Td className="ro-in-content-middle-name-data">
                                          {item.item_name}
                                        </Td>
                                        <Td className="ro-in-content-middle-r-qty-data">
                                          {item.ordered_quantity}
                                        </Td>
                                        <Td className="ro-in-content-middle-r-qty-data">
                                          {item.pending_quantity}
                                        </Td>
                                      </Tr>
                                    </Tbody>
                                  ))
                                : null}
                            </Table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <LotiFiles message={"No Pending Items"} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-med-12 invoice-part-three">
            <div className="col-md-4 invoice-part-three-1">
              <span>Signature of Customer</span>
            </div>
            <div className="col-md-4 invoice-part-three-2">
              <span>Thank You</span>
            </div>
            <div className="col-md-4 invoice-part-three-3">
              <span>Signature of Receiver</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ROContentMiddle;
