import React from "react";
import AcInputText from "../AcInputText/AcInputText";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AlertType, alertService } from "../../utils/alert.service";
import SelectInputText from "../InputText/SelectInputText";
import { CalenderIcon } from "../../assets";

const ExpenseContentTop = ({
  expense,
  setExpense,
  selectDate,
  setSelectDate,
  calendarIsOpen,
  setCalendarIsOpen,
  addExpensesDetail,
  setExinType,
}) => {
  const letsToastDate = () => {
    alertService.alert({
      type: AlertType.Error,
      message: "Please Select Date",
    });
  };

  const letsToastName = () => {
    alertService.alert({
      type: AlertType.Error,
      message: "Please Add Expense Name",
    });
  };

  const letsToastAmount = () => {
    alertService.alert({
      type: AlertType.Error,
      message: "Please Add Expense Amount ",
    });
  };

  const expenseSubmitHandler = () => {
    if (selectDate == "") {
      letsToastDate();
    } else if (expense.expenseName == "") {
      letsToastName();
    } else if (expense.expenseAmount == "") {
      letsToastAmount();
    } else {
      addExpensesDetail();
    }
  };

  const expenseCancelHandler = () => {
    setExpense({
      expenseName: "",
      expenseAmount: "",
      expenseDesc: "",
    });
    setSelectDate(new Date());
  };

  return (
    <div>
      <div className="ex-content-top">
        <span className="ex-content-top-title">EXPENSE / INCOME</span>
        <div className="ex-content-top-input">
          <div
            className="po-accordion-date"
            style={{ marginLeft: "20px", marginRight: "10px" }}
          >
            <label className="ac-inputText-label" htmlFor={" Date"}>
              Date
            </label>
            <div className="ac-inputText-date-container">
              <DatePicker
                className="ac-inputText-input-date"
                onChange={(value) => {
                  setSelectDate(value);
                }}
                selected={selectDate}
                name="startDate"
                dateFormat="dd-MM-yyyy"
              />
              <img src={CalenderIcon} />
            </div>
          </div>
          <div className="ac-inputText-input-wrapper">
            <label className="ac-inputText-label" htmlFor={"Expense/Income"}>
              Expense / Income
            </label>
            <select
              className="ac-inputText-input-container"
              onChange={(e) => setExinType(e.target.value)}
            >
              {["Expense", "Income"].map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div>
          <AcInputText
            label={"Name"}
            type="text"
            placeholder="Name"
            value={expense.expenseName}
            onChange={(e) =>
              setExpense((prevValue) => ({
                ...prevValue,
                expenseName: e.target.value,
              }))
            }
          />
          <AcInputText
            label={"Description"}
            type="text"
            placeholder="Description"
            value={expense.expenseDesc}
            onChange={(e) =>
              setExpense((prevValue) => ({
                ...prevValue,
                expenseDesc: e.target.value,
              }))
            }
          />
          <AcInputText
            label={"Amount"}
            type="text"
            placeholder="Amount"
            value={expense.expenseAmount}
            onChange={(e) =>
              setExpense((prevValue) => ({
                ...prevValue,
                expenseAmount: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <div className="payment-btn">
            <button className="payment-btn-sub" onClick={expenseSubmitHandler}>
              Submit
            </button>
            <button className="payment-btn-can" onClick={expenseCancelHandler}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseContentTop;
