import { color } from "d3-color";
import React from "react";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
  AreaChart,
} from "recharts";

const SplineChart = ({ revenueData }) => {
  const data = revenueData.result;
  return (
    <ResponsiveContainer width={"98%"} height={300}>
      <AreaChart
        data={data}
        margin={{ top: 20, right: 10, left: 30, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#167a9354" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#ffffff00" stopOpacity={0.0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis dataKey="Revenue" />
        <CartesianGrid strokeDashArray="5 5" stroke="none" />
        <Tooltip
          wrapperStyle={{ backgroundColor: "red" }}
          labelStyle={{ color: "black" }}
          itemStyle={{ color: "black" }}
        />
        <Area
          type="monotone"
          dataKey="Revenue"
          stroke="#167a93"
          fill="url(#colorValue)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default SplineChart;
