import axios from "axios";
import { useState } from "react";
import LoadingSpin from "react-loading-spin";
import { useNavigate } from "react-router-dom";
import InputText from "../../components/InputText/InputText";
import { BASEURL } from "../../constant/constant";
import { ValidateId } from "../../helpers/validators";
import { AlertType, alertService } from "../../utils/alert.service";
import "./AdminLogin.css";
import { ColoredUserIcon, SahjanandLogoIcon } from "../../assets";

const ForgotPassword = () => {
  const initialValues = { id: "" };
  const [data, setData] = useState(initialValues);
  const [errors, setErrors] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const forgotPasswordApi = async () => {
    const item = { user_name: data.id };
    try {
      setIsLoading(true);
      const response = await axios({
        url: BASEURL + "user/forgot_password",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: item,
      });
      if (response.status === 200) {
        setIsLoading(false);
        navigate("/forgot-password/otp", {
          state: {
            user_email: response.data.email,
          },
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const validator = (formData = data) => {
    let newErrors = {};
    let isValid = true;
    const statusId = ValidateId(formData.id);

    if (statusId) {
      newErrors.id = statusId === 1 ? "User is Required" : "";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validator()) {
      forgotPasswordApi();
    }
  };

  return (
    <div>
      <section className="login-main-container">
        <div className="card">
          <img className="al-logo" src={SahjanandLogoIcon} />
          <h2 className="al-title">Forgot Password</h2>
          <form onSubmit={handleSubmit}>
            <InputText
              label="User"
              type="text"
              placeholder="User"
              src={ColoredUserIcon}
              value={data.id}
              onChange={(e) =>
                setData((prevValue) => ({
                  ...prevValue,
                  id: e.target.value,
                }))
              }
              error={errors?.id}
            />
            <div className="button-container">
              <button className="button" disabled={isLoading}>
                {isLoading ? (
                  <LoadingSpin
                    primaryColor="white"
                    size="30px"
                    animationDuration="1s"
                  />
                ) : (
                  "NEXT"
                )}
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ForgotPassword;
