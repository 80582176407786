import moment from "moment";
import React, { useRef, useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ReactToPrint from "react-to-print";
import Checkbox from "../AcInputText/Checkbox";
import SortIcon from "../AcInputText/SortIcon";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import LotiFiles from "../Lotifiles/LotiFiles";
import Pagination from "../Pagination/Pagination";
import { ReturnSummery } from "../Popup/Popup";
import ReturnOrderInvoice from "../ReturnOrderInvoice";
import { AppColors } from "../colors";
import RIAccordion from "./../CustomerList-comp/RIAccordion";
import ClAccordian from "./CLAccordian";
import {
  DeleteIcon,
  EyeIcon,
  StatusDotIcon,
  StatusDotOnGoingIcon,
} from "../../assets";

const ClContentMiddle = ({
  selectedPage,
  setSelectedPage,
  data,
  onEdit,
  onDelete,
  addSite,
  onSummery,
  onEditSite,
  state,
  placeOrder,
  setP_s_id,
  customerList,
  onCLickAccordion,
  isLoading,
  p_s_id,
  customer_name,
  customer_phone,
  toggleLoading,
  setToggleLoading,
  customerSelectedPage,
  setCustomerSelectedPage,
  isAccordionLoading,
  setCustomerSize,
  r_o_Customer,
  r_o_Site,
  setr_CustomerSelectedPage,
  setr_CustomerSize,
  setr_SiteSelectedPage,
  r_SiteSelectedPage,
  r_CustomerSelectedPage,
  onShortOrderCustomerData,
  onShortOrderSiteData,
  onShortReturnCustomerData,
  onShortReturnSiteData,
  handlePlaceCheckbox,
  setPlaceArr,
  onDeletePlaceOrder,
  onDeleteReturnOrder,
  show_site_payment,
}) => {
  const [summeryOpen, setSummeryOpen] = useState(false);
  const [summeryItem, setSummeryItem] = useState("");
  const [openInvoice, setOpenInvoice] = useState(false);
  const [arr, setArr] = useState([]);
  const [isChecked, setIsChecked] = useState("");

  const toggleSummeryPopup = (mapItem) => {
    setSummeryItem(mapItem);
    setSummeryOpen(!summeryOpen);
    setOpenInvoice(!openInvoice);
  };

  const componentRef = useRef();

  const printDocument = () => {
    window.print();
  };

  let total_damage_qty = 0;
  let total_ext_days = 0;
  let total_ext_days_rent = 0;
  let total_payable_payment = 0;
  let total_remain_payment = 0;
  let total_charges = summeryItem.labour_charge + summeryItem.vehicle_charge;

  for (let i = 0; i < summeryItem?.return_order_details?.length; i++) {
    total_ext_days += summeryItem?.return_order_details[i]?.total_rent;
    total_damage_qty +=
      summeryItem?.return_order_details[i]?.total_damaged_cost;
    total_ext_days_rent +=
      summeryItem?.return_order_details[i]?.total_extra_days_rent;
    total_payable_payment +=
      summeryItem?.return_order_details[i]?.payable_amount;
    total_remain_payment +=
      summeryItem?.return_order_details[i]?.remaining_payment;
  }

  const handleCheckbox = (e, mapItem) => {
    const { value, checked } = e.target;
    if (checked == true) {
      setArr((prevValue) => [...prevValue, mapItem]);
    } else {
      setArr(arr.filter((item) => item !== mapItem));
    }
    setIsChecked(checked);
  };

  return (
    <div>
      <RIAccordion
        product_name="Order Details (Master)"
        content={
          <div>
            <div>
              <div className="cl-content-top-left">
                <span
                  className="cl-accordion-table-title"
                  style={{ marginTop: "10px", marginLeft: "0px" }}
                >
                  Place Order Details (Master)
                </span>
                {customerList?.list?.length > 0 ? (
                  <span className="cl-content-top-left-dropdown">
                    Show&nbsp;
                    <select
                      id="number-dd"
                      name="number"
                      onChange={(e) => setCustomerSize(e.target.value)}
                    >
                      {[10, 25, 50, 75, 100, 125, 150, 175, 200].map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    &nbsp; Entries
                  </span>
                ) : (
                  ""
                )}
              </div>
              {isLoading ? (
                <TableLoader />
              ) : (
                <div>
                  {customerList?.list?.length > 0 ? (
                    <div className="content-middle">
                      <Table className="content-middle-main">
                        <Thead>
                          <Tr>
                            <Th className="cl-content-middle-title-tr">
                              <div className="sort-main">
                                <div className="content-middle-title-tr-sort-name">
                                  Chalan No.
                                </div>
                                <div>
                                  <SortIcon
                                    onClick={() =>
                                      onShortOrderCustomerData("chalan_no")
                                    }
                                  />
                                </div>
                              </div>
                            </Th>
                            <Th className="cl-content-middle-title-tr">
                              <div className="sort-main">
                                <div className="content-middle-title-tr-sort-name">
                                  Order Status
                                </div>
                                <div>
                                  <SortIcon
                                    onClick={() =>
                                      onShortOrderCustomerData("order_status")
                                    }
                                  />
                                </div>
                              </div>
                            </Th>
                            <Th className="cl-content-middle-title-tr">
                              Site Name
                            </Th>
                            <Th className="cl-content-middle-title-tr">
                              <div className="sort-main">
                                <div className="content-middle-title-tr-sort-name">
                                  Order Date
                                </div>
                                <div>
                                  <SortIcon
                                    onClick={() =>
                                      onShortOrderCustomerData("order_date")
                                    }
                                  />
                                </div>
                              </div>
                            </Th>
                            <Th className="cl-content-middle-title-tr">
                              <div className="sort-main">
                                <div className="content-middle-title-tr-sort-name">
                                  Return Date
                                </div>
                                <div>
                                  <SortIcon
                                    onClick={() =>
                                      onShortOrderCustomerData(
                                        "expected_return_date"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Th>
                            <Th className="cl-content-middle-title-tr">
                              <div className="sort-main">
                                <div className="content-middle-title-tr-sort-name">
                                  Location
                                </div>
                                <div>
                                  <SortIcon
                                    onClick={() =>
                                      onShortOrderCustomerData("order_location")
                                    }
                                  />
                                </div>
                              </div>
                            </Th>
                            <Th className="cl-content-middle-title-tr">
                              For Days
                            </Th>
                            <Th className="cl-content-middle-title-tr">
                              Action
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {customerList?.list && customerList.list.length > 0
                            ? customerList.list.map((item, index) => {
                                return (
                                  <Tr key={index}>
                                    <div className="div-square-check">
                                      <Checkbox
                                        value={item.order_id}
                                        onChange={(e) =>
                                          handlePlaceCheckbox(e, item)
                                        }
                                      />
                                      <div className="cl-content-chalan-box">
                                        <Td
                                          className="cat-content-middle-name-data customer-name"
                                          onClick={() => onSummery(item)}
                                        >
                                          {item?.chalan_no !== null
                                            ? item.chalan_no
                                            : "-"}
                                        </Td>
                                      </div>
                                    </div>
                                    <Td className="cl-content-middle-email-data">
                                      {item.order_status == "Completed" ? (
                                        <div className="cl-content-middle-completed">
                                          <span>
                                            <img src={StatusDotIcon} />{" "}
                                            Completed
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="cl-content-middle-onGoing">
                                          <span>
                                            <img src={StatusDotOnGoingIcon} />{" "}
                                            On Going
                                          </span>
                                        </div>
                                      )}
                                    </Td>
                                    <Td className="cl-content-middle-email-data">
                                      {item?.site_name?.trim() !== ""
                                        ? item.site_name
                                        : "-"}
                                    </Td>
                                    <Td className="cl-content-middle-name-data">
                                      {item?.order_date?.trim() !== ""
                                        ? moment(item.order_date).format(
                                            "DD-MM-YYYY"
                                          )
                                        : "-"}
                                    </Td>
                                    <Td className="cl-content-middle-email-data">
                                      {item?.expected_return_date?.trim() !== ""
                                        ? moment(
                                            item.expected_return_date
                                          ).format("DD-MM-YYYY")
                                        : "-"}
                                    </Td>
                                    <Td className="cl-content-middle-name-data">
                                      {item?.order_location?.trim() !== ""
                                        ? item.order_location
                                        : "-"}
                                    </Td>
                                    <Td className="cl-content-middle-email-data">
                                      15 DAYS
                                    </Td>
                                    <Td className="cl-content-middle-name-data">
                                      <img
                                        className="cl-content-middle-action-data-div-delete"
                                        src={EyeIcon}
                                        onClick={() => onSummery(item)}
                                      />
                                      <img
                                        className="content-middle-action-data-div-delete"
                                        src={DeleteIcon}
                                        onClick={() => onDeletePlaceOrder(item)}
                                      />
                                    </Td>
                                  </Tr>
                                );
                              })
                            : null}
                        </Tbody>
                      </Table>
                    </div>
                  ) : (
                    <LotiFiles message={"No Data Found For Place Order!"} />
                  )}
                </div>
              )}
              {customerList?.list?.length > 0 ? (
                <div className="content-bottam">
                  <Pagination
                    selectedPage={customerSelectedPage}
                    totalCount={customerList?.total_pages ?? 1}
                    onPageChange={(page) => setCustomerSelectedPage(page)}
                    itemsPerPage={4}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="addCustomer-second-hr">
                <hr />
              </div>
              <div className="cl-content-top-left">
                <span
                  className="cl-accordion-table-title"
                  style={{ marginTop: "10px", marginLeft: "0px" }}
                >
                  Return Order Details (Master)
                </span>
                {r_o_Customer?.list?.length > 0 ? (
                  <span className="cl-content-top-left-dropdown">
                    Show&nbsp;
                    <select
                      id="number-dd"
                      name="number"
                      onChange={(e) => setr_CustomerSize(e.target.value)}
                    >
                      {[10, 25, 50, 75, 100, 125, 150, 175, 200].map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    &nbsp; Entries
                  </span>
                ) : (
                  ""
                )}
                {r_o_Customer?.list?.length > 0 ? (
                  <div className="cat-content-middle">
                    <Table className="content-middle-main">
                      <Thead>
                        <Tr>
                          <Th className="content-middle-title-tr">
                            <div className="sort-main">
                              <div className="content-middle-title-tr-sort-name">
                                Chalan No.
                              </div>
                              <div>
                                <SortIcon
                                  onClick={() =>
                                    onShortReturnCustomerData("chalan_no")
                                  }
                                />
                              </div>
                            </div>
                          </Th>
                          <Th className="content-middle-title-tr">Site Name</Th>
                          <Th className="content-middle-title-tr">
                            <div className="sort-main">
                              <div className="content-middle-title-tr-sort-name">
                                Return Order Date
                              </div>
                              <div>
                                <SortIcon
                                  onClick={() =>
                                    onShortReturnCustomerData(
                                      "order_return_date"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </Th>
                          <Th className="content-middle-title-tr">
                            <div className="sort-main">
                              <div className="content-middle-title-tr-sort-name">
                                Labour Charge
                              </div>
                              <div>
                                <SortIcon
                                  onClick={() =>
                                    onShortReturnCustomerData("labour_charge")
                                  }
                                />
                              </div>
                            </div>
                          </Th>
                          <Th className="content-middle-title-tr ">
                            <div className="sort-main">
                              <div className="content-middle-title-tr-sort-name">
                                Vehicle Charge
                              </div>
                              <div>
                                <SortIcon
                                  onClick={() =>
                                    onShortReturnCustomerData("vehicle_charge")
                                  }
                                />
                              </div>
                            </div>
                          </Th>
                          <Th className="content-middle-title-tr ">
                            Total Charge
                          </Th>
                          <Th className="content-middle-title-tr">Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {r_o_Customer?.list?.length > 0
                          ? r_o_Customer?.list?.map((mapItem) => {
                              return (
                                <Tr>
                                  <div className="div-square-check">
                                    <Checkbox
                                      value={mapItem.return_order_id}
                                      onChange={(e) =>
                                        handleCheckbox(e, mapItem)
                                      }
                                    />
                                    <div className="cl-content-chalan-box">
                                      <Td
                                        className="cat-content-middle-name-data customer-name"
                                        onClick={() =>
                                          toggleSummeryPopup(mapItem)
                                        }
                                      >
                                        {mapItem?.chalan_no !== null
                                          ? mapItem.chalan_no
                                          : "-"}
                                      </Td>
                                    </div>
                                  </div>
                                  <Td className="cat-content-middle-name-data">
                                    {mapItem.site_name}
                                  </Td>
                                  <Td className="content-middle-email-data">
                                    {mapItem?.order_return_date?.trim() !== ""
                                      ? moment(
                                          mapItem.order_return_date
                                        ).format("DD-MM-YYYY")
                                      : "-"}
                                  </Td>
                                  <Td className="ro-content-middle-e-days-data">
                                    {mapItem.labour_charge}
                                  </Td>
                                  <Td className="ro-content-middle-e-days-data">
                                    {mapItem.vehicle_charge}
                                  </Td>
                                  <Td className="ro-content-middle-e-days-data">
                                    {mapItem.labour_charge +
                                      mapItem.vehicle_charge}
                                  </Td>
                                  <Td className="cat-content-middle-name-data">
                                    <div className="cl-content-middle-name-data-div">
                                      <img
                                        className="cl-content-middle-action-data-div-delete"
                                        src={EyeIcon}
                                        onClick={() =>
                                          toggleSummeryPopup(mapItem)
                                        }
                                      />
                                      <img
                                        className="content-middle-action-data-div-delete"
                                        src={DeleteIcon}
                                        onClick={() =>
                                          onDeleteReturnOrder(mapItem)
                                        }
                                      />
                                    </div>
                                  </Td>
                                </Tr>
                              );
                            })
                          : null}
                      </Tbody>
                    </Table>
                  </div>
                ) : (
                  <LotiFiles message={"No Data Found For Return Order!"} />
                )}
                {r_o_Customer?.list?.length > 0 ? (
                  <div className="content-bottam">
                    <Pagination
                      selectedPage={r_CustomerSelectedPage}
                      totalCount={r_o_Customer?.total_pages ?? 1}
                      onPageChange={(page) => setr_CustomerSelectedPage(page)}
                      itemsPerPage={4}
                    />
                  </div>
                ) : null}
                {summeryOpen && (
                  <ReturnSummery
                    summeryContent={
                      <div>
                        <div>
                          <span className="cl-summary-title">
                            RETURN ORDER DETAILS &nbsp;
                            <span style={{ color: AppColors.darkRed }}>
                              RETURN DATE : &nbsp;
                            </span>
                            {summeryItem?.order_return_date?.trim() !== ""
                              ? moment(summeryItem.order_return_date).format(
                                  "DD-MM-YYYY"
                                )
                              : "-"}
                          </span>
                          <div className="cl-summary-hr" />
                          <div
                            className="content-middle"
                            style={{ marginRight: "25px" }}
                          >
                            <Table className="content-middle-main">
                              <Thead>
                                <Tr>
                                  <Th className="content-middle-title-tr ro-content-middle-no">
                                    No
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-name">
                                    Item Name
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-name">
                                    Order Date
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-name">
                                    Return Order Date
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-name">
                                    Site Name
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-r-qty">
                                    Return Qty
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-d-qty">
                                    Unit Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-d-q-charge">
                                    Total Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-e-days">
                                    Extra Days
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-e-days">
                                    Discount Days
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-t-days">
                                    Extra Days Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-e-d-rent">
                                    Total Extra Days Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-t-rent">
                                    Damage Qty
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-t-e-d-rent">
                                    Total Damage Rent
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-o-date">
                                    Damage Qty Cost
                                  </Th>
                                  <Th className="content-middle-title-tr ro-content-middle-t-amount">
                                    Payable Amount
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {summeryItem?.return_order_details &&
                                summeryItem?.return_order_details?.length > 0
                                  ? summeryItem?.return_order_details?.map(
                                      (item, index) => {
                                        return (
                                          <Tr>
                                            <Td className="ro-content-middle-no-data">
                                              {index + 1}
                                            </Td>
                                            <Td className="ro-content-middle-name-data">
                                              {item.item_name}
                                            </Td>
                                            <Td className="ro-content-middle-name-data">
                                              {item?.order_date?.trim() !== ""
                                                ? moment(
                                                    item.order_date
                                                  ).format("DD-MM-YYYY")
                                                : "-"}
                                            </Td>
                                            <Td className="ro-content-middle-name-data">
                                              {item?.order_date?.trim() !== ""
                                                ? moment(item.order_date)
                                                    .add(14, "days")
                                                    .format("DD-MM-YYYY")
                                                : "-"}
                                            </Td>
                                            <Td className="ro-content-middle-name-data">
                                              {item.site_name}
                                            </Td>
                                            <Td className="ro-content-middle-r-qty-data">
                                              {item.return_quantity}
                                            </Td>
                                            <Td className="ro-content-middle-e-d-rent-data">
                                              {item.unit_rent.toFixed(2)}
                                            </Td>
                                            <Td className="ro-content-middle-t-e-d-rent-data">
                                              {item.total_rent.toFixed(2)}
                                            </Td>
                                            <Td className="ro-content-middle-r-date-data">
                                              {item.extra_days}
                                            </Td>
                                            <Td className="ro-content-middle-r-date-data">
                                              {item.discounted_days}
                                            </Td>
                                            <Td className="ro-content-middle-u-rent-data">
                                              {item.extra_days_rent.toFixed(2)}
                                            </Td>
                                            <Td className="ro-content-middle-e-days-data">
                                              {item.total_extra_days_rent.toFixed(
                                                2
                                              )}
                                            </Td>
                                            <Td className="ro-content-middle-t-rent-data">
                                              {item.damaged_quantity}
                                            </Td>
                                            <Td className="ro-content-middle-t-days-data">
                                              {item.total_damaged_cost.toFixed(
                                                2
                                              )}
                                            </Td>
                                            <Td className="ro-content-middle-o-date-data">
                                              {item.damaged_cost_item.toFixed(
                                                2
                                              )}
                                            </Td>
                                            <Td className="ro-content-middle-d-qty-data">
                                              {item.payable_amount.toFixed(2)}
                                            </Td>
                                          </Tr>
                                        );
                                      }
                                    )
                                  : null}
                              </Tbody>
                              <Tbody>
                                <Tr>
                                  <Td
                                    className="ro-content-middle-ext-3"
                                    colspan="7"
                                  >
                                    Total Rent
                                  </Td>
                                  <Td
                                    className="ro-content-middle-ext-4"
                                    colspan="3"
                                  >
                                    {total_ext_days}
                                  </Td>
                                  <Td className="ro-content-middle-ext-5">
                                    Total Ext. Days Rent
                                  </Td>
                                  <Td className="ro-content-middle-ext-6">
                                    {total_ext_days_rent?.toFixed(2)}
                                  </Td>
                                  <Td
                                    className="ro-content-middle-ext-1"
                                    colspan="1"
                                  >
                                    Total Damage Cost
                                  </Td>
                                  <Td className="ro-content-middle-ext-2">
                                    {total_damage_qty?.toFixed(2)}
                                  </Td>
                                  <Td
                                    className="ro-content-middle-ext-1"
                                    colspan="1"
                                  >
                                    Total Amount
                                  </Td>
                                  <Td className="ro-content-middle-ext-7">
                                    ₹ {total_payable_payment.toFixed(2)}
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td
                                    className="ro-content-middle-v-1"
                                    colspan="15"
                                  >
                                    Vehicle Rent
                                  </Td>
                                  <Td className="ro-content-middle-v-2">
                                    ₹ {summeryItem.vehicle_charge}
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td
                                    className="ro-content-middle-l-1"
                                    colspan="15"
                                  >
                                    Labour Rent
                                  </Td>
                                  <Td className="ro-content-middle-l-2">
                                    ₹ {summeryItem.labour_charge}
                                  </Td>
                                </Tr>
                                <Tr style={{ fontWeight: "bold" }}>
                                  <Td
                                    className="ro-content-middle-t-1"
                                    colspan="13"
                                  >
                                    Total Remaining Payment
                                  </Td>
                                  <Td className="ro-content-middle-t-2">
                                    ₹ {total_remain_payment?.toFixed(2)}
                                  </Td>
                                  <Td className="ro-content-middle-t-1">
                                    Total Payable Amount
                                  </Td>
                                  <Td className="ro-content-middle-t-2">
                                    ₹{" "}
                                    {(
                                      total_payable_payment + total_charges
                                    )?.toFixed(2)}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </div>
                        </div>
                        <div className="cl-summary-btn-div">
                          <ReactToPrint
                            trigger={() => (
                              <button
                                className="cl-summary-btn"
                                style={{ backgroundColor: AppColors.red }}
                                onClick={printDocument}
                              >
                                Print
                              </button>
                            )}
                            content={() => componentRef.current}
                            removeAfterPrint={true}
                          />
                          <button
                            style={{ marginLeft: "10px" }}
                            className="cl-summary-btn"
                            onClick={toggleSummeryPopup}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    }
                  />
                )}
              </div>
              {openInvoice ? (
                <div ref={componentRef}>
                  {arr == "" ? (
                    <ReturnOrderInvoice
                      customer_phone={customer_phone}
                      customer_name={customer_name}
                      summeryItem={summeryItem}
                      isChecked={isChecked}
                    />
                  ) : (
                    <div>
                      {arr.map((summeryItem) => (
                        <ReturnOrderInvoice
                          customer_phone={customer_phone}
                          customer_name={customer_name}
                          summeryItem={summeryItem}
                          isChecked={isChecked}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        }
      />
      <ClAccordian
        accordionData={addSite}
        data={data}
        onEditSite={onEditSite}
        onDelete={onDelete}
        state={state}
        placeOrder={placeOrder}
        setP_s_id={setP_s_id}
        onSummery={onSummery}
        onCLickAccordion={onCLickAccordion}
        isLoading={isAccordionLoading && isAccordionLoading === p_s_id?.site_id}
        p_s_id={p_s_id}
        customer_name={customer_name}
        customer_phone={customer_phone}
        toggleLoading={toggleLoading}
        setToggleLoading={setToggleLoading}
        onEdit={onEdit}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        r_o_Site={r_o_Site}
        r_SiteSelectedPage={r_SiteSelectedPage}
        setr_SiteSelectedPage={setr_SiteSelectedPage}
        onShortOrderSiteData={onShortOrderSiteData}
        onShortReturnSiteData={onShortReturnSiteData}
        handlePlaceCheckbox={handlePlaceCheckbox}
        setPlaceArr={setPlaceArr}
        onDeletePlaceOrder={onDeletePlaceOrder}
        onDeleteReturnOrder={onDeleteReturnOrder}
        show_site_payment={show_site_payment}
      />
    </div>
  );
};

export default ClContentMiddle;
