import React, { useEffect, useState } from "react";
import AcInputText from "../AcInputText/AcInputText";
import axios from "axios";
import { AddProduct } from "../Popup/Popup";
import { alertService, AlertType } from "../../utils/alert.service";
import "react-toastify/dist/ReactToastify.css";
import { ValidateName } from "../../helpers/validators";
import { BASEURL } from "../../constant/constant";
import SelectInputText from "../InputText/SelectInputText";
import ButtonTop from "../AcInputText/ButtonTop";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalenderIcon } from "../../assets";

const StockContentTop = ({
  isOpen,
  setIsOpen,
  addStock,
  setAddStock,
  setSize,
  productDropDown,
  ispd_Id,
  isv_Id,
  setpd_IsId,
  setv_IsId,
  varientDropDown,
  ProductDropDown,
  VarientDropDown,
  StockList,
  errors,
  setErrors,
  data,
  defaultValue,
  varList,
}) => {
  const isDisabled = true;
  const isDisabled1 = false;
  const [stockDate, setStockDate] = useState(new Date());

  const addStockDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const item = {
      product_variants_id: isv_Id,
      price_per_item: parseFloat(addStock.stockPrice),
      total_quantity: parseInt(addStock.stockQty),
      total_amount: parseFloat(addStock.stockTotal),
      purchase_date: moment(stockDate),
    };
    try {
      const response = await axios({
        url: BASEURL + "product/stock/add",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        await varList();
        toggleSizePopup();
        setAddStock({
          stockQty: "",
          stockPrice: "",
          stockTotal: "",
          stockDate: defaultValue,
        });
        StockList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  useEffect(() => {
    if (ispd_Id) {
      VarientDropDown();
    }
  }, [ispd_Id]);

  const toggleSizePopup = () => {
    setIsOpen(!isOpen);
    ProductDropDown();
    setAddStock({
      stockQty: "",
      stockPrice: "",
      stockTotal: "",
      stockDate: defaultValue,
    });
  };

  const validator = () => {
    let newErrors = {};
    let isValid = true;
    const statusQty = ValidateName(addStock.stockQty);
    const statusProductName = ValidateName(ispd_Id);
    const statusVarient = ValidateName(isv_Id);

    if (statusQty) {
      newErrors.stockQty = statusQty === 1 ? "Quantity is Required" : "";
      isValid = false;
    }

    if (statusProductName) {
      newErrors.ispd_Id = statusProductName === 1 ? "Select Product Name" : "";
      isValid = false;
    }
    if (statusVarient) {
      newErrors.isv_Id = statusVarient === 1 ? "Select Variant" : "";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator()) {
      addStockDetail();
    }
  };

  return (
    <div className="content-top">
      <div className="content-top-left">
        <span className="content-top-left-title">STOCK DETAILS</span>
        <span className="content-top-left-desc">
          All Stocks Details Listed Here Also You Can Update or Delete
        </span>
        {data?.list?.length > 0 ? (
          <span className="content-top-left-dropdown">
            Show&nbsp;
            <select
              id="number-dd"
              name="number"
              onChange={(e) => setSize(e.target.value)}
            >
              {[10, 25, 50, 75, 100, 125, 150, 175, 200].map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            &nbsp; Entries
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="content-top-right">
        <ButtonTop onClick={toggleSizePopup} name={"Add Stock"} />
      </div>
      {isOpen && (
        <AddProduct
          content={
            <form>
              <div className="addProduct-first">
                <span className="addProduct-title">STOCK DETAILS</span>
                <span className="addProduct-desc">All field are required</span>
              </div>
              <div className="addProduct-second">
                <span className="addProduct-second-title">
                  Personal Details
                  <div className="addProduct-second-hr">
                    <hr />
                  </div>
                </span>
                <div className="addProduct-second-firstRow">
                  <SelectInputText
                    label="Product Name"
                    setpd_IsId={setpd_IsId}
                    onChange={(e) => {
                      setpd_IsId(e.target.value);
                    }}
                    data={productDropDown?.list}
                    value={ispd_Id}
                    error={errors.ispd_Id}
                  />
                  <SelectInputText
                    label="Variant"
                    setv_IsId={setv_IsId}
                    onChange={(e) => {
                      setv_IsId(e.target.value);
                    }}
                    data={varientDropDown?.list}
                    value={isv_Id}
                    error={errors.isv_Id}
                  />
                  <div
                    className="po-accordion-date"
                    style={{ marginLeft: "20px" }}
                  >
                    <label
                      className="ac-inputText-label"
                      htmlFor={"Purchase Date"}
                      style={{ marginBottom: "8px" }}
                    >
                      Purchase Date
                    </label>
                    <div className="ac-inputText-date-container">
                      <DatePicker
                        className="ac-inputText-input-date"
                        onChange={(value) => setStockDate(value)}
                        selected={stockDate}
                        dateFormat="dd-MM-yyyy"
                      />
                      <img src={CalenderIcon} />
                    </div>
                  </div>
                </div>
                <div className="addProduct-second-firstRow">
                  <AcInputText
                    label="Quantity"
                    type="text"
                    placeholder="Quantity"
                    value={addStock.stockQty}
                    onChange={(e) =>
                      setAddStock((prevValue) => ({
                        ...prevValue,
                        stockQty: e.target.value,
                      }))
                    }
                    error={errors?.stockQty}
                  />
                  <AcInputText
                    label="Price (per item)"
                    placeholder="Price"
                    disabled={addStock.stockQty > 0 ? isDisabled1 : isDisabled}
                    value={addStock.stockPrice}
                    onChange={(e) =>
                      setAddStock((prevValue) => ({
                        ...prevValue,
                        stockPrice: e.target.value.replace(/\D/g, ""),
                        stockTotal: prevValue.stockQty * e.target.value,
                      }))
                    }
                  />
                  <AcInputText
                    label="Total Amount"
                    placeholder="Total Amount"
                    disabled={addStock.stockQty > 0 ? isDisabled1 : isDisabled}
                    value={addStock.stockTotal}
                    onChange={(e) =>
                      setAddStock((prevValue) => ({
                        ...prevValue,
                        stockTotal: e.target.value.replace(/\D/g, ""),
                        stockPrice: e.target.value / prevValue.stockQty,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="addProduct-second-bottam-btn">
                <button
                  onClick={submitHandler}
                  className="addProduct-second-bottam-btn-sub"
                >
                  Submit
                </button>
                <button
                  className="addProduct-second-bottam-btn-can"
                  onClick={toggleSizePopup}
                >
                  Cancel
                </button>
              </div>
            </form>
          }
        />
      )}
    </div>
  );
};

export default StockContentTop;
