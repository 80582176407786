import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASEURL } from "../constant/constant";
import Timer from "../helpers/Timer";
import { ValidateEmail, ValidateName } from "../helpers/validators";
import { AlertType, alertService } from "../utils/alert.service";
import "./../pages/AdminLogin/AdminLogin.css";
import InputText from "./InputText/InputText";
import { OpenProfile } from "./Popup/Popup";

import { ToastContainer, toast } from "react-toastify";
import {
  BellIcon,
  ColoredPassIcon,
  ColoredUserIcon,
  LogOutIcon,
  MenuIcon,
  UserIcon,
} from "../assets";
import { getFirebaseToken, onForegroundMessage } from "./Firebase/Firebase";
import "./Firebase/styles.css";

const ContentHeader = ({ setIsActive }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [oldData, setOldData] = useState("");
  const [changeData, setChangeData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showNotificationBanner, setShowNotificationBanner] = useState(
    Notification.permission === "default"
  );
  const [render, setRender] = useState("");
  const [storeFcmToken, setStoreFcmToken] = useState("");
  const showBanner = "showBanner";

  const oldUserData = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const response = await axios({
      url: BASEURL + "user/details",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
    });
    setOldData(response.data.result);
    sessionStorage.setItem("user_name", response.data.result.user_name);
    sessionStorage.setItem(
      "user_full_name",
      response.data.result.user_full_name
    );
    sessionStorage.setItem("user_email", response.data.result.user_email);
  };

  useEffect(() => {
    oldUserData();
  }, []);

  useEffect(() => {
    setChangeData(oldData);
  }, [oldData]);

  const user_name = sessionStorage.getItem("user_name");

  const ChangeCustomerDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const item = {
      user_name: changeData.user_name,
      user_full_name: changeData.user_full_name,
      user_email: changeData.user_email,
      current_password: changeData.currentPassword,
      new_password: changeData.newPassword,
    };
    try {
      const response = await axios({
        url: BASEURL + "user/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const letsToast = () => {
    alertService.alert({
      type: AlertType.Error,
      message: "Confirm Password is Not Same",
    });
  };

  const validator = () => {
    let newErrors = {};
    let isValid = true;
    const statusName = ValidateName(changeData.user_name);
    const statusFullName = ValidateName(changeData.user_full_name);
    const statusEmail = ValidateEmail(changeData.user_email);

    if (statusName) {
      newErrors.user_name = statusName === 1 ? "Name is Required" : "";
      isValid = false;
    }
    if (statusFullName) {
      newErrors.user_full_name =
        statusFullName === 1 ? "Full Name is Required" : "";
      isValid = false;
    }
    if (statusEmail) {
      newErrors.user_email =
        statusEmail === 1 ? "Email is Required" : "Email is Invalid";
      isValid = false;
    }

    if (changeData.newPassword !== changeData.confirmPassword) {
      letsToast();
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleClick = (event) => {
    event.preventDefault();
    setIsActive((current) => !current);
  };

  const handleOpen = () => {
    setOpen(!open);
    setNotificationOpen(false);
  };

  const profileHandleOpen = () => {
    setProfileOpen(!profileOpen);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const changeSubmitButton = () => {
    if (validator()) {
      ChangeCustomerDetail();
    }
  };

  const changeCancelButton = () => {
    setProfileOpen(!profileOpen);
    setOpen(!open);
  };

  useEffect(() => {
    onForegroundMessage()
      .then((payload) => {
        setRender(payload);
        const {
          notification: { title, body },
        } = payload;
        toast(<ToastifyNotification title={title} body={body} />);
      })
      .catch((err) =>
        console.log(
          "An error occured while retrieving foreground message. ",
          err
        )
      );
  }, [render]);

  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken) => {
        setStoreFcmToken(firebaseToken);

        if (firebaseToken) {
          setShowNotificationBanner(false);
        }
      })
      .catch((err) =>
        console.error("An error occured while retrieving firebase token. ", err)
      );
  };

  const ToastifyNotification = ({ title, body }) => (
    <div className="push-notification" onClick={notificationHandleOpen}>
      <h2 className="push-notification-title">{title}</h2>
      <p className="push-notification-text">{body}</p>
    </div>
  );

  const notificationHandleOpen = () => {
    setNotificationOpen(!notificationOpen);
    setOpen(false);
  };

  return (
    <div className="home-section">
      <Timer />
      <div>
        {/* {showNotificationBanner ? (
          <div className="notification-banner">
            <span>The app needs permission to</span>
            <div
              className="notification-banner-link"
              onClick={handleGetFirebaseToken}
            >
              {" "}
              enable push notifications.
            </div>
          </div>
        ) : null} */}
        {/* <nav className={`${showNotificationBanner ? showBanner : null}`}> */}
        <nav>
          <div class="sidebar-button">
            <div className="content-header-menu">
              <img src={MenuIcon} onClick={handleClick} />
            </div>
          </div>
          <div class="profile-details">
            <div
              className="content-header-bell-icon"
              // onClick={notificationHandleOpen}
            >
              <img src={BellIcon} />
            </div>
            <div className="content-header-user-icon" onClick={handleOpen}>
              <span className="content-header-user">
                {user_name?.charAt(0)}
              </span>
            </div>
            <div className="content-header-user-name">
              {notificationOpen ? (
                <ul
                  className="notification-menu"
                  style={{ paddingBottom: "20px" }}
                >
                  <li>Coming Soon...</li>
                  <div className="menu-hr" />
                  <li>Coming Soon...</li>
                  <div className="menu-hr" />
                  <li>Coming Soon...</li>
                  <div className="menu-hr" />
                  <li>Coming Soon...</li>
                  <div className="menu-hr" />
                </ul>
              ) : null}
              {open ? (
                <ul className="menu" style={{ paddingBottom: "20px" }}>
                  <div className="menu-profile-logo">
                    <div className="content-header-user-icon">
                      <span className="content-header-user">
                        {user_name?.charAt(0)}
                      </span>
                    </div>
                    <span className="menu-title">{user_name}</span>
                  </div>
                  <div className="menu-hr" />
                  <li className="menu-item">
                    <button className="menu-btn" onClick={profileHandleOpen}>
                      <img src={UserIcon} />
                      <span className="menu-btn-title">Profile</span>
                    </button>
                  </li>
                  <li className="menu-item">
                    <button className="menu-btn" onClick={handleLogout}>
                      <img src={LogOutIcon} />
                      <span className="menu-btn-title">Log Out</span>
                    </button>
                  </li>
                </ul>
              ) : null}
            </div>
            <div>
              {profileOpen ? (
                <div>
                  <OpenProfile
                    content={
                      <div className="profile">
                        <span className="profile-title">Profile</span>
                        <InputText
                          label="User"
                          type="text"
                          placeholder="User"
                          src={ColoredUserIcon}
                          value={changeData.user_name}
                          onChange={(e) =>
                            setChangeData((prevValue) => ({
                              ...prevValue,
                              user_name: e.target.value,
                            }))
                          }
                          error={errors?.user_name}
                        />
                        <InputText
                          style={{ marginTop: "-20px" }}
                          label="Full Name"
                          type="text"
                          placeholder="Full Name"
                          src={ColoredUserIcon}
                          value={changeData.user_full_name}
                          onChange={(e) =>
                            setChangeData((prevValue) => ({
                              ...prevValue,
                              user_full_name: e.target.value,
                            }))
                          }
                        />
                        <InputText
                          style={{ marginTop: "-20px" }}
                          label="Email"
                          type="text"
                          placeholder="Email"
                          src={ColoredUserIcon}
                          value={changeData.user_email}
                          onChange={(e) =>
                            setChangeData((prevValue) => ({
                              ...prevValue,
                              user_email: e.target.value,
                            }))
                          }
                          error={errors?.user_email}
                        />
                        <InputText
                          style={{ marginTop: "-20px" }}
                          label="Current Password"
                          type="password"
                          placeholder="Current Password"
                          src={ColoredPassIcon}
                          value={changeData.currentPassword}
                          onChange={(e) =>
                            setChangeData((prevValue) => ({
                              ...prevValue,
                              currentPassword: e.target.value,
                            }))
                          }
                          error={errors?.curr_pass}
                        />
                        <InputText
                          style={{ marginTop: "-20px" }}
                          label="New Password"
                          type="password"
                          placeholder="New Password"
                          src={ColoredPassIcon}
                          value={changeData.newPassword}
                          onChange={(e) =>
                            setChangeData((prevValue) => ({
                              ...prevValue,
                              newPassword: e.target.value,
                            }))
                          }
                          error={errors?.new_pass}
                        />
                        <InputText
                          style={{ marginTop: "-20px" }}
                          label="Confirm Password"
                          type="password"
                          placeholder="ConfirmPassword"
                          src={ColoredPassIcon}
                          value={changeData.confirmPassword}
                          onChange={(e) =>
                            setChangeData((prevValue) => ({
                              ...prevValue,
                              confirmPassword: e.target.value,
                            }))
                          }
                          error={errors?.con_pass}
                        />
                        <div className="profile-button">
                          <div className="profile-button-container">
                            <button
                              className="profile-button-cancel"
                              onClick={changeCancelButton}
                            >
                              Cancel
                            </button>
                          </div>
                          <div className="profile-button-container">
                            <button
                              className="profile-button-change"
                              onClick={changeSubmitButton}
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
              ) : null}
            </div>
          </div>
        </nav>
        <ToastContainer hideProgressBar />
      </div>
    </div>
  );
};

export default ContentHeader;
