import React from "react";
import ContentLoader from "react-content-loader";

const FadingLoader = () => {
  return (
    <div style={{ marginTop: "50px" }} className="row">
      <FadingLoaderCard1 />
      <FadingLoaderCard2 />
      <FadingLoaderCard3 />
      <FadingLoaderCard4 />
      <FadingLoaderCard5 />
    </div>
  );
};

const FadingLoaderCard1 = () => {
  return (
    <ContentLoader width={100} height={100}>
      <rect x="10" y="1" rx="15" ry="15" width="1200" height="65" />
    </ContentLoader>
  );
};

const FadingLoaderCard2 = () => {
  return (
    <ContentLoader width={100} height={100}>
      <rect x="10" y="1" rx="15" ry="15" width="1200" height="65" />
    </ContentLoader>
  );
};

const FadingLoaderCard3 = () => {
  return (
    <ContentLoader width={100} height={100}>
      <rect x="10" y="1" rx="15" ry="15" width="1200" height="65" />
    </ContentLoader>
  );
};

const FadingLoaderCard4 = () => {
  return (
    <ContentLoader width={100} height={100}>
      <rect x="10" y="1" rx="15" ry="15" width="1200" height="65" />
    </ContentLoader>
  );
};

const FadingLoaderCard5 = () => {
  return (
    <ContentLoader width={100} height={100}>
      <rect x="10" y="1" rx="15" ry="15" width="1200" height="65" />
    </ContentLoader>
  );
};

export default FadingLoader;
