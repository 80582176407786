import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminLogin from "./../pages/AdminLogin/AdminLogin";
import AddCustomer from "./../pages/AddCustomer/AddCustomer";
import CustomerList from "./../pages/CustomerList/CustomerList";
import Product from "../pages/Product/Product";
import Measurement from "../pages/Measurement/Measurement";
import Variant from "../pages/Variant/Variant";
import Stock from "../pages/Stock/Stock";
import Payment from "../pages/Payment/Payment";
import PrivateRoute from "./ProtectedRoute";
import PlaceOrder from "../pages/PlaceOrder/PlaceOrder";
import Dashboard from "../pages/Dashboard/Dashboard";
import ReturnOrder from "../pages/ReturnOrder/ReturnOrder";
import Expenses from "../pages/Expenses/Expenses";
import Search from "../pages/Search/Search";
import TransactionYearMain from "../pages/Transaction/TransactionYearMain";
import TransactionMonthMain from "../pages/Transaction/TransactionMonthMain";
import TransactionDayMain from "../pages/Transaction/TransactionDayMain";
import TransactionOfDayMain from "../pages/Transaction/TransactionOfDayMain";
import ForgotPassword from "../pages/AdminLogin/ForgotPassword";
import EnterOtp from "../pages/AdminLogin/EnterOtp";
import NewPassword from "../pages/AdminLogin/NewPassword";
import ChalanBook from "../pages/ChalanBook/ChalanBook";
import Invoice from "../pages/Invoice/Invoice";

const route = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AdminLogin />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/customer"
          element={
            <PrivateRoute>
              <AddCustomer />
            </PrivateRoute>
          }
        />
        <Route
          path="/customer/details"
          element={
            <PrivateRoute>
              <CustomerList />
            </PrivateRoute>
          }
        />
        <Route
          path="/customer/details/place-order"
          element={
            <PrivateRoute>
              <PlaceOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="/customer/details/return-order"
          element={
            <PrivateRoute>
              <ReturnOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="/customer/details/payment"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route
          path="/product"
          element={
            <PrivateRoute>
              <Product />
            </PrivateRoute>
          }
        />
        <Route
          path="/measurement"
          element={
            <PrivateRoute>
              <Measurement />
            </PrivateRoute>
          }
        />
        <Route
          path="/variant"
          element={
            <PrivateRoute>
              <Variant />
            </PrivateRoute>
          }
        />
        <Route
          path="/stock"
          element={
            <PrivateRoute>
              <Stock />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route
          path="/expenses"
          element={
            <PrivateRoute>
              <Expenses />
            </PrivateRoute>
          }
        />
        <Route
          path="/search"
          element={
            <PrivateRoute>
              <Search />
            </PrivateRoute>
          }
        />
        <Route
          path="/transaction-year"
          element={
            <PrivateRoute>
              <TransactionYearMain />
            </PrivateRoute>
          }
        />
        <Route
          path="/transaction-year/transaction-month"
          element={
            <PrivateRoute>
              <TransactionMonthMain />
            </PrivateRoute>
          }
        />
        <Route
          path="/transaction-year/transaction-month/transaction-day"
          element={
            <PrivateRoute>
              <TransactionDayMain />
            </PrivateRoute>
          }
        />
        <Route
          path="/transaction-year/transaction-month/transaction-day/transaction-ofday"
          element={
            <PrivateRoute>
              <TransactionOfDayMain />
            </PrivateRoute>
          }
        />
        <Route
          path="/chalan-book"
          element={
            <PrivateRoute>
              <ChalanBook />
            </PrivateRoute>
          }
        />
        <Route
          path="/invoice"
          element={
            <PrivateRoute>
              <Invoice />
            </PrivateRoute>
          }
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/forgot-password/otp" element={<EnterOtp />} />
        <Route
          path="/forgot-password/otp/new-password"
          element={<NewPassword />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default route;
