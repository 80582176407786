import React from "react";
import { useNavigate } from "react-router-dom";
import { HomeIcon } from "../../../assets";
const TransactionOfDayNavigation = () => {
  const navigate = useNavigate();
  return (
    <div className="content-nav">
      <img src={HomeIcon} />
      <span className="content-nav-home">Home</span>
      <i class="bx bx-chevron-right"></i>
      <span
        className="content-nav-home"
        onClick={() => navigate("/transaction-year")}
      >
        Transaction-Year
      </span>
      <i class="bx bx-chevron-right"></i>
      <span
        className="content-nav-home"
        onClick={() => navigate("/transaction-month")}
      >
        Transaction-Month
      </span>
      <i class="bx bx-chevron-right"></i>
      <span className="content-nav-home" onClick={() => navigate(-1)}>
        Transaction-Day
      </span>
      <i class="bx bx-chevron-right"></i>
      <span className="content-nav-home">Transaction</span>
    </div>
  );
};

export default TransactionOfDayNavigation;
