import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ReactSwitch from "react-switch";
import VAccordion from "./VAccordion";
import FadingLoader from "../LoadingSpinner/AccordionLoading";
import LotiFiles from "../Lotifiles/LotiFiles";
import { DeleteIcon, EditIcon } from "../../assets";

const SizeContentMiddle = ({
  onChangeToggle,
  onEdit,
  onDelete,
  varData,
  vOpen,
  setVopen,
  isLoading,
}) => {
  const handleChange = (val) => {
    onChangeToggle(val);
  };

  return (
    <div>
      {isLoading ? (
        <FadingLoader />
      ) : (
        <div>
          {varData?.list?.length > 0 ? (
            <div className="size-content-middle">
              {varData.list && varData.list.length > 0
                ? varData.list.map((item) => (
                    <VAccordion
                      vOpen={vOpen}
                      setVopen={setVopen}
                      product_name={item.product_name}
                      content={
                        <div
                          className="var-content-middle-table"
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            marginBottom: "20px",
                          }}
                        >
                          {item.product_variants.length > 0 ? (
                            <Table className="content-middle-main">
                              <Thead>
                                <Tr>
                                  <Th className="content-middle-title-tr">
                                    Size
                                  </Th>
                                  <Th className="content-middle-title-tr">
                                    Measurement
                                  </Th>
                                  <Th className="content-middle-title-tr">
                                    Fix-Rent
                                  </Th>
                                  <Th className="content-middle-title-tr">
                                    Is Active
                                  </Th>
                                  <Th className="content-middle-title-tr">
                                    Action
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {item.product_variants &&
                                item.product_variants.length > 0
                                  ? item.product_variants.map(
                                      (mapItem, index) => {
                                        return (
                                          <Tr>
                                            <Td className="content-middle-name-data">
                                              {mapItem.product_variant_size}
                                            </Td>
                                            <Td className="content-middle-name-data">
                                              {mapItem.measurement_name}
                                            </Td>
                                            <Td className="content-middle-name-data">
                                              {mapItem.fix_rent}
                                            </Td>
                                            <Td className="content-middle-name-data">
                                              <ReactSwitch
                                                height={25}
                                                width={53}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                checked={mapItem.is_active}
                                                onChange={(val) =>
                                                  handleChange({
                                                    product_variants_id:
                                                      mapItem.product_variants_id,
                                                    status: val,
                                                  })
                                                }
                                              />
                                            </Td>
                                            <Td className="content-middle-name-data">
                                              <div>
                                                <img
                                                  src={EditIcon}
                                                  onClick={() =>
                                                    onEdit({
                                                      ...mapItem,
                                                      product_name:
                                                        item.product_name,
                                                    })
                                                  }
                                                />
                                                <img
                                                  className="cl-content-middle-action-data-div-delete"
                                                  src={DeleteIcon}
                                                  onClick={() =>
                                                    onDelete(mapItem)
                                                  }
                                                />
                                              </div>
                                            </Td>
                                          </Tr>
                                        );
                                      }
                                    )
                                  : null}
                              </Tbody>
                            </Table>
                          ) : (
                            <LotiFiles
                              message={"No Data Found For Variants!"}
                            />
                          )}
                        </div>
                      }
                    />
                  ))
                : null}
            </div>
          ) : (
            <LotiFiles message={"No Data Found For Variants!"} />
          )}
        </div>
      )}
      <div className="content-bottam" />
    </div>
  );
};

export default SizeContentMiddle;
