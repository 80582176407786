import React from "react";
import moment from "moment";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { SahjanandLogoIcon } from "../assets";

const PlaceOrderInvoice = ({
  customer_phone,
  customer_name,
  summeryItem,
  isChecked,
}) => {
  const total_rent =
    summeryItem?.order_details?.reduce(
      (a, b) => a + b?.rent_fifteen_days ?? 0,
      0
    ) ?? 0;

  return (
    <div>
      <div className="col-md-12 invoice-date-name">
        <div className="col-md-6">
          <span>{moment().format("DD-MM-YYYY, h:mm")}</span>
        </div>
        <div className="col-md-6">
          <span>Sahjanand Construction</span>
        </div>
      </div>
      <div className="invoice-main">
        <div className="col-md-12 invoice-part-first">
          <div className="col-md-4 invoice-part-first-left">
            <span className="invoice-part-first-text">Gopal Patel</span>
          </div>
          <div className="col-md-4 invoice-part-first-middle">
            <img
              className="invoice-part-first-middle-logo"
              src={SahjanandLogoIcon}
            />
            <span className="invoice-part-first-middle-name">
              સહજાનંદ કન્ટ્રકશન
            </span>
          </div>
          <div className="col-md-4 invoice-part-first-last">
            <span className="invoice-part-first-text">9173735497</span>
          </div>
        </div>
        <div>
          <div className="col-md-12 invoice-part-second">
            <div className="col-md-12 invoice-part-second-one">
              <span className="invoice-part-second-one-text">
                Placed Item Details
              </span>
            </div>
            <div className="col-md-12 invoice-part-second-two">
              <div className="col-md-3 invoice-part-second-two-1">
                <span>Time :</span>
                <span>{moment().format("h:mm a")}</span>
              </div>
              <div className="col-md-3 invoice-part-second-two-1">
                <span>Date :</span>
                <span>{moment().format("DD-MM-YYYY")}</span>
              </div>
              <div className="col-md-6">
                <span>Mobile :</span>
                <span>{customer_phone}</span>
              </div>
            </div>
            <div className="col-md-12 invoice-part-second-three">
              <div className="col-md-6 invoice-part-second-two-1">
                <span>Name :</span>
                <span>{customer_name}</span>
              </div>
              <div className="col-md-6">
                <span>Returned By :</span>
                <span>{customer_name}</span>
              </div>
            </div>
          </div>
          <div className="col-md-12 invoice-part-second">
            <div>
              <div className="ro-in">
                <Table className=" ro-in-table">
                  <Thead>
                    <Tr>
                      <Th className="content-middle-title-tr ro-in-content-middle-no">
                        No
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-name">
                        Item Name
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                        Order Date
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-r-qty">
                        Return Date
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-d-qty">
                        Order Qty.
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-d-qty">
                        Unit Rent
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-d-q-charge">
                        Rent (per day)
                      </Th>
                      <Th className="content-middle-title-tr ro-in-content-middle-e-days">
                        Rent (15 days)
                      </Th>
                    </Tr>
                  </Thead>
                  {summeryItem?.order_details &&
                  summeryItem?.order_details?.length > 0
                    ? summeryItem?.order_details?.map((item, index) => {
                        return (
                          <Tbody key={index}>
                            <Tr>
                              <Td className="ro-in-content-middle-no-data">
                                {index + 1}
                              </Td>
                              <Td className="ro-in-content-middle-name-data">
                                {item.item_name}
                              </Td>
                              <Td className="ro-in-content-middle-r-qty-data">
                                {summeryItem?.order_date?.trim() !== ""
                                  ? moment(summeryItem.order_date).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </Td>
                              <Td className="ro-in-content-middle-r-qty-data">
                                {summeryItem?.expected_return_date?.trim() !==
                                ""
                                  ? moment(
                                      summeryItem?.expected_return_date
                                    ).format("DD-MM-YYYY")
                                  : "-"}
                              </Td>
                              <Td className="ro-in-content-middle-e-d-rent-data">
                                {item?.quantity}
                              </Td>
                              <Td className="ro-in-content-middle-t-e-d-rent-data">
                                {item?.rent}
                              </Td>
                              <Td className="ro-in-content-middle-t-e-d-rent-data">
                                {item?.rent_per_day}
                              </Td>
                              <Td className="ro-in-content-middle-r-date-data">
                                {item?.rent_fifteen_days?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 0,
                                    useGrouping: true,
                                  }
                                )}
                              </Td>
                            </Tr>
                          </Tbody>
                        );
                      })
                    : null}
                  <Tbody>
                    <Tr>
                      <Td className="ro-in-content-middle-ext-3" colspan="7">
                        Total Rent
                      </Td>
                      <Td className="ro-in-content-middle-ext-4">
                        ₹{" "}
                        {total_rent?.toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                          useGrouping: true,
                        })}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td className="ro-in-content-middle-v-1" colspan="7">
                        Vehicle Rent
                      </Td>
                      <Td className="ro-in-content-middle-v-2">
                        ₹ {summeryItem?.vehicle_charge?.toFixed(2)}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td className="ro-in-content-middle-l-1" colspan="7">
                        Labour Rent
                      </Td>
                      <Td className="ro-in-content-middle-l-2">
                        ₹{" "}
                        {summeryItem?.labour_charge?.toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                          useGrouping: true,
                        })}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td className="ro-in-content-middle-t-1" colspan="7">
                        Total Payable Amount
                      </Td>
                      <Td className="ro-in-content-middle-t-2">
                        ₹{" "}
                        {(
                          total_rent +
                          summeryItem.vehicle_charge +
                          summeryItem.labour_charge
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                          useGrouping: true,
                        })}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-med-12 invoice-part-three">
          <div className="col-md-4 invoice-part-three-1">
            <span>Signature of Customer</span>
          </div>
          <div className="col-md-4 invoice-part-three-2">
            <span>Thank You</span>
          </div>
          <div className="col-md-4 invoice-part-three-3">
            <span>Signature of Receiver</span>
          </div>
        </div>
      </div>
      {isChecked ? <div className="page-break" /> : ""}
    </div>
  );
};

export default PlaceOrderInvoice;
