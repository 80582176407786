import axios from "axios";
import OTPInput from "otp-input-react";
import { useState } from "react";
import LoadingSpin from "react-loading-spin";
import { useLocation, useNavigate } from "react-router-dom";
import { BASEURL } from "../../constant/constant";
import { AlertType, alertService } from "../../utils/alert.service";
import "./AdminLogin.css";
import { SahjanandLogoIcon } from "../../assets";

const EnterOtp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [OTP, setOTP] = useState("");
  const { state } = useLocation();

  const navigate = useNavigate();

  const otpApi = async () => {
    const item = { otp: OTP, email: state.user_email };
    try {
      setIsLoading(true);
      const response = await axios({
        url: BASEURL + "user/verify_otp",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: item,
      });
      if (response.status === 200) {
        setIsLoading(false);
        navigate("/forgot-password/otp/new-password", {
          state: {
            user_email: state.user_email,
            otp: OTP,
          },
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    otpApi();
  };
  return (
    <div>
      <section className="login-main-container">
        <div className="card">
          <img className="al-logo" src={SahjanandLogoIcon} />
          <h2 className="al-title">Forgot Password</h2>
          <h5 style={{ marginTop: "1rem" }}>
            Code has been send to {state.user_email}
          </h5>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                margin: "2rem",
                marginLeft: "4rem",
                marginRight: "4rem",
              }}
            >
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
              />
            </div>
            <div className="button-container">
              <button className="button" disabled={isLoading}>
                {isLoading ? (
                  <LoadingSpin
                    primaryColor="white"
                    size="30px"
                    animationDuration="1s"
                  />
                ) : (
                  "VERIFY"
                )}
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default EnterOtp;
