import axios from "axios";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import ReactToPrint from "react-to-print";
import { BASEURL } from "../../constant/constant";
import { AlertType, alertService } from "../../utils/alert.service";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import PlaceOrderInvoice from "../PlaceOrderInvoice";
import { OrderSummery, ReturnSummery } from "../Popup/Popup";
import ReturnOrderInvoice from "../ReturnOrderInvoice";
import { AppColors } from "../colors";
import ChalanMiddle from "./ChalanMiddle";

const ChalanContent = () => {
  const [chalan, setChalan] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orderList, setOrderList] = useState("");
  const [summeryOpen, setSummeryOpen] = useState(false);
  const [openInvoice, setOpenInvoice] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState("");

  const componentRef = useRef();

  const ChalanList = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    setIsLoading(true);
    try {
      const response = await axios({
        url: BASEURL + "chalan",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
      });
      setChalan(response.data);
      setFilteredData(response.data?.list);
      setIsLoading(false);
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  useEffect(() => {
    ChalanList();
  }, []);

  const toggleSummeryPopup = () => {
    setSummeryOpen(!summeryOpen);
    setOpenInvoice(!openInvoice);
  };

  const onSummery = (item) => {
    setOrderList(item);
    toggleSummeryPopup();
  };

  const printDocument = () => {
    window.print();
  };

  const total_damage_qty =
    orderList?.return_order_details?.reduce(
      (a, b) => a + b?.total_damaged_cost ?? 0,
      0
    ) ?? 0;
  const total_ext_days =
    orderList?.return_order_details?.reduce(
      (a, b) => a + b?.total_rent ?? 0,
      0
    ) ?? 0;
  const total_ext_days_rent =
    orderList?.return_order_details?.reduce(
      (a, b) => a + b?.total_extra_days_rent ?? 0,
      0
    ) ?? 0;
  const total_payable_payment =
    orderList?.return_order_details?.reduce(
      (a, b) => a + b?.payable_amount ?? 0,
      0
    ) ?? 0;
  const total_remain_payment =
    orderList?.return_order_details?.reduce(
      (a, b) => a + b?.remaining_payment ?? 0,
      0
    ) ?? 0;
  const total_charges = orderList.labour_charge + orderList.vehicle_charge;

  const sumOfCharges = orderList?.vehicle_charge + orderList?.labour_charge;

  let total_rent =
    orderList?.order_details?.reduce(
      (a, b) => a + b?.rent_fifteen_days ?? 0,
      0
    ) ?? 0;

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const filtered = chalan?.list?.filter((item) =>
      item?.customer_full_name?.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleSortChalan = () => {
    const sortedItems = [...filteredData].sort((a, b) => {
      if (a.chalan_no === b.chalan_no) {
        return a.chalan_no < b.chalan_no ? -1 : 1;
      } else {
        return a.chalan_no < b.chalan_no ? -1 : 1;
      }
    });
    setFilteredData(sortedItems);
  };

  const handleSortName = () => {
    const sortedItems = [...filteredData].sort((a, b) => {
      if (a.customer_full_name === b.customer_full_name) {
        return a.customer_full_name < b.customer_full_name ? -1 : 1;
      } else {
        return a.customer_full_name < b.customer_full_name ? -1 : 1;
      }
    });
    setFilteredData(sortedItems);
  };

  const handleSortNumber = () => {
    const sortedItems = [...filteredData].sort((a, b) => {
      if (a.customer_phone === b.customer_phone) {
        return a.customer_phone < b.customer_phone ? -1 : 1;
      } else {
        return a.customer_phone < b.customer_phone ? -1 : 1;
      }
    });
    setFilteredData(sortedItems);
  };

  const handleSortDate = () => {
    const sortedItems = [...filteredData].sort((a, b) => {
      if (a.order_date === b.order_date) {
        return a.order_date < b.order_date ? -1 : 1;
      } else {
        return a.order_date < b.order_date ? -1 : 1;
      }
    });
    setFilteredData(sortedItems);
  };

  return (
    <div className="content-scroll">
      <div class="content-card card">
        <ChalanMiddle
          chalan={chalan}
          isLoading={isLoading}
          onSummery={onSummery}
          searchQuery={searchQuery}
          filteredData={filteredData}
          handleSearchChange={handleSearchChange}
          handleSortChalan={handleSortChalan}
          handleSortName={handleSortName}
          handleSortNumber={handleSortNumber}
          handleSortDate={handleSortDate}
        />
      </div>
      {summeryOpen ? (
        <div>
          {orderList?.return_order_id ? (
            <ReturnSummery
              summeryContent={
                <div>
                  <div>
                    <span className="cl-summary-title">
                      RETURN ORDER DETAILS
                    </span>
                    <div className="cl-summary-hr" />
                    <div
                      className="content-middle"
                      style={{ marginRight: "25px" }}
                    >
                      <Table className="content-middle-main">
                        <Thead>
                          <Tr>
                            <Th className="content-middle-title-tr ro-content-middle-no">
                              No
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-name">
                              Item Name
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-name">
                              Order Date
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-name">
                              Return Order Date
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-name">
                              Site Name
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-r-qty">
                              Return Qty
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-d-qty">
                              Unit Rent
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-d-q-charge">
                              Total Rent
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-e-days">
                              Extra Days
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-e-days">
                              Discount Days
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-t-days">
                              Extra Days Rent
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-e-d-rent">
                              Total Extra Days Rent
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-t-rent">
                              Damage Qty
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-t-e-d-rent">
                              Total Damage Rent
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-o-date">
                              Damage Qty Cost
                            </Th>
                            <Th className="content-middle-title-tr ro-content-middle-t-amount">
                              Payable Amount
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {orderList?.return_order_details &&
                          orderList?.return_order_details?.length > 0
                            ? orderList?.return_order_details?.map(
                                (item, index) => {
                                  return (
                                    <Tr>
                                      <Td className="ro-content-middle-no-data">
                                        {index + 1}
                                      </Td>
                                      <Td className="ro-content-middle-name-data">
                                        {item.item_name}
                                      </Td>
                                      <Td className="ro-content-middle-name-data">
                                        {item?.order_date?.trim() !== ""
                                          ? moment(item.order_date).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "-"}
                                      </Td>
                                      <Td className="ro-content-middle-name-data">
                                        {item?.order_date?.trim() !== ""
                                          ? moment(item.order_date)
                                              .add(14, "days")
                                              .format("DD-MM-YYYY")
                                          : "-"}
                                      </Td>
                                      <Td className="ro-content-middle-name-data">
                                        {item.site_name}
                                      </Td>
                                      <Td className="ro-content-middle-r-qty-data">
                                        {item.return_quantity}
                                      </Td>
                                      <Td className="ro-content-middle-e-d-rent-data">
                                        {item.unit_rent.toFixed(2)}
                                      </Td>
                                      <Td className="ro-content-middle-t-e-d-rent-data">
                                        {item.total_rent.toFixed(2)}
                                      </Td>
                                      <Td className="ro-content-middle-r-date-data">
                                        {item.extra_days}
                                      </Td>
                                      <Td className="ro-content-middle-r-date-data">
                                        {item.discounted_days}
                                      </Td>
                                      <Td className="ro-content-middle-u-rent-data">
                                        {item.extra_days_rent.toFixed(2)}
                                      </Td>
                                      <Td className="ro-content-middle-e-days-data">
                                        {item.total_extra_days_rent.toFixed(2)}
                                      </Td>
                                      <Td className="ro-content-middle-t-rent-data">
                                        {item.damaged_quantity}
                                      </Td>
                                      <Td className="ro-content-middle-t-days-data">
                                        {item.total_damaged_cost.toFixed(2)}
                                      </Td>
                                      <Td className="ro-content-middle-o-date-data">
                                        {item.damaged_cost_item.toFixed(2)}
                                      </Td>
                                      <Td className="ro-content-middle-d-qty-data">
                                        {item.payable_amount.toFixed(2)}
                                      </Td>
                                    </Tr>
                                  );
                                }
                              )
                            : null}
                        </Tbody>
                        <Tbody>
                          <Tr>
                            <Td className="ro-content-middle-ext-3" colspan="7">
                              Total Rent
                            </Td>
                            <Td className="ro-content-middle-ext-4" colspan="3">
                              {total_ext_days.toFixed(2)}
                            </Td>
                            <Td className="ro-content-middle-ext-5">
                              Total Ext. Days Rent
                            </Td>
                            <Td className="ro-content-middle-ext-6">
                              {total_ext_days_rent?.toFixed(2)}
                            </Td>
                            <Td className="ro-content-middle-ext-1" colspan="1">
                              Total Damage Cost
                            </Td>
                            <Td className="ro-content-middle-ext-2">
                              {total_damage_qty?.toFixed(2)}
                            </Td>
                            <Td className="ro-content-middle-ext-1" colspan="1">
                              Total Amount
                            </Td>
                            <Td className="ro-content-middle-ext-7">
                              ₹ {total_payable_payment?.toFixed(2)}
                            </Td>
                          </Tr>
                          <Tr>
                            <Td className="ro-content-middle-v-1" colspan="15">
                              Vehicle Rent
                            </Td>
                            <Td className="ro-content-middle-v-2">
                              ₹ {orderList.vehicle_charge}
                            </Td>
                          </Tr>
                          <Tr>
                            <Td className="ro-content-middle-l-1" colspan="15">
                              Labour Rent
                            </Td>
                            <Td className="ro-content-middle-l-2">
                              ₹ {orderList.labour_charge}
                            </Td>
                          </Tr>
                          <Tr style={{ fontWeight: "bold" }}>
                            <Td className="ro-content-middle-t-1" colspan="13">
                              Total Remaining Payment
                            </Td>
                            <Td className="ro-content-middle-t-2">
                              ₹ {total_remain_payment?.toFixed(2)}
                            </Td>
                            <Td className="ro-content-middle-t-1">
                              Total Payable Amount
                            </Td>
                            <Td className="ro-content-middle-t-2">
                              ₹{" "}
                              {(total_payable_payment + total_charges).toFixed(
                                2
                              )}
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="cl-summary-btn-div">
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className="cl-summary-btn"
                          style={{ backgroundColor: AppColors.red }}
                          onClick={printDocument}
                        >
                          Print
                        </button>
                      )}
                      content={() => componentRef.current}
                    />
                    <button
                      style={{ marginLeft: "10px" }}
                      className="cl-summary-btn"
                      onClick={toggleSummeryPopup}
                    >
                      Close
                    </button>
                  </div>
                </div>
              }
            />
          ) : (
            <OrderSummery
              summeryContent={
                <div>
                  <span
                    className="cl-summary-title"
                    style={{ marginTop: "20px" }}
                  >
                    Order Details
                  </span>
                  <div className="cl-summary-hr" />
                  {isLoading ? (
                    <TableLoader />
                  ) : (
                    <div>
                      <div
                        className="cat-content-middle"
                        style={{ marginRight: "25px" }}
                      >
                        <Table className="content-middle-main">
                          <Thead>
                            <Tr>
                              <Th className="cl-content-middle-title-tr cl-summary-middle-no">
                                No
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Item Name
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Order Date
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Order Qty
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Unit Rent
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Rent (Per Day Rs.)
                              </Th>
                              <Th className="cl-content-middle-title-tr">
                                Rent (Per 15 Days)
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {orderList?.order_details
                              ? orderList.order_details.map((item, index) => {
                                  return (
                                    <Tr>
                                      <Td className="cl-summary-middle-no-data">
                                        {index + 1}
                                      </Td>
                                      <Td className="cl-summary-middle-quantity-data">
                                        {item?.item_name}
                                      </Td>
                                      <Td className="cl-content-middle-mobile-data">
                                        {orderList?.order_date?.trim() !== ""
                                          ? moment(orderList.order_date).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "-"}
                                      </Td>
                                      <Td className="cl-summary-middle-quantity-data">
                                        {item?.quantity}
                                      </Td>
                                      <Td className="cl-summary-middle-rent2-data">
                                        {item?.rent}
                                      </Td>
                                      <Td className="cl-summary-middle-rent2-data">
                                        {item?.rent_per_day}
                                      </Td>
                                      <Td className="cl-summary-middle-rent1-data">
                                        {item?.rent_fifteen_days}
                                      </Td>
                                    </Tr>
                                  );
                                })
                              : null}
                          </Tbody>
                          <Tbody>
                            <Tr style={{ background: AppColors.lightGreen }}>
                              <Td
                                style={{
                                  textAlign: "right",
                                }}
                                className="cl-summary-middle-rent1-data"
                                colspan="6"
                              >
                                Total Rent
                              </Td>
                              <Td className="cl-summary-middle-rent1-data">
                                ₹ {total_rent}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td className="ro-content-middle-v-1" colspan="6">
                                Vehicle Rent
                              </Td>
                              <Td className="ro-content-middle-v-2">
                                ₹ {orderList?.vehicle_charge}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td className="ro-content-middle-l-1" colspan="6">
                                Labour Rent
                              </Td>
                              <Td className="ro-content-middle-l-2">
                                ₹ {orderList?.labour_charge}
                              </Td>
                            </Tr>
                            <Tr style={{ fontWeight: "bold" }}>
                              <Td className="ro-content-middle-t-1" colspan="6">
                                Total Payable Amount
                              </Td>
                              <Td className="ro-content-middle-t-2">
                                ₹ {total_rent + sumOfCharges}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </div>
                    </div>
                  )}
                  <div className="cl-summary-btn-div">
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className="cl-summary-btn"
                          style={{ backgroundColor: AppColors.red }}
                          onClick={printDocument}
                        >
                          Print
                        </button>
                      )}
                      content={() => componentRef.current}
                    />
                    <button
                      style={{ marginLeft: "10px" }}
                      className="cl-summary-btn"
                      onClick={toggleSummeryPopup}
                    >
                      Close
                    </button>
                  </div>
                </div>
              }
            />
          )}
        </div>
      ) : null}
      {openInvoice ? (
        <div ref={componentRef}>
          {orderList.return_order_id ? (
            <ReturnOrderInvoice
              customer_phone={orderList?.customer_phone}
              customer_name={orderList?.customer_full_name}
              summeryItem={orderList}
            />
          ) : (
            <PlaceOrderInvoice
              summeryItem={orderList}
              customer_phone={orderList?.customer_phone}
              customer_name={orderList?.customer_full_name}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ChalanContent;
