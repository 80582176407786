import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchableDropDown from "../AcInputText/SearchbleDropDown";
import { CalenderIcon } from "../../assets";

const InvoiceContentTop = ({
  selectedFromDate,
  selectedToDate,
  setSelectedFromDate,
  setSelectedToDate,
  customer,
  c_id,
  setC_id,
}) => {
  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}
      >
        <span className="payment-top-left-title">INVOICE</span>
      </div>
      <div className="po-accordion-second">
        {customer?.list ? (
          <SearchableDropDown
            options={customer?.list}
            value={c_id}
            onChange={(e) => {
              setC_id(e[0]?.value);
            }}
          />
        ) : (
          ""
        )}
        <div className="po-accordion-date" style={{ marginLeft: "20px" }}>
          <label className="ac-inputText-label" htmlFor={"Start Date"}>
            Start Date
          </label>
          <div className="ac-inputText-date-container">
            <DatePicker
              className="ac-inputText-input-date"
              onChange={(value) => {
                setSelectedFromDate(value);
              }}
              selected={selectedFromDate}
              name="startDate"
              dateFormat="dd-MM-yyyy"
            />
            <img src={CalenderIcon} />
          </div>
        </div>
        <div className="po-accordion-date" style={{ marginLeft: "30px" }}>
          <label className="ac-inputText-label" htmlFor={"End Date"}>
            End Date
          </label>
          <div className="ac-inputText-date-container">
            <DatePicker
              className="ac-inputText-input-date"
              onChange={(value) => setSelectedToDate(value)}
              selected={selectedToDate}
              name="endDate"
              dateFormat="dd-MM-yyyy"
            />
            <img src={CalenderIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceContentTop;
