import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASEURL } from "../../constant/constant";
import ExpenseContentTop from "./ExpenseContentTop";
import ExpenseMiddle from "./ExpenseMiddle";
import { AlertType, alertService } from "../../utils/alert.service";
import moment from "moment";
import { DeletePopup } from "../Popup/Popup";
import { RedCrossIcon } from "../../assets";

const ExpenseContent = () => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [size, setSize] = useState();
  const [selectDate, setSelectDate] = useState(new Date());
  const [exinType, setExinType] = useState("Expense");
  const [expense, setExpense] = useState({
    expenseName: "",
    expenseAmount: "",
    expenseDesc: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [expenseList, setExpenseList] = useState("");
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [delItem, setDelItem] = useState("");
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const addExpensesDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const item = {
      date: moment(selectDate, "DD-MM-YYYY"),
      name: expense.expenseName,
      amount: expense.expenseAmount,
      description: expense.expenseDesc,
      type: exinType,
    };
    try {
      const response = await axios({
        url: BASEURL + "expenses/add",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: item,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        ExpensesList();
        setExpense({
          expenseName: "",
          expenseAmount: "",
          expenseDesc: "",
        });
        setSelectDate(new Date());
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const ExpensesList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const response = await axios({
      url: BASEURL + "expenses/list",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        page: selectedPage?.selected ?? 1,
        size: size,
        sort_by: sortData,
        order_by: orderBy,
      },
    });
    setExpenseList(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    ExpensesList(true);
  }, [selectedPage, size, sortData, orderBy]);

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const onDelete = (delValue) => {
    setDelItem(delValue);
    toggleDeletePopup();
  };

  const deleteExpenseDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const deleteItem = {
      expenses_id: delItem.expenses_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "expenses/remove",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: deleteItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleDeletePopup();
        ExpensesList();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const deleteSubmitHandler = () => {
    deleteExpenseDetail();
    toggleDeletePopup();
  };

  const onShortData = (sortItem) => {
    if (sortItem !== sortData) {
      setSortData(sortItem);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  return (
    <div className="content-scroll">
      <div class="content-card card">
        <ExpenseContentTop
          expense={expense}
          setExpense={setExpense}
          selectDate={selectDate}
          setSelectDate={setSelectDate}
          calendarIsOpen={calendarIsOpen}
          setCalendarIsOpen={setCalendarIsOpen}
          addExpensesDetail={addExpensesDetail}
          setExinType={setExinType}
        />
      </div>
      <div class="content-card card">
        <ExpenseMiddle
          isLoading={isLoading}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          size={size}
          setSize={setSize}
          expenseList={expenseList}
          onShortData={onShortData}
          onDelete={onDelete}
        />
      </div>
      {isDeleteOpen && (
        <DeletePopup
          deleteContent={
            <div className="del-pop">
              <div className="del-pop-main">
                <img className="del-pop-icon" src={RedCrossIcon} />
              </div>
              <div className="del-pop-text-div">
                <span className="del-pop-text">
                  Are you sure want to delete expense : <br />
                  {delItem.name}?
                </span>
              </div>
              <div className="del-pop-btn">
                <button
                  className="del-pop-btn-yes"
                  onClick={deleteSubmitHandler}
                >
                  Yes
                </button>
                <button className="del-pop-btn-no" onClick={toggleDeletePopup}>
                  No
                </button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default ExpenseContent;
