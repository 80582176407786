import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import POAccordion from "./POAccordion";
import AcInputText from "../AcInputText/AcInputText";
import { alertService, AlertType } from "../../utils/alert.service";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalenderIcon } from "../../assets";

const POContentTop = ({
  siteList,
  varData,
  poData,
  setPoData,
  orderData,
  setOrderData,
  placeOrderDetail,
  arr,
  setArr,
  setIsChecked,
  setS_id,
  s_id,
  placeOrderList,
  state,
  selectedFromDate,
  setSelectedFromDate,
  selectedToDate,
  setSelectedToDate,
}) => {
  const disabled = true;
  const disabledF = false;

  const navigate = useNavigate();

  function getNumberOfDays(start, end) {
    const date1 = moment(start).startOf("day");
    const date2 = moment(end).endOf("day");

    const diffInDays = date2.diff(date1, "days");
    return diffInDays + 1;
  }

  const onRentChange = (value, mapObject) => {
    const isPresent = orderData.find(
      (findItem) =>
        findItem.product_variants_id === mapObject.product_variants_id
    );
    if (isPresent) {
      setOrderData(
        orderData.map((mapItem) => {
          if (mapItem.product_variants_id === mapObject.product_variants_id) {
            return {
              ...mapItem,
              fix_rent: value?.trim() === "" ? 0 : parseFloat(value),
            };
          } else {
            return mapItem;
          }
        })
      );
    } else {
      setOrderData((prevState) => [
        ...prevState,
        {
          ...mapObject,
          fix_rent: value?.trim() === "" ? 0 : parseFloat(value),
        },
      ]);
    }
  };

  const onItemChange = (value, mapObject, item) => {
    const isPresent = orderData.find(
      (findItem) =>
        findItem.product_variants_id === mapObject.product_variants_id
    );
    if (isPresent) {
      setOrderData(
        orderData.map((mapItem) => {
          if (mapItem.product_variants_id === mapObject.product_variants_id) {
            return {
              ...mapItem,
              product_name: item.product_name,
              product_id: item.product_id,
              quantity: value,
            };
          } else {
            return mapItem;
          }
        })
      );
    } else {
      setOrderData((prevState) => [
        ...prevState,
        {
          ...mapObject,
          quantity: value,
          product_name: item.product_name,
          product_id: item.product_id,
        },
      ]);
    }
  };

  const handlecheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked == true) {
      setArr((prevValue) => [...prevValue, value]);
    } else {
      setArr(arr.filter((item) => item !== value));
    }
    setIsChecked(checked);
  };

  const payableAmount = () => {
    let sum = 0;

    const getNumber = (value) => {
      return value.toString()?.trim() === "" ? 0 : parseFloat(value);
    };

    for (const iterator of orderData) {
      if (arr.includes(iterator.product_variants_id)) {
        sum = sum + parseFloat(iterator.quantity * iterator.fix_rent);
      }
    }
    sum = sum + getNumber(poData.vRent) + getNumber(poData.lRent);
    return sum;
  };

  const placeOrderCancel = () => {
    navigate(-1);
  };

  const o_data = orderData.filter(
    (filterItem) =>
      filterItem?.quantity?.trim() !== "" && parseInt(filterItem.quantity) !== 0
  );

  const placeOrderSubmitHandler = (e) => {
    e.preventDefault();
    if (o_data.length == 0) {
      alertService.alert({
        type: AlertType.Error,
        message: "Please Add Quantity!",
      });
    } else {
      placeOrderDetail();
      placeOrderList();
      navigate(-1);
    }
  };

  return (
    <div className="content-top">
      <div className="content-top-left">
        <span className="content-top-left-title">
          PLACE ORDER &nbsp;
          <span style={{ color: "gray" }}>({state.customer_full_name})</span>
        </span>
        <div className="ro-content-top">
          <div
            className="ac-inputText-input-wrapper"
            style={{ marginTop: "10px" }}
          >
            <label className="ac-inputText-label" htmlFor={"Site Name"}>
              Site Name
            </label>
            <select
              style={{ width: "300px" }}
              className="ac-inputText-input-container"
              onChange={(e) => {
                setS_id(e.target.value);
              }}
              value={s_id}
            >
              {siteList?.list &&
                siteList?.list?.map((item) => {
                  return (
                    <option className="ac-inputText-input" value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="po-accordion-input" style={{ marginTop: "10px" }}>
            <AcInputText
              label="Chalan Number"
              type="text"
              placeholder="Chalan Number"
              value={poData.cNumber}
              onChange={(e) =>
                setPoData((prevValue) => ({
                  ...prevValue,
                  cNumber: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <div className="po-content-top-hr" />
        <span className="po-content-top-second-txt">Item Details</span>
        {varData?.list && varData?.list?.length > 0
          ? varData?.list.map((item) => {
              return (
                <POAccordion
                  product_name={item.product_name}
                  content={
                    item?.product_variants && item?.product_variants?.length > 0
                      ? item?.product_variants?.map((mapitem) => {
                          return (
                            <div>
                              <div className="po-accordion-main">
                                <div className="po-accordion">
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "18px",
                                    }}
                                  >
                                    <input
                                      className="po-accordion-checkbox"
                                      type="checkbox"
                                      value={mapitem.product_variants_id}
                                      onChange={(e) => handlecheckbox(e)}
                                    />
                                    &nbsp;
                                    {arr.includes(
                                      mapitem.product_variants_id
                                    ) ? (
                                      <span
                                        className="po-accordion-checkbox-label"
                                        style={{
                                          color: "black",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {mapitem.product_variant_size}&nbsp;
                                        {mapitem.measurement_name}
                                      </span>
                                    ) : (
                                      <span className="po-accordion-checkbox-label">
                                        {mapitem.product_variant_size}&nbsp;
                                        {mapitem.measurement_name}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="po-accordion-main-input">
                                  <div className="po-accordion-input">
                                    <AcInputText
                                      label="Available Quantity"
                                      type="text"
                                      value={mapitem.available_quantity}
                                      disabled
                                    />
                                  </div>
                                  <div className="po-accordion-input one">
                                    <AcInputText
                                      label="Rent Per Item (15 days)"
                                      type="text"
                                      disabled={
                                        arr.includes(
                                          mapitem.product_variants_id
                                        )
                                          ? disabledF
                                          : disabled
                                      }
                                      value={
                                        arr.includes(
                                          mapitem.product_variants_id
                                        )
                                          ? orderData.find(
                                              (findItem) =>
                                                findItem.product_variants_id ===
                                                mapitem.product_variants_id
                                            )?.fix_rent
                                          : mapitem.fix_rent
                                      }
                                      onChange={(e) =>
                                        onRentChange(
                                          e.target.value.replace(/\D/g, ""),
                                          mapitem
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="po-accordion-input one">
                                    <AcInputText
                                      label="Quantity"
                                      type="text"
                                      placeholder="Quantity"
                                      disabled={
                                        arr.includes(
                                          mapitem.product_variants_id
                                        )
                                          ? disabledF
                                          : disabled
                                      }
                                      value={
                                        arr.includes(
                                          mapitem.product_variants_id
                                        )
                                          ? orderData.find(
                                              (findItem) =>
                                                findItem.product_variants_id ===
                                                mapitem.product_variants_id
                                            )?.quantity
                                          : ""
                                      }
                                      onChange={(e) => {
                                        onItemChange(
                                          e.target.value.replace(/\D/g, ""),
                                          mapitem,
                                          item
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : null
                  }
                />
              );
            })
          : null}
        <div className="po-content-top-hr" />
        <div>
          <span
            className="content-top-left-title"
            style={{ marginTop: "10px" }}
          >
            ORDER DETAILS
          </span>
          <div className="po-accordion-second">
            <div className="po-accordion-date" style={{ marginLeft: "20px" }}>
              <label
                className="ac-inputText-label"
                htmlFor={"Order Date (From)"}
                style={{ marginTop: "-8px" }}
              >
                Order Date (From)
              </label>
              <div className="ac-inputText-date-container">
                <DatePicker
                  className="ac-inputText-input-date"
                  onChange={(value) => {
                    setSelectedFromDate(value);
                    setSelectedToDate(moment(value).add(14, "days").toDate());
                  }}
                  selected={selectedFromDate}
                  name="startDate"
                  dateFormat="dd-MM-yyyy"
                />
                <img src={CalenderIcon} />
              </div>
            </div>
            <div className="po-accordion-date" style={{ marginLeft: "30px" }}>
              <label
                className="ac-inputText-label"
                htmlFor={"Order Date (To)"}
                style={{ marginTop: "-8px" }}
              >
                Order Date (To)
              </label>
              <div className="ac-inputText-date-container">
                <DatePicker
                  className="ac-inputText-input-date"
                  onChange={(value) => setSelectedToDate(value)}
                  selected={selectedToDate}
                  name="endDate"
                  dateFormat="dd-MM-yyyy"
                  disabled
                />
                <img src={CalenderIcon} />
              </div>
            </div>
            <div
              className="po-accordion-second-input"
              style={{ marginLeft: "20px" }}
            >
              <AcInputText
                label="Total Days"
                type="text"
                placeholder="FOR DAYS"
                value={getNumberOfDays(selectedFromDate, selectedToDate) || 0}
                disabled
              />
            </div>
            <div
              className="po-accordion-second-input"
              style={{ marginLeft: "-40px" }}
            >
              <AcInputText
                label="Location"
                type="text"
                placeholder="Location"
                value={poData.location}
                onChange={(e) =>
                  setPoData((prevValue) => ({
                    ...prevValue,
                    location: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="po-content-top-hr" />
        <div>
          <span
            className="content-top-left-title"
            style={{ marginTop: "10px" }}
          >
            LABOUR CHARGES
          </span>
          <div className="po-accordion-second">
            <div className="po-accordion-second-input">
              <AcInputText
                label="Vehicle Rent"
                type="text"
                placeholder="Vehicle Rent"
                value={poData.vRent}
                onChange={(e) =>
                  setPoData((prevValue) => ({
                    ...prevValue,
                    vRent: e.target.value.replace(/\D/g, ""),
                  }))
                }
              />
            </div>
            <div className="po-accordion-second-input">
              <AcInputText
                label="Labour Rent"
                type="text"
                placeholder="Labour Rent"
                value={poData.lRent}
                onChange={(e) =>
                  setPoData((prevValue) => ({
                    ...prevValue,
                    lRent: e.target.value.replace(/\D/g, ""),
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="po-content-top-hr" />
        <div>
          <span
            className="content-top-left-title"
            style={{ marginTop: "10px" }}
          >
            PAYMENT DETAILS
          </span>
          <div className="po-accordion-second">
            <div className="po-accordion-second-input">
              <AcInputText
                label="Payable Amount"
                placeholder="0.00"
                value={payableAmount()?.toFixed(2)}
                onChange={(e) =>
                  setPoData((prevValue) => ({
                    ...prevValue,
                    payAmount: e.target.value,
                  }))
                }
                disabled
                style={{ fontWeight: "600", fontSize: "20px" }}
              />
            </div>
            <div className="po-accordion-second-input">
              <AcInputText
                label="Deposite"
                type="text"
                placeholder="0.00"
                value={poData.deposite}
                onChange={(e) =>
                  setPoData((prevValue) => ({
                    ...prevValue,
                    deposite: e.target.value.replace(/\D/g, ""),
                  }))
                }
                style={{ fontWeight: "600", fontSize: "20px" }}
              />
            </div>
          </div>
        </div>
        <div className="po-content-top-hr" />
        <div className="po-second-bottam-btn">
          <button
            className="po-second-bottam-btn-sub"
            onClick={placeOrderSubmitHandler}
          >
            Submit
          </button>
          <button
            className="po-second-bottam-btn-can"
            onClick={placeOrderCancel}
          >
            Cancel
          </button>
        </div>
        {orderData?.length > 0 ? (
          <div>
            <span className="content-top-left-title">ORDER LIST PREVIEW</span>
            <div className="cl-content-middle" style={{ marginRight: "30px" }}>
              <Table className="content-middle-main">
                <Thead>
                  <Tr>
                    <Th className="content-middle-title-tr">Product Name</Th>
                    <Th className="content-middle-title-tr">Size</Th>
                    <Th className="content-middle-title-tr">Quantity</Th>
                    <Th className="content-middle-title-tr">
                      Rent Per Item (15 days)
                    </Th>
                    <Th className="content-middle-title-tr">Total</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {orderData
                    .filter((filterItem) => filterItem.quantity !== 0)
                    .map((orderItem, key) =>
                      arr.includes(orderItem.product_variants_id) ? (
                        <Tr key={key}>
                          <Td className="content-middle-name-data">
                            {orderItem.product_name}
                          </Td>
                          <Td className="content-middle-email-data">
                            {orderItem.product_variant_size}&nbsp;
                            {orderItem.measurement_name}
                          </Td>
                          <Td className="content-middle-name-data">
                            {orderItem.quantity}
                          </Td>
                          <Td className="content-middle-email-data">
                            {orderItem.fix_rent}
                          </Td>
                          <Td className="content-middle-name-data">
                            {orderItem.quantity * orderItem.fix_rent}
                          </Td>
                        </Tr>
                      ) : null
                    )}
                </Tbody>
              </Table>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default POContentTop;
