import React from "react";
import Select from "react-weblineindia-dropdown";

const SearchbleDropDown = ({ options, onChange, value }) => {
  return (
    <div style={{ marginLeft: "15px" }}>
      <label>Customer Name</label>
      <div style={{ width: "300px" }}>
        <Select
          style={{ borderRadius: "10px", border: "1px solid #000000" }}
          options={options}
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};

export default SearchbleDropDown;
