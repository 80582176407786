import React from "react";
import DashContentTop from "./DashContentTop";
import DashContentMiddle from "./DashContentMiddle";
import DashContentBottam from "./DashContentBottam";
import { useState } from "react";
import axios from "axios";
import { AlertType, alertService } from "../../utils/alert.service";
import { BASEURL } from "../../constant/constant";
import { useEffect } from "react";
import { DeletePopup } from "../Popup/Popup";
import { RedCrossIcon } from "../../assets";

const DashboardContent = () => {
  const [size, setSize] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const [revenueType, setRevenueType] = useState("Monthly");
  const [expenseType, setExpenseType] = useState("Monthly");
  const [staticType, setStaticType] = useState("Monthly");
  const [customer_payment_list, setCustomer_payment_list] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [cardData, setCardData] = useState("");
  const [revenueData, setRevenueData] = useState("");
  const [productData, setProductData] = useState("");
  const [expenseData, setExpenseData] = useState("");
  const [staticData, setStaticData] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [delItem, setDelItem] = useState("");

  const DashboardCardData = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const response = await axios({
      url: BASEURL + "dashboard",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
    });
    setCardData(response.data);
  };

  useEffect(() => {
    DashboardCardData();
  }, []);

  const RevenueChartData = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const response = await axios({
      url: BASEURL + "dashboard/revenue_chart",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        data_by: revenueType,
      },
    });
    setRevenueData(response.data);
  };

  useEffect(() => {
    RevenueChartData();
  }, [revenueType]);

  const ProductChartData = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const response = await axios({
      url: BASEURL + "dashboard/variant_chart",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
    });
    setProductData(response.data);
  };

  useEffect(() => {
    ProductChartData();
  }, []);

  const ExpenseChartData = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const response = await axios({
      url: BASEURL + "dashboard/expense_chart",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        data_by: expenseType,
      },
    });
    setExpenseData(response.data);
  };

  useEffect(() => {
    ExpenseChartData();
  }, [expenseType]);

  const StaticChartData = async () => {
    const auth_token = sessionStorage.getItem("auth_token");
    const response = await axios({
      url: BASEURL + "dashboard/orders_chart",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        data_by: staticType,
      },
    });
    setStaticData(response.data);
  };

  useEffect(() => {
    StaticChartData();
  }, [staticType]);

  const CustomerPaymentList = async (showSkeleton = false) => {
    const auth_token = sessionStorage.getItem("auth_token");
    showSkeleton && setIsLoading(true);
    const response = await axios({
      url: BASEURL + "payment/list",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: auth_token,
      },
      data: {
        page: selectedPage?.selected ?? 1,
        size: size,
        sort_by: sortData,
        order_by: orderBy,
        search_keyword: searchInput,
      },
    });
    setCustomer_payment_list(response.data);
    showSkeleton && setIsLoading(false);
  };

  useEffect(() => {
    CustomerPaymentList(true);
  }, [selectedPage, size, sortData, orderBy, searchInput]);

  const toggleDeletePopup = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const onDelete = (delValue) => {
    setDelItem(delValue);
    toggleDeletePopup();
  };

  const deletePaymentDetail = async () => {
    const auth_token = sessionStorage.getItem("auth_token");

    const deleteItem = {
      payment_id: delItem.payment_id,
      customer_id: delItem.customer_id,
    };
    try {
      const response = await axios({
        url: BASEURL + "payment/delete",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: auth_token,
        },
        data: deleteItem,
      });
      if (response.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        toggleDeletePopup();
        CustomerPaymentList();
        DashboardCardData();
        RevenueChartData();
        ProductChartData();
        ExpenseChartData();
        StaticChartData();
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else if (error.response.status === 409) {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: error.response.data.map((item) => item.message).join("\n"),
        });
      }
    }
  };

  const deleteSubmitHandler = () => {
    deletePaymentDetail();
    toggleDeletePopup();
  };

  const onShortData = (sortItem) => {
    if (sortItem !== sortData) {
      setSortData(sortItem);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  return (
    <div className="content-scroll">
      <div class="content-card card">
        <DashContentTop
          setRevenueType={setRevenueType}
          cardData={cardData}
          revenueData={revenueData}
        />
        <DashContentMiddle
          setExpenseType={setExpenseType}
          setStaticType={setStaticType}
          productData={productData}
          expenseData={expenseData}
          staticData={staticData}
        />
        <DashContentBottam
          customer_payment_list={customer_payment_list}
          isLoading={isLoading}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          setSize={setSize}
          onShortData={onShortData}
          handleChangeSearch={handleChangeSearch}
          searchInput={searchInput}
          onDelete={onDelete}
        />
      </div>
      {isDeleteOpen && (
        <DeletePopup
          deleteContent={
            <div className="del-pop">
              <div className="del-pop-main">
                <img className="del-pop-icon" src={RedCrossIcon} />
              </div>
              <div className="del-pop-text-div">
                <span className="del-pop-text">
                  Are you sure want to delete payment : <br />
                  {delItem.customer_name}?
                </span>
              </div>
              <div className="del-pop-btn">
                <button
                  className="del-pop-btn-yes"
                  onClick={deleteSubmitHandler}
                >
                  Yes
                </button>
                <button className="del-pop-btn-no" onClick={toggleDeletePopup}>
                  No
                </button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default DashboardContent;
