import React, { useState } from "react";
import { DownArrowIcon, RightArrowIcon } from "../../assets";

const RIAccordion = ({ content, product_name, Pagination }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="ri-accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        {isActive ? (
          <img className="cl-down-arrow" src={DownArrowIcon} />
        ) : (
          <img className="cl-right-arrow" src={RightArrowIcon} />
        )}
        <div className="ri-accordion-title-txt">{product_name}</div>
      </div>
      {isActive ? (
        <div
          className="accordion-content"
          style={{ paddingLeft: "20px", paddingRight: "30px" }}
        >
          {content}
        </div>
      ) : null}
      {isActive ? <div>{Pagination}</div> : null}
    </div>
  );
};

export default RIAccordion;
