import moment from "moment/moment";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { DeleteIcon, EditIcon } from "../../assets";
import SortIcon from "../AcInputText/SortIcon";
import FadingLoader from "../LoadingSpinner/AccordionLoading";
import TableLoader from "../LoadingSpinner/LoadingSpinner";
import LotiFiles from "../Lotifiles/LotiFiles";
import Pagination from "../Pagination/Pagination";
import { OrderSummery } from "../Popup/Popup";
import SAccordion from "./SAccordion";

const StockContentMiddle = ({
  selectedPage,
  setSelectedPage,
  data,
  onEdit,
  onDelete,
  varData,
  setSize,
  stockData,
  togglePopupSummary,
  summaryOpen,
  isLoading,
  onShortData,
}) => {
  return (
    <div>
      <div>
        {isLoading ? (
          <TableLoader />
        ) : (
          <div>
            {data?.list?.length > 0 ? (
              <div className="content-middle">
                <Table className="content-middle-main">
                  <Thead>
                    <Tr>
                      <Th className="content-middle-title-tr">
                        <div className="sort-main">
                          <div className="content-middle-title-tr-sort-name">
                            Product
                          </div>
                          <div>
                            <SortIcon
                              onClick={() => onShortData("product_name")}
                            />
                          </div>
                        </div>
                      </Th>
                      <Th className="content-middle-title-tr">
                        <div className="sort-main">
                          <div className="content-middle-title-tr-sort-name">
                            Measurement
                          </div>
                          <div>
                            <SortIcon
                              onClick={() => onShortData("measurement_name")}
                            />
                          </div>
                        </div>
                      </Th>
                      <Th className="content-middle-title-tr">
                        <div className="sort-main">
                          <div className="content-middle-title-tr-sort-name">
                            Size
                          </div>
                          <div>
                            <SortIcon
                              onClick={() =>
                                onShortData("product_variant_size")
                              }
                            />
                          </div>
                        </div>
                      </Th>
                      <Th className="content-middle-title-tr">
                        <div className="sort-main">
                          <div className="content-middle-title-tr-sort-name">
                            Quantity
                          </div>
                          <div>
                            <SortIcon
                              onClick={() => onShortData("product_name")}
                            />
                          </div>
                        </div>
                      </Th>
                      <Th className="content-middle-title-tr">
                        <div className="sort-main">
                          <div className="content-middle-title-tr-sort-name">
                            Price (per item)
                          </div>
                          <div>
                            <SortIcon
                              onClick={() => onShortData("price_per_item")}
                            />
                          </div>
                        </div>
                      </Th>
                      <Th className="content-middle-title-tr">
                        <div className="sort-main">
                          <div className="content-middle-title-tr-sort-name">
                            Total Amount
                          </div>
                          <div>
                            <SortIcon
                              onClick={() => onShortData("total_amount")}
                            />
                          </div>
                        </div>
                      </Th>
                      <Th className="content-middle-title-tr">
                        <div className="sort-main">
                          <div className="content-middle-title-tr-sort-name">
                            Date
                          </div>
                          <div>
                            <SortIcon
                              onClick={() => onShortData("purchase_date")}
                            />
                          </div>
                        </div>
                      </Th>
                      <Th className="content-middle-title-tr">Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.list && data?.list?.length > 0
                      ? data?.list?.map((item, index) => {
                          return (
                            <Tr key={index}>
                              <Td className="content-middle-name-data">
                                {item.product_name}
                              </Td>
                              <Td className="content-middle-email-data">
                                {item.measurement_name}
                              </Td>
                              <Td className="content-middle-mobile-data">
                                {item.product_variant_size}
                              </Td>
                              <Td className="content-middle-whats-data">
                                {item.total_quantity}
                              </Td>
                              <Td className="content-middle-city-data">
                                {item.price_per_item}
                              </Td>
                              <Td className="content-middle-address-data">
                                {item.total_amount}
                              </Td>
                              <Td className="content-middle-city-data">
                                {item?.purchase_date?.trim() !== ""
                                  ? moment(item.purchase_date).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </Td>
                              <Td className="content-middle-address-data">
                                <div className="content-middle-action-data-div">
                                  <img
                                    src={EditIcon}
                                    onClick={() => onEdit(item)}
                                  />
                                  <img
                                    className="content-middle-action-data-div-delete"
                                    src={DeleteIcon}
                                    onClick={() => onDelete(item)}
                                  />
                                </div>
                              </Td>
                            </Tr>
                          );
                        })
                      : null}
                  </Tbody>
                </Table>
              </div>
            ) : (
              <LotiFiles message={"No Data Found For Stock!"} />
            )}
          </div>
        )}
      </div>
      {data?.list?.length > 0 ? (
        <div className="content-bottam">
          <Pagination
            selectedPage={selectedPage}
            totalCount={data?.total_pages ?? 1}
            onPageChange={(page) => setSelectedPage(page)}
            itemsPerPage={4}
          />
        </div>
      ) : (
        ""
      )}
      {isLoading ? (
        <FadingLoader />
      ) : (
        <div>
          {varData?.list?.length > 0 ? (
            <div className="size-content-middle">
              {varData?.list && varData?.list?.length > 0
                ? varData.list.map((item) => (
                    <SAccordion
                      isLoading={isLoading}
                      product_name={item.product_name}
                      content={
                        <div
                          className="var-content-middle-table"
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            marginBottom: "20px",
                          }}
                        >
                          {isLoading ? (
                            <TableLoader />
                          ) : (
                            <Table className="content-middle-main">
                              <Thead>
                                <Tr>
                                  <Th className="content-middle-title-tr">
                                    Product
                                  </Th>
                                  <Th className="content-middle-title-tr">
                                    Size
                                  </Th>
                                  <Th className="content-middle-title-tr">
                                    Measurement
                                  </Th>
                                  <Th className="content-middle-title-tr">
                                    Quantity (In Order)
                                  </Th>
                                  <Th className="content-middle-title-tr">
                                    Available Quantity
                                  </Th>
                                  <Th className="content-middle-title-tr">
                                    Damaged Quantity
                                  </Th>
                                  <Th className="content-middle-title-tr">
                                    Total Quantity
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {item.product_variants &&
                                item.product_variants.length > 0
                                  ? item.product_variants.map(
                                      (mapitem, index) => {
                                        return (
                                          <Tr>
                                            <Td
                                              className="content-middle-name customer-name"
                                              onClick={() =>
                                                togglePopupSummary(mapitem)
                                              }
                                            >
                                              {item.product_name}
                                            </Td>
                                            <Td className="content-middle-name">
                                              {mapitem.product_variant_size}
                                            </Td>
                                            <Td className="content-middle-name">
                                              {mapitem.measurement_name}
                                            </Td>
                                            <Td className="content-middle-name">
                                              {mapitem?.total_quantity -
                                                mapitem?.available_quantity}
                                            </Td>
                                            <Td className="content-middle-name">
                                              {mapitem?.available_quantity}
                                            </Td>
                                            <Td className="content-middle-name">
                                              {mapitem?.damaged_quantity}
                                            </Td>
                                            <Td className="content-middle-name">
                                              {mapitem?.total_quantity}
                                            </Td>
                                          </Tr>
                                        );
                                      }
                                    )
                                  : null}
                              </Tbody>
                            </Table>
                          )}
                        </div>
                      }
                    />
                  ))
                : null}
            </div>
          ) : (
            <LotiFiles message={"No Data Found For Stock!"} />
          )}
        </div>
      )}
      <div className="content-bottam" />
      {summaryOpen && (
        <OrderSummery
          summeryContent={
            <div>
              <div>
                <span className="cl-summary-title">Stock Summary</span>
              </div>
              <div className="cl-summary-hr" />
              <div>
                {isLoading ? (
                  <TableLoader />
                ) : (
                  <div>
                    {stockData?.list?.length > 0 ? (
                      <div
                        className="cl-content-middle"
                        style={{ marginRight: "20px" }}
                      >
                        <Table className="cl-summary-middle-main">
                          <Thead>
                            <Tr>
                              <Th className="content-middle-title-tr">
                                PRODUCT NAME
                              </Th>
                              <Th className="content-middle-title-tr">
                                MEASUREMENT
                              </Th>
                              <Th className="content-middle-title-tr">SIZE</Th>
                              <Th className="content-middle-title-tr">
                                TOTAL QTY.
                              </Th>
                              <Th className="content-middle-title-tr">
                                PRICE (PER ITEM)
                              </Th>
                              <Th className="content-middle-title-tr">
                                TOTAL AMOUNT
                              </Th>
                              <Th className="content-middle-title-tr">
                                PURCHASE DATE
                              </Th>
                              <Th className="content-middle-title-tr">
                                CREATE DATE
                              </Th>
                              <Th className="content-middle-title-tr">
                                ACTION
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {stockData?.list && stockData?.list?.length > 0
                              ? stockData.list.map((item, index) => {
                                  return (
                                    <Tr>
                                      <Td className="cl-summary-middle-product-data">
                                        {item.product_name}
                                      </Td>
                                      <Td className="cl-summary-middle-measure-data">
                                        {item.measurement_name}
                                      </Td>
                                      <Td className="cl-summary-middle-for-data">
                                        {item.product_variant_size}
                                      </Td>
                                      <Td className="cl-summary-middle-extra-data">
                                        {item.total_quantity}
                                      </Td>
                                      <Td className="cl-summary-middle-quantity-data">
                                        {item.price_per_item}
                                      </Td>
                                      <Td className="cl-summary-middle-rent1-data">
                                        {item.total_amount}
                                      </Td>

                                      <Td className="cl-summary-middle-rent2-data">
                                        {item?.purchase_date?.trim() !== ""
                                          ? moment(item.purchase_date).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "-"}
                                      </Td>
                                      <Td className="cl-summary-middle-rent3-data">
                                        {item?.createdAt?.trim() !== ""
                                          ? moment(item.createdAt).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "-"}
                                      </Td>
                                      <Td className="cl-content-middle-action-data">
                                        <div className="cl-content-middle-action-data-div">
                                          <img
                                            src={EditIcon}
                                            onClick={() => onEdit(item)}
                                          />
                                          <img
                                            className="cl-content-middle-action-data-div-delete"
                                            src={DeleteIcon}
                                            onClick={() => onDelete(item)}
                                          />
                                        </div>
                                      </Td>
                                    </Tr>
                                  );
                                })
                              : null}
                          </Tbody>
                        </Table>
                      </div>
                    ) : (
                      <LotiFiles message={"No Data Found For Stock!"} />
                    )}
                  </div>
                )}
                <div className="cl-summary-btn-div">
                  <button
                    className="cl-summary-btn"
                    onClick={togglePopupSummary}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default StockContentMiddle;
